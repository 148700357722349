import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { ErrorMessage, Field, Formik } from "formik";
import {
  IOption,
  XAutoComplete,
} from "../../../components/shared/forms/XAutoComplete";
import React, { useEffect, useState } from "react";
import { IBatch } from "../interfaces/IBatch";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useAuth } from "../../auth";
import { IHub, IInput, IProduct, IUnit } from "../../settings/interfaces/IHub";
import { authService } from "../../../../data/oidc/AuthService";
import { IBuyer } from "../interfaces/IBuyer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get, post, put } from "../../../../utils/ajax";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { BUYERS_CONSTANTS } from "../../../../data/redux/inventory/buyersReducer";
import { IBatchExport } from "../interfaces/IBatchExport";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { INVENTORY_BATCHES_CONSTANTS } from "../../../../data/redux/inventory/batchesReducer";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { InputAdornment } from "@mui/material";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { IBatchSale } from "../interfaces/IBatchSale";
import { SellBatchSchema } from "../schemas/SellBatchSchema";
import { BatchStatus } from "../interfaces/BatchStatus";

type Props = {
  selectedBatch: IBatch
};

const SellBatchForm = ({ selectedBatch }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  const [buyers, setBuyers] = useState<IBuyer[]>([]);
  const buyerOptions = buyers.map((buyer: IBuyer) => ({
    label: buyer.name,
    id: buyer.id ?? "",
  }));

  const [modalKey, setModalKey] = useState(Date.now());
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const handleFetchBuyers = () => {
    if (remoteRoutes.onboardingService) {
      const hubId = currentUser?.hubId;
      const params = { hubId };
      const url = buildUrl(remoteRoutes.inventoryService, "/buyers", params);
      get(
        url,
        (response: IApiResponse) => {
          const { data } = response;
          setBuyers(data);
          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        () => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: BUYERS_CONSTANTS.STOP_FETCH });
        },
      );
    }
  };

  useEffect(() => {
    handleFetchBuyers();
  }, []);

  useEffect(() => {
    if (selectedBatch) {
    }
  }, [selectedBatch]);

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const url = buildUrl(remoteRoutes.inventoryService, `/trade/third-party`);
    const data: IBatchSale = {
      saleData: {
        date: values.date.toISOString(),
        hubId: currentUser?.hubId,
        hubName: currentUser?.hubName,
        quantity: Number(values.quantity),
        unit: values.unitName,
        unitPrice: Number(values.unitPrice),
        productId: values.productName.id,
        product: values.productName.label,
        // varietyId: values.variety.id,
        // variety: values.variety.label,
        sellingPrice: Number(values.sellingPrice),
        batchId: selectedBatch.id,
        batchNumber: values.batchNumber,
      },
      userDetails: {
        name: currentUser?.given_name + " " + currentUser?.family_name,
        email: currentUser?.email,
        phoneNumber: currentUser?.phone_number,
      },
      buyerDetails: {
        id: values.buyer?.id,
        name: values.buyer?.label,
      },
    };

    post(
      url,
      data,
      () => {
        CloseModal("sell-batch", () => {
          setSubmitting(false);
          resetForm();
        });

        // dispatch({
        //   type: INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH,
        //   payload: data,
        // });

        handleUpdateStatus(BatchStatus.TradedToThirdParty);
        toast.success(toastMessages.default.success, overrideToastDefaults);
        setModalKey(Date.now());
      },
      () => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
    );
  };

  const handleUpdateStatus = (status: any) => {
    setUpdatingStatus(true);

    if (!selectedBatch?.id) {
      console.error("Batch ID is missing. Update cannot be performed.");
      setUpdatingStatus(false);
      return;
    }

    const updatedBatch = {
      ...selectedBatch,
      date: new Date(selectedBatch?.date).toISOString(),
      currentStatus: status,
    };

    const url = remoteRoutes.inventoryService + "/batches/" + selectedBatch?.id;
    put(
      url,
      updatedBatch,
      (response) => {
        dispatch({
          type: INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH,
          payload: updatedBatch,
        });

        setUpdatingStatus(false);
      },
      (error) => {
        setUpdatingStatus(false);
      },
      () => { }
    );
  };

  return (
    <Formik
      initialValues={{
        ...selectedBatch,
        date: dayjs(null),
        productName: selectedBatch.productName
          ? {
            id: selectedBatch.productId,
            label: selectedBatch.productName,
          }
          : null,
        // variety: selectedBatch.variety
        //   ? {
        //     id: selectedBatch.varietyId,
        //     label: selectedBatch.variety,
        //   }
        //   : null,
      }}
      enableReinitialize={true}
      validationSchema={SellBatchSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <ModalWrapper
          id="sell-batch"
          title={`${intl.formatMessage({ id: "FORM.TITLE.SELLBATCH", defaultMessage: "Sell Batch" })}`}
          size="md"
          key={modalKey}
          submitting={isSubmitting}
          handleSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                as={XDatePicker}
                label={`${intl.formatMessage({ id: "FORM.DATEOFSALE", defaultMessage: "Date of Sale" })}`}
                name="date"
                maxDate={dayjs(new Date())}
              />
              <ErrorMessage
                name="date"
                component="div"
                className="form-error"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={false}
                component={XAutoComplete}
                options={buyerOptions}
                label={`${intl.formatMessage({
                  id: "FORM.TITLE.SELECTBUYER",
                  defaultMessage: "Select Buyer",
                })}`}
                name="buyer"
              />
              <ErrorMessage
                name="buyer"
                component="div"
                className="form-error"
              />
            </div>
            <div className="col-lg-6">
              <Field
                disabled
                as={XTextField}
                label={`${intl.formatMessage({ id: "FORM.BATCHNUMBER", defaultMessage: "Batch Number" })}`}
                name="batchNumber"
              />
            </div>
            <div className="col-lg-6">
              <Field
                disabled
                as={XTextField}
                label={`${intl.formatMessage({ id: "FORM.PRODUCT", defaultMessage: "Product" })}`}
                name="productName.label"
              />
            </div>
            {/*<div className="col-lg-6">*/}
            {/*  <Field*/}
            {/*    disabled*/}
            {/*    as={XTextField}*/}
            {/*    label={`${intl.formatMessage({ id: "FORM.VARIETY", defaultMessage: "Variety" })}`}*/}
            {/*    name="variety.label"*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="col-lg-6">
              <Field
                type={"number"}
                disabled={isSubmitting}
                as={XTextField}
                label={`${intl.formatMessage({ id: "FORM.QUANTITY", defaultMessage: "Quantity" })}`}
                name="quantity"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled
                as={XTextField}
                label={`${intl.formatMessage({ id: "FORM.PRODUCTUNIT", defaultMessage: "Product Unit" })}`}
                name="unitName"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"number"}
                disabled={isSubmitting}
                as={XTextField}
                label={`${intl.formatMessage({ id: "FORM.UNITPRICE", defaultMessage: "Unit Price" })}`}
                name="unitPrice"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">UGX</InputAdornment>
                  ),
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const unitPrice = Number(e.target.value);
                  setFieldValue("unitPrice", unitPrice);
                  setFieldValue(
                    "sellingPrice",
                    Number(values.quantity) * unitPrice,
                  );
                }}
              />
              <ErrorMessage
                name="unitPrice"
                component="div"
                className="form-error"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"number"}
                disabled
                as={XTextField}
                label={`${intl.formatMessage({
                  id: "FORM.SELLINGPRICE",
                  defaultMessage: "Selling Price",
                })}`}
                name="sellingPrice"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">UGX</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </ModalWrapper>
      )}
    </Formik>
  );
};

export default SellBatchForm;
