import { XSidebarWrapper } from "../../../components/shared/XSidebarWrapper";
import React, { useEffect, useState } from "react";
import { IHeaderButtonProps } from "../../../components/shared/SettingsPageHeader";
import { faPhone, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditContactPersonForm from "./modals/EditContactPersonForm";
import DeleteContactPersonForm from "./modals/DeleteContactPersonForm";
import { useSelector } from "react-redux";
import { IState } from "../../../../data/types";
import { FormattedMessage, useIntl } from "react-intl";
import { ISettingsState } from "../../../../data/redux/settings/settingsReducer";
import { authService } from "../../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../../hooks/roleChecker";

type Props = {
  close: () => void;
  show: boolean;
  contactPersonId: any;
};

const ContactPersonDetails = ({ close, show, contactPersonId }: Props) => {
  const intl = useIntl();
  const contactPerson = useSelector((state: IState) =>
    state.settings.contactPeople.data.find((cc) => cc.id === contactPersonId),
  );

  const { contactPeople }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );
  const { pagination } = contactPeople;

  const [contactPersonDetails, setContactPersonDetails] =
    useState(contactPerson);

  useEffect(() => {
    setContactPersonDetails(contactPerson);
  }, [contactPerson]);

  const [loading, setLoading] = React.useState(true);
  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoading(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);
  const isMerchant = IsMerchant(roles);

  const actionButtons: IHeaderButtonProps[] = [];

  if (isGlobalAdmin || isHubAdmin || isHubManager || isMerchant || isMerchant) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
      cssClass: "btn btn-primary btn-sm me-1",
      dataTarget: "#edit-contact-person",
      dataToggle: "modal",
    });
  }

  if (isGlobalAdmin || isHubAdmin) {
    actionButtons.push({
      cssClass: "btn btn-danger btn-sm",
      dataTarget: "#delete-contact-person",
      dataToggle: "modal",
      deleteIcon: true,
    });
  }

  return (
    <>
      <XSidebarWrapper
        title={intl.formatMessage({
          id: "DETAILS.TITLE.CONTACTPERSONDETAILS",
          defaultMessage: "Contact Person Details",
        })}
        width={35}
        close={close}
        show={show}
      >
        <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
          <div className="py-3 px-4 rounded-1 bg-primary">
            <h2>
              {contactPersonDetails?.name
                .split(" ")
                .map((name) => name[0])
                .join("")}
            </h2>
          </div>
          <div className="py-3 w-100">
            <h5>{contactPersonDetails?.name}</h5>
            <div className="d-flex justify-content-between w-100">
              <div className="fs-6 small">
                <span>
                  <FontAwesomeIcon icon={faPhone} className="me-2" />{" "}
                  {`${contactPersonDetails?.phoneNumber}`}
                </span>
              </div>
              <div className="action-buttons">
                {actionButtons.map((button, index) => {
                  return (
                    <button
                      data-bs-toggle={button.dataToggle}
                      data-bs-target={button.dataTarget}
                      className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                      key={index}
                      onClick={button.onClick}
                    >
                      {button.deleteIcon && <FontAwesomeIcon icon={faTrash} />}
                      {button.processing
                        ? `${intl.formatMessage({
                            id: "LOADERS.PLEASEWAIT",
                            defaultMessage: "Please wait...",
                          })}`
                        : button.label}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 bg-gray rounded-3 mb-3">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.DISTRICT"
                  defaultMessage="District"
                />
              </strong>
              <br />
              <span>{contactPersonDetails?.district || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.SUBCOUNTY"
                  defaultMessage="SubCounty"
                />
              </strong>
              <br />
              <span>{contactPersonDetails?.subCounty || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage id="DETAILS.PARISH" defaultMessage="Parish" />
              </strong>
              <br />
              <span>{contactPersonDetails?.parish || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.VILLAGE"
                  defaultMessage="Village"
                />
              </strong>
              <br />
              <span>{contactPersonDetails?.village || "-"}</span>
            </div>
            {contactPersonDetails?.email && (
              <div className="col-lg-6 col-12 mb-4">
                <strong className="small fw-semibold">
                  <FormattedMessage id="DETAILS.EMAIL" defaultMessage="email" />
                </strong>
                <br />
                <span>{contactPersonDetails?.email || "-"}</span>
              </div>
            )}
          </div>
        </div>
      </XSidebarWrapper>

      {contactPersonDetails && (
        <>
          <EditContactPersonForm initialValues={contactPersonDetails} />
          <DeleteContactPersonForm
            item={contactPersonDetails}
            close={close}
            pagination={pagination}
          />
        </>
      )}
    </>
  );
};

export default ContactPersonDetails;
