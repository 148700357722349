import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { IBatch } from "../interfaces/IBatch";
import { IHub } from "../../settings/interfaces/IHub";
import { authService } from "../../../../data/oidc/AuthService";
import { remoteRoutes } from "../../../../data/constants";
import { useAuth } from "../../auth";
import { IBuyer } from "../interfaces/IBuyer";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get, post, put } from "../../../../utils/ajax";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { HUBS_CONSTANTS } from "../../../../data/redux/settings/hubsReducer";
import { BUYERS_CONSTANTS } from "../../../../data/redux/inventory/buyersReducer";
import { IBatchExport } from "../interfaces/IBatchExport";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ExportBatchSchema } from "../schemas/ExportBatchSchema";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { useIntl } from "react-intl";
import { INVENTORY_BATCHES_CONSTANTS } from "../../../../data/redux/inventory/batchesReducer";

type Props = {
  initialValues: IBatch;
};

const ExportBatchForm = ({ initialValues }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const hub: IHub | any = authService.getHub();
  const [buyers, setBuyers] = useState<IBuyer[]>([]);

  const [modalKey, setModalKey] = useState(Date.now());

  const buyerOptions = buyers.map((buyer: IBuyer) => ({
    label: buyer.name,
    id: buyer.id ?? "",
  }));

  const handleFetchBuyers = () => {
    if (remoteRoutes.onboardingService) {
      const hubId = currentUser?.hubId;
      const params = { hubId };
      const url = buildUrl(remoteRoutes.inventoryService, "/buyers", params);
      get(
        url,
        (response: IApiResponse) => {
          const { data } = response;
          setBuyers(data);
          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        () => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: BUYERS_CONSTANTS.STOP_FETCH });
        },
      );
    }
  };

  const batchExport = {
    // id: initialValues?.id,
    hubId: initialValues?.hubId,
    date: initialValues?.date
      ? new Date(initialValues.date as string).toISOString()
      : new Date().toISOString(),
    batchId: initialValues?.id,
    buyerId: "",
    collectionCentreId: initialValues?.currentCollectionCentreId,
    collectionCentreName: initialValues?.currentCollectionCentreName,
    quantity: initialValues?.quantity,
    unitId: initialValues?.unitId,
    unitName: initialValues?.unitName,
    product: initialValues?.productName,
    productId: initialValues?.productId,
    variety: initialValues?.variety,
    varietyId: initialValues?.varietyId,
  };

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const url = buildUrl(remoteRoutes.inventoryService, `/exports`);
    const data: IBatchExport = {
      // id: values.id,
      hubId: values.hubId,
      date: values.date,
      batchId: values.batchId,
      buyerId: values.buyerId.id,
      collectionCentreId: values.collectionCentreId,
      collectionCentreName: values.collectionCentreName,
      quantity: values.quantity,
      unitId: values.unitId,
      unitName: values.unitName,
      product: values.product,
      productId: values.productId,
      variety: values.variety,
      varietyId: values.varietyId,
    };

    post(
      url,
      data,
      () => {
        CloseModal("export-batch", () => {
          setSubmitting(false);
          resetForm();
        });

        dispatch({
          type: INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH,
          payload: data,
        });
        toast.success(toastMessages.default.success, overrideToastDefaults);
        setModalKey(Date.now());
      },
      () => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
    );
  };

  useEffect(() => {
    handleFetchBuyers();
  }, []);

  return (
    <Formik
      initialValues={batchExport}
      enableReinitialize={true}
      validationSchema={ExportBatchSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <ModalWrapper
          id="export-batch"
          title={`${intl.formatMessage({ id: "FORM.TITLE.SELECTBUYER", defaultMessage: "Select Buyer" })}`}
          size="md"
          key={modalKey}
          submitting={isSubmitting}
          handleSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-lg-12">
              <Field
                type={"string"}
                disabled={false}
                component={XAutoComplete}
                options={buyerOptions}
                label={`${intl.formatMessage({ id: "FORM.TITLE.SELECTBUYER", defaultMessage: "Select Buyer" })}`}
                name="buyerId"
                value={values.buyerId}
              />
            </div>
          </div>
        </ModalWrapper>
      )}
    </Formik>
  );
};

export default ExportBatchForm;
