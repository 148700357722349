import React, { useEffect, useState } from "react";
import { IPurchase } from "../interfaces/IPurchase";
import { useDispatch } from "react-redux";
import { useAuth } from "../../auth";
import { Field, Formik, FormikHelpers, FormikValues } from "formik";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { InputAdornment } from "@mui/material";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import { get, put } from "../../../../utils/ajax";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { INVENTORY_PURCHASES_CONSTANTS } from "../../../../data/redux/inventory/purchasesReducer";
import toast from "react-hot-toast";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { IFarmer, IUnit } from "../../settings/interfaces/IHub";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { useIntl } from "react-intl";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { IApiResponse } from "../../../interfaces/IApiResponse";

interface Props {
    initialValues: IPurchase
}

const EditPurchaseForm = ({ initialValues }: Props) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());
    const [farmers, setFarmers] = useState<IFarmer[]>([]);
    const [loadingFarmers, setLoadingFarmers] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [units, setUnits] = useState<IUnit[]>([]);

    const farmerList: IOption[] = loadingFarmers
        ? [
            {
                id: "loading",
                label: `${intl.formatMessage({ id: "LOADERS.FARMERLIST", defaultMessage: "Loading farmer list..." })}`,
            },
        ]
        : farmers
            .map((f: IFarmer) => ({
                id: f.id ?? "",
                label: f.fullname ?? "",
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

    const options: IOption[] = units
        .map((u) => ({
            id: u.id ?? "",
            label: u.displayName ?? "",
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const [purchaseDetails, setPurchaseDetails] = useState<IPurchase>({
        ...initialValues
    })

    useEffect(() => {
        setLoading(true);
        handleFetchFarmers();
        handleFetchData();

        if (initialValues) {
            setPurchaseDetails({
                ...initialValues
            })
        }
    }, [initialValues])

    const handleFetchFarmers = () => {
        if (remoteRoutes.profiles) {
            const hubId = currentUser?.hubId;

            setLoadingFarmers(true);
            setFarmers([]);

            const fetchTotalFarmers = () => {
                const params = { hubId, page: 1, pageSize: 1 };
                const url = buildUrl(remoteRoutes.profiles, "/farmers", params);

                return new Promise((resolve, reject) => {
                    get(
                        url,
                        (response: IApiResponse) => {
                            const totalFarmers = response.paginationMetadata?.totalItems || 0;
                            resolve(totalFarmers);
                        },
                        (error) => {
                            setLoadingFarmers(false);
                            reject(error);
                        },
                    );
                });
            };

            const fetchAllFarmers = (totalFarmers: number) => {
                const params = { hubId, page: 1, pageSize: totalFarmers };
                const url = buildUrl(remoteRoutes.profiles, "/farmers", params);

                get(
                    url,
                    (response: IApiResponse) => {
                        const { data } = response;
                        setFarmers(data);
                        setLoadingFarmers(false);
                        dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                    },
                    (error) => {
                        setLoadingFarmers(false);
                        dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                    },
                );
            };

            fetchTotalFarmers()
                .then((totalItems: any) => {
                    if (totalItems > 0) {
                        fetchAllFarmers(totalItems);
                    } else {
                        setLoadingFarmers(false);
                    }
                })
                .catch((error) => {
                    setLoadingFarmers(false);
                });
        }
    };

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            let url = "";

            let params: any = {
                pageSize: 100,
            };

            url = buildUrl(remoteRoutes.onboardingService, "/units", params);
            get(
                url,
                (response: IApiResponse) => {
                    const { data } = response;
                    setUnits(data);
                },
                async (error) => {
                },
                () => {
                    dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                },
            );

        }
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const data: IPurchase = {
            ...values,
            date: values.date.toISOString(),
            unitName: values.unitName.label,
            unitId: values.unitName.id,
            farmerName: values.farmerName.label,
            farmerId: values.farmerName.id,
        }

        const url = buildUrl(remoteRoutes.inventoryService, `/purchases/${values.id}`)

        put(url, data, (response) => {
            CloseModal("edit-purchase", () => {
                setSubmitting(false);
                resetForm();
            });

            dispatch({
                type: INVENTORY_PURCHASES_CONSTANTS.UPDATE_PURCHASE,
                payload: data,
            })
            setModalKey(Date.now());
            toast.success(toastMessages.default.success, overrideToastDefaults);
        },
            (error) => {
                setSubmitting(false);
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => { },
        )
    }

    return (
        <Formik
            initialValues={{
                ...purchaseDetails,
                date: dayjs(purchaseDetails.date),
                farmerName: purchaseDetails.farmerName
                    ? {
                        id: purchaseDetails.farmerId,
                        label: purchaseDetails.farmerName
                    }
                    : null,
                unitName: purchaseDetails.unitName
                    ? {
                        id: purchaseDetails.unitId,
                        label: purchaseDetails.unitName,
                    }
                    : null
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
                return <ModalWrapper
                    id={"edit-purchase"}
                    title={"Edit Purchase"}
                    key={modalKey}
                    size="md"
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XDatePicker}
                                label="Date of Purchase"
                                name="date"
                                maxDate={dayjs(new Date())}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={farmerList || []}
                                getOptionLabel={(option: any) => (option ? option.label : "")}
                                label={`${intl.formatMessage({ id: "FORM.FARMERNAME", defaultMessage: "Farmer Name" })}`}
                                name="farmerName"
                                value={values.farmerName}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Farm Code"
                                name="farmCode"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Farmer Code"
                                name="farmerCode"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Quantity"
                                name="quantity"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={options || []}
                                getOptionLabel={(option: any) => (option ? option.label : "")}
                                values={values.unitName}
                                label={`${intl.formatMessage({ id: "FORM.INPUTSUNIT", defaultMessage: "Inputs Unit" })}`}
                                name="unitName"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Unit Price"
                                name="unitPrice"
                                inputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">UGX</InputAdornment>
                                    ),
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const unitPrice = Number(e.target.value);
                                    setFieldValue("unitPrice", unitPrice);
                                    setFieldValue(
                                        "totalPrice",
                                        Number(values.quantity) * unitPrice,
                                    );
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled
                                as={XTextField}
                                label="Total Price"
                                name="totalPrice"
                                inputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">UGX</InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>

                </ModalWrapper>
            }}
        </Formik>
    )
}

export default EditPurchaseForm