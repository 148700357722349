import React, { useEffect, useState } from "react"
import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { ICollectionCentre, IHub, IIdNamePair, IProduct } from "../../settings/interfaces/IHub";
import { authService } from "../../../../data/oidc/AuthService";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { Autocomplete, TextField } from "@mui/material";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { useDispatch } from "react-redux";
import { useAuth } from "../../auth";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

interface Props {
  setProductId: (id: string | null | undefined) => void;
  setVarietyId: (id: string | null | undefined) => void;
  setCollectionCentre: (centres: IOption | null) => void;
  setStartDate: (date: string | null | undefined) => void;
  setEndDate: (date: string | null | undefined) => void;
}

const DashboardFilters: React.FC<Props> = ({
  setStartDate,
  setEndDate,
  setCollectionCentre,
  setProductId,
  setVarietyId
}) => {
  const intl = useIntl();
  const [products, setProducts] = useState<any[] | undefined>(undefined);
  const [varieties, setVarieties] = useState<IIdNamePair[] | undefined>(undefined);
  const [locations, setLocations] = useState<ICollectionCentre[]>([]);
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const [modalKey, setModalKey] = useState(Date.now());

  const collectionCentres: IOption[] = locations
    .map((c) => ({
      id: c.id ?? "",
      label: c.name ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const initialValues = {
    product: {
      id: "",
      label: "",
      varieties: [] as IIdNamePair[],
      variety: null as IIdNamePair | null,
    },
    collectionCentre: {
      id: "",
      label: "",
    },
  };

  const hub: IHub | undefined = authService.getHub();

  useEffect(() => {
    if (hub) {
      const { products } = hub;
      const sortedProducts = products?.map((product: IProduct) => ({
        id: product.id,
        label: product.name,
        varieties: product.varieties || [],
      })).sort((a, b) => a.label.localeCompare(b.label));

      setProducts(sortedProducts || []);
    }
  }, []);

  const handleProductChange = (productId: string | null, setFieldValue: any) => {

    if (!productId) {
      setVarieties([]);
      setFieldValue('product', { id: "", label: "", varieties: [], variety: null });
      return;
    }

    const selectedProduct = products?.find(
      (product) => product.id === productId,
    );

    if (selectedProduct) {
      const sortedVarieties: IIdNamePair[] = (selectedProduct.varieties || [])
        .map((variety: any) => ({
          id: variety.id,
          label: variety.label,
        }))
        .sort((a: { label: string }, b: { label: any }) =>
          a.label.localeCompare(b.label),
        );

      setVarieties(sortedVarieties);
      setFieldValue('product', { ...selectedProduct, varieties: sortedVarieties });
    } else {
      console.error("Selected product not found in the list");
    }
  };

  const handleCollectionCentreChange = (selectedCentre: IOption) => {
    setCollectionCentre(selectedCentre);
  };

  const formatDate = (date: any): string | null => {
    if (!date) return null;

    if (typeof date.toISOString === "function") {
      return date.toISOString();
    } else if (typeof date.format === "function") {
      return date.format("YYYY-MM-DD");
    } else if (typeof date.toISO === "function") {
      return date.toISO();
    } else {
      return date.toString();
    }
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const selectedProductId = values.product?.id || null;
    const selectedVarietyId = values.product?.variety?.id || null;
    const selectedCollectionCentre = values.collectionCentre || null;

    const selectedStartDate = formatDate(values.startDate);
    const selectedEndDate = formatDate(values.endDate);

    if (selectedVarietyId) {
      setProductId(selectedProductId);
      setVarietyId(selectedVarietyId);
    } else if (selectedProductId) {
      setProductId(selectedProductId);
    }

    if (selectedCollectionCentre) {
      setCollectionCentre(selectedCollectionCentre);
    }

    if (selectedStartDate) {
      setStartDate(selectedStartDate);
    }
    if (selectedEndDate) {
      setEndDate(selectedEndDate);
    }

    CloseModal("inventory-dashboard-filters", () => {
      setSubmitting(false);
    });
    setModalKey(Date.now());
  };

  const handleFetchData = () => {
    if (remoteRoutes.onboardingService) {

      const hubId = currentUser?.hubId
      let url = ''
      if (hubId) {
        const params = { hubId };

        url = buildUrl(remoteRoutes.onboardingService, "/collection/centres", params);
        get(
          url,
          (response: IApiResponse) => {
            const { data } = response;
            setLocations(data);
          },
          async (error) => {
          },
          () => {
            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        )
      }

    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleClearForm = (resetForm: () => void) => {
    setProductId(null);
    setVarietyId(null);
    setCollectionCentre(null);
    setStartDate(null);
    setEndDate(null);

    resetForm();

    CloseModal("inventory-dashboard-filters");
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        handleSubmit,
        isSubmitting,
        values,
        resetForm,
        setFieldValue,
        touched,
        errors,
      }) => (
        <ModalWrapper
          id={"inventory-dashboard-filters"}
          title={`${intl.formatMessage({ id: "FORM.TITLE.DASHBOARDFILTERS", defaultMessage: "Dashboard Filters" })}`}
          submitting={isSubmitting}
          handleSubmit={handleSubmit}
          size="md"
          submitBtnLabel={`${intl.formatMessage({ id: "BUTTONS.APPLY", defaultMessage: "Apply" })}`}
          isResetAction
          handleReset={() => handleClearForm(resetForm)}
          key={modalKey}
        >
          <div className="row">
            <div className="col-12 col-lg-12">
              <Field
                type={"string"}
                component={XAutoComplete}
                name="product"
                label={`${intl.formatMessage({ id: "FILTER.LABEL.PRODUCT", defaultMessage: "Product" })}`}
                options={products}
                onChange={(selectedProduct: any) => {
                  if (selectedProduct && selectedProduct.id) {
                    handleProductChange(selectedProduct.id, setFieldValue);
                  } else {
                    setFieldValue("product", {
                      id: "",
                      label: "",
                      varieties: [],
                      variety: null,
                    });
                    setVarieties([]);
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-12">
              <Field name="variety">
                {() => (
                  <Autocomplete
                    getOptionLabel={(option: IIdNamePair) => option.name || ""}
                    options={values.product?.varieties || []}
                    onChange={(event, value) => {
                      setFieldValue("product.variety", value || null);
                    }}
                    disabled={
                      !values.product?.id ||
                      values.product?.varieties?.length === 0
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          !values.product?.id
                            ? `${intl.formatMessage({ id: "FILTER.LABEL.VARIETY", defaultMessage: "Variety" })}`
                            : values.product?.varieties?.length === 0
                              ? `${intl.formatMessage({ id: "FILTER.LABEL.NOVARIETIES", defaultMessage: "Product had no varieties" })}`
                              : `${intl.formatMessage({ id: "FILTER.LABEL.VARIETY", defaultMessage: "Variety" })}`
                        }
                        variant="standard"
                        error={touched.product && Boolean(errors.product)}
                      />
                    )}
                  />
                )}
              </Field>
            </div>
            <div className="col-12 col-lg-12 pt-3">
              <Field
                type={"string"}
                component={XAutoComplete}
                name={"collectionCentre"}
                label={`${intl.formatMessage({ id: "FILTER.LABEL.COLLECTIONCENTRE", defaultMessage: "Collection Centre" })}`}
                options={collectionCentres || []}
                getOptionLabel={(option: any) => (option ? option.label : "")}
                onChange={(event: any, value: IOption) =>
                  handleCollectionCentreChange(value)
                }
              />
            </div>
            <div className="col-6">
              <Field
                type={"string"}
                as={XDatePicker}
                name={"startDate"}
                label={`${intl.formatMessage({ id: "FILTER.LABEL.FROM", defaultMessage: "From" })}`}
                onChange={(value: string) => setFieldValue("startDate", value)}
                maxDate={dayjs(new Date())}
              />
            </div>
            <div className="col-6">
              <Field
                type={"string"}
                as={XDatePicker}
                name={"endDate"}
                label={`${intl.formatMessage({ id: "FILTER.LABEL.TO", defaultMessage: "To" })}`}
                onChange={(value: string) => setFieldValue("endDate", value)}
                maxDate={dayjs(new Date())}
              />
            </div>
          </div>
        </ModalWrapper>
      )}
    </Formik>
  );
};

export default DashboardFilters;
