import React, { useEffect, useState } from "react";
import {
  IContactPerson,
  IHub,
  IProduct,
} from "../../../settings/interfaces/IHub";
import { ContactPeople } from "../../hubs/partials/_contactPeople";
import { FormattedMessage } from "react-intl";

type Props = {
  hub?: IHub;
};

const HubDetails = ({ hub }: Props) => {
  const contactPersons: IContactPerson[] = hub?.contactPersons || [];
  const products: IProduct[] = hub?.products || [];

  const contactPerson = contactPersons?.length ? contactPersons[0] : null;

  return (
    <>
      <div className="card shadow-sm border-0 py-3 px-3">
        <div className="card shadow-sm border-0 py-3 px-3">
          <div className="row">
            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage
                  id="DETAILS.PHONENUMBER"
                  defaultMessage="Phone number"
                />
              </strong>
              <br />
              <span>{hub?.phoneNumber}</span>
            </div>
            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage id="DETAILS.EMAIL" defaultMessage="Email" />
              </strong>
              <br />
              <span>{hub?.email}</span>
            </div>

            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage id="DETAILS.MAU" defaultMessage="Mau"/>
              </strong>
              <br />
              <span>{hub?.minimumAggregationUnit ?? "-"}</span>
            </div>

            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage
                  id="DETAILS.DISTRICT"
                  defaultMessage="District"
                />
              </strong>
              <br />
              <span>{hub?.district}</span>
            </div>
            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage
                  id="DETAILS.SUBCOUNTY"
                  defaultMessage="Sub-County"
                />
              </strong>
              <br />
              <span>{hub?.subCounty}</span>
            </div>
          </div>

          {/*<hr/>*/}
        </div>

        <ContactPeople contactPersons={contactPersons} />
      </div>
    </>
  );
};

export default HubDetails;
