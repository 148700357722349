import { IBatchExport } from "../../../app/modules/inventory/interfaces/IBatchExport";
import { IBatchSale } from "../../../app/modules/inventory/interfaces/IBatchSale";

export const INVENTORY_SALES_CONSTANTS = {
  STOP_FETCH: "STOP_FETCH",
  FETCH_DATA: "FETCH_DATA",
  POST_DATA: "POST_DATA",
  UPDATE_DATA: "UPDATE_DATA",
  GET_DETAILS: "GET_DETAILS",
  LOADING_DATA: "LOADING_DATA",
  SET_SORT_PARAMS: "SET_SORT_PARAMS",
  FILTER_DATA: "FILTER_DATA",
  RESET_FILTER: "RESET_FILTER",
  PAGINATION: "PAGINATION",
};

export interface IBatchSalesState {
  loading: boolean;
  data: IBatchSale[];
  filter: {
    show: boolean;
    data: IBatchSale[];
    range: {
      startDate: string;
      endDate: string;
    };
    key: string;
    pagination: {
      itemsPerPage: number;
      activePage: number;
      isLastPage: boolean;
    };
  };
  sorting: {
    order: string;
    column: string;
  };
  reset: boolean;
}

const initialState: IBatchSalesState = {
  loading: false,
  data: [],
  filter: {
    key: "",
    show: false,
    data: [],
    range: {
      startDate: "",
      endDate: "",
    },
    pagination: {
      itemsPerPage: 10,
      activePage: 1,
      isLastPage: false,
    },
  },
  sorting: {
    order: "asc",
    column: "",
  },
  reset: false,
};

export default function batchSalesReducer(state = initialState, action: any) {
  switch (action.type) {
    case INVENTORY_SALES_CONSTANTS.FILTER_DATA:
      return { ...state, ...action.payload, show: !state.filter.show };

    case INVENTORY_SALES_CONSTANTS.RESET_FILTER:
      initialState.filter.show = true;
      return { ...initialState, reset: true };

    case INVENTORY_SALES_CONSTANTS.SET_SORT_PARAMS:
      return { ...state, ...action.payload };

    case INVENTORY_SALES_CONSTANTS.PAGINATION:
      return { ...state, ...action.payload };

    case INVENTORY_SALES_CONSTANTS.FETCH_DATA: {
      return { ...state, loading: false, data: action.payload, reset: false };
    }

    case INVENTORY_SALES_CONSTANTS.POST_DATA: {
      const batchSale: IBatchExport = action.payload;
      return { ...state, data: [batchSale, ...state.data] };
    }

    case INVENTORY_SALES_CONSTANTS.LOADING_DATA: {
      return { ...state, loading: action.payload };
    }

    case INVENTORY_SALES_CONSTANTS.GET_DETAILS: {
      return { ...state, requestDetails: action.payload };
    }

    case INVENTORY_SALES_CONSTANTS.STOP_FETCH: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
}
