import {useDispatch} from "react-redux";
import {Field, Formik} from "formik";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {IUnit} from "../../interfaces/IHub";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {put} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {UnitSchema} from "../interfaces/UnitSchema";
import {useIntl} from "react-intl";

type Props = {
    initialValues: IUnit
}

const EditUnit = ({initialValues}: Props) => {

    const intl = useIntl()
    const dispatch = useDispatch();

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, `/units/${values.id}`)
        const data: IUnit = {
            ...values
        }

        put(url, data, (response) => {

            CloseModal("edit-unit", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.UPDATE_UNIT,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
    }, []);

    const validationSchema = UnitSchema(intl)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="edit-unit"
                    title="Edit Unit of Measurement"
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Display Name"
                                name="displayName"
                            />
                        </div>
                    </div>

                </ModalWrapper>
            }}

        </Formik>
    );
}

export default EditUnit;