import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import DeleteInputStockForm from "./modals/DeleteInputStockForm";
import EditInputStockForm from "./modals/EditInputStockForm";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import { useSelector } from "react-redux";
import { IState } from "../../../data/types";
import React, { useEffect, useState } from "react";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { FormattedMessage, useIntl } from "react-intl";
import { authService } from "../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
} from "../../hooks/roleChecker";

type Props = {
  close: () => void;
  show: boolean;
  selectedRow: IInputDistribution | null;
  selectedInputRowId: string | null;
};

const InputPurchaseDetails = ({
  close,
  show,
  selectedRow,
  selectedInputRowId,
}: Props) => {
  const intl = useIntl();
  const inputDetails = useSelector((state: IState) =>
    state.inputs.data.find((input) => input.id === selectedInputRowId),
  );

  const [localInput, setLocalInput] = useState(inputDetails);

  useEffect(() => {
    setLocalInput(inputDetails);
  }, [inputDetails]);

  const formattedData = inputDetails
    ? {
        ...inputDetails,
        date: printNaturalDateShort(inputDetails.date),
      }
    : null;

  const actionButtons: IHeaderButtonProps[] = [];

  const [loading, setLoading] = React.useState(true);
  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoading(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);

  if (isHubAdmin || isGlobalAdmin) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.DELETE", defaultMessage: "Delete" })}`,
      cssClass: "btn btn-outline-secondary",
      dataTarget: "#delete-input-stock",
      dataToggle: "modal",
    });
  }

  if (isGlobalAdmin || isHubManager || isHubAdmin) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
      cssClass: "btn btn-primary",
      dataTarget: "#edit-input-stock",
      dataToggle: "modal",
    });
  }

  return (
    <>
      <XSidebarWrapper close={close} show={show} width={35}>
        <div className="d-flex justify-content-start w-100 align-items-center">
          <div className="py-3 px-3 rounded-1 bg-primary">
            <h2>
              {localInput?.inputName && localInput?.inputName[0].toUpperCase()}
            </h2>
          </div>
          <div className="px-3">
            <h5>{localInput?.inputName}</h5>
            <div className="small">
              UGX {Number(localInput?.totalCost).toLocaleString()}
            </div>
            <div className="fs-6 small">
              <span className="badge text-bg-info">
                {Number(localInput?.quantity).toLocaleString() +
                  " " +
                  localInput?.unitName}
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className="p-3 bg-gray rounded-3 mb-2">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id={"DETAILS.DATEOFSTOCKING"}
                  defaultMessage={"Date of Stocking"}
                />
              </strong>
              <br />
              <span>{printNaturalDateShort(localInput?.date)}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id={"DETAILS.INPUTCATEGORY"}
                  defaultMessage={"Input Category"}
                />
              </strong>
              <br />
              <span>{"-"}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id={"DETAILS.UNITPRICE"}
                  defaultMessage={"Unit Price"}
                />
              </strong>
              <br />
              <span>UGX {Number(localInput?.unitCost).toLocaleString()}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id={"DETAILS.COLLECTIONCENTER"}
                  defaultMessage={"Collection Centre"}
                />
              </strong>
              <br />
              <span>{localInput?.collectionCentreName || "-"}</span>
            </div>
          </div>
        </div>
        <div className="p-3 bg-gray rounded-3 mb-2">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <span className="small fw-semibold">
                <FormattedMessage
                  id={"DETAILS.SOURCE"}
                  defaultMessage={"Source"}
                />
              </span>
              <br />
              <span>{"-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id={"DETAILS.SOURCECONTACT"}
                  defaultMessage={"Source Contact"}
                />
              </strong>
              <br />
              <span>{"-"}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id={"DETAILS.AGENT"}
                  defaultMessage={"Agent"}
                />
              </strong>
              <br />
              <span>{"-"}</span>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="d-flex justify-content-end gap-3 w-50">
            {actionButtons.map((button, index) => {
              return (
                <button
                  key={index}
                  className={button.cssClass}
                  data-bs-toggle={button.dataToggle}
                  data-bs-target={button.dataTarget}
                  onClick={() => button.onClick}
                >
                  {button.label}
                </button>
              );
            })}
          </div>
        </div>
      </XSidebarWrapper>

      <DeleteInputStockForm item={selectedRow} />
      <EditInputStockForm initialValues={selectedRow} />
    </>
  );
};

export default InputPurchaseDetails;
