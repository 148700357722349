import * as Yup from "yup";
import { IntlShape } from "react-intl";

export const CollectionCentreSchema = (intl: IntlShape) => Yup.object({
    name: Yup.string().nullable().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
    district: Yup.object().nullable().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
    subCounty: Yup.object().nullable().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
    // hubId: Yup.string().required('Required')
})