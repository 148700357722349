import TableWrapper from "../../components/shared/TableWrapper";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../components/shared/XPagination";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import { IApiResponse } from "../../interfaces/IApiResponse";
import { IState } from "../../../data/types";
import { remoteRoutes } from "../../../data/constants";
import { buildUrl } from "../../../utils/queryBuilder";
import { get } from "../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../data/toastDefaults";
import { IBuyer } from "./interfaces/IBuyer";
import {
  BUYERS_CONSTANTS,
  IBuyerState,
} from "../../../data/redux/inventory/buyersReducer";
import XTable, {
  IRowAction,
  ITableColumn,
} from "../../components/shared/XTable";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import CreateBuyerForm from "./modals/CreateBuyerForm";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";
import { useIntl } from "react-intl";
import { IUnit } from "../settings/interfaces/IHub";
import EditBuyerForm from "./modals/EditBuyerForm";
import DeleteBuyerForm from "./modals/DeleteBuyerForm";
import debounce from "lodash.debounce";
import { authService } from "../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../hooks/roleChecker";

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.NAME",
    label: "Name",
    id: "name",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.EMAIL",
    label: "Email",
    id: "email",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.TELEPHONE",
    label: "Phone Number",
    id: "phoneNumber",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.EORINUMBER",
    label: "EORINumber",
    id: "eoriNumber",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.ADDRESS",
    label: "Address",
    id: "address",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];

export const Buyers = () => {
  document.title = "Inventory > Buyers";

  const intl = useIntl();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    email: "",
    eoriNumber: "",
    address: "",
    phoneNumber: ""
  };

  const { currentUser } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredBuyers, setFilteredBuyers] = useState<IBuyer[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);

  const { buyers, loading }: IBuyerState = useSelector(
    (state: IState) => state.buyers,
  );
  const { pagination, data } = buyers;

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [selectedBuyer, setSelectedBuyer] = useState<IBuyer>(initialValues);
  const [id, setId] = useState<string>("");

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: BUYERS_CONSTANTS.LOADING_BUYERS,
      payload: state,
    });
  };

  const filterBuyers = () => {
    const data: IBuyer[] = buyers?.data;
    if (searchTerm) {
      setFilteredBuyers(
        data.filter((buyer) =>
          buyer.name?.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredBuyers(data);
    }
  };

  useEffect(() => {
    filterBuyers();
  }, [searchTerm, buyers]);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize);
  }, [page]);

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.inventoryService) {
      const hubId = currentUser?.hubId;
      let params: any = { hubId, page, pageSize };

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(remoteRoutes.inventoryService, "/buyers", params);
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          dispatch({
            type: BUYERS_CONSTANTS.FETCH_BUYERS,
            payload: { data, pagination: paginationMetadata },
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: BUYERS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchTerm(value);
      setPage(1);
      handleFetchData(page, pagination.pageSize, value);
    }, 800),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: IBuyer) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        // Remove the id if it's already in the array
        newSelected.splice(index, 1);
      } else {
        // Add the id if it's not in the array
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: IBuyer) => {
    setDrawerVisible(!drawerVisible);
    setSelectedBuyer(item);
  };

  useEffect(() => {
    setBreadcrumbs([
      { label: "Inventory", url: undefined },
      { label: "Buyers", url: undefined },
    ]);
  }, []);

  const actionButtons: IHeaderButtonProps[] = [
    {
      label: `${intl.formatMessage({ id: "BUTTONS.ADDNEWBUYER", defaultMessage: "Add a New Buyer" })}`,
      cssClass: "btn btn-primary btn-sm",
      dataTarget: "#new-buyer",
      dataToggle: "modal",
    },
  ];

  const rowActions: IRowAction[] = [];

  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoading(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);
  const isMerchant = IsMerchant(roles);

  if (isGlobalAdmin || isHubAdmin || isHubManager || isMerchant) {
    rowActions.push({
      label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
      onClick: (item: IBuyer) => {
        setSelectedBuyer(item ?? "");
      },
      icon: "pencil",
      dataTarget: "#edit-buyer",
      dataToggle: "modal",
    });
  }

  if (isGlobalAdmin || isHubAdmin || isMerchant) {
    rowActions.push({
      label: `${intl.formatMessage({ id: "BUTTONS.DELETE", defaultMessage: "Delete" })}`,
      onClick: (item: IBuyer) => {
        setId(item.id ?? "");
      },
      icon: "trash",
      dataTarget: "#delete-buyer",
      dataToggle: "modal",
    });
  }

  return (
    <>
      <div className={"py-3"}>
        <InnerMenu module={Modules.Inventory} />
      </div>

      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-auto">
            <input
              type="text"
              onKeyUp={(event) => handleSearch(event)}
              ref={searchInputRef}
              onChange={(event) => handleSearchChange(event)}
              className="form-control"
              placeholder={intl.formatMessage({
                id: "TABLE.SEARCH",
                defaultMessage: "Type to search...",
              })}
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
            {/*    <FontAwesomeIcon icon={faFilter} className="text-orange"/>*/}
            {/*    <span className="ms-2"><FormattedMessage*/}
            {/*        id="BUTTONS.FILTERS"*/}
            {/*        defaultMessage="Filters"*/}
            {/*    /></span>*/}
            {/*</button>*/}
            {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">*/}
            {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
            {/*</button>*/}

            {showActionButtons &&
              actionButtons.map((button, index) => {
                return (
                  <button
                    data-bs-toggle={button.dataToggle}
                    data-bs-target={button.dataTarget}
                    className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                    key={index}
                    onClick={button.onClick}
                  >
                    {button.processing
                      ? `${intl.formatMessage({
                        id: "LOADERS.PLEASEWAIT",
                        defaultMessage: "Please wait...",
                      })}`
                      : button.label}
                  </button>
                );
              })}
          </div>
        </div>

        <XTable
          checked={false}
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={filteredBuyers}
          columns={columns}
          rowActions={rowActions}
          loading={loading}
        />

        <XPagination
          dataLength={buyers.data.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <EditBuyerForm initialValues={selectedBuyer} />
      <DeleteBuyerForm id={id} pagination={pagination} />
      <CreateBuyerForm hubId={currentUser?.hubId} pagination={pagination} />
    </>
  );
};
