import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {Field, Form, Formik} from "formik";
import {post} from "../../../../utils/ajax";
import {buildUrl} from "../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../data/constants";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import moment from "moment";
import {useAuth} from "../../auth";
import {useEffect, useState} from "react";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {XTextArea} from "../../../components/shared/forms/XTextArea";
import {ICollectionCentre, IHub} from "../../settings/interfaces/IHub";
import {authService} from "../../../../data/oidc/AuthService";
import {CombineBatchesSchema} from "../schemas/MoveBatchSchema";
import {IBatch} from "../interfaces/IBatch";
import {useIntl} from "react-intl";

type Props = {
    batches: IBatch[]
}

export const movements = [
    {id: 1, label: "Centre to Centre"},
    {id: 2, label: "Agent to Agent", disabled: true},
    {id: 3, label: "Farmer to Agent", disabled: true},
]

export const CombineBatchesForm = ({batches}: Props) => {

    const intl = useIntl();
    const {currentUser} = useAuth()
    const [locations, setLocations] = useState<any[] | undefined>(undefined)

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        batchNumber: '',
        description: ''
    }

    useEffect(() => {

        const hub: IHub | undefined = authService.getHub()

        if (hub) {
            const {collectionCentres} = hub
            setLocations(collectionCentres?.map((center: ICollectionCentre) => ({label: center.name, id: center.id})))
        }
    }, [])

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const dataToSubmit = {
            hubId: currentUser?.hubId,
            productId: batches[0].productId,
            productName: batches[0].productName,
            date: moment().toISOString(),
            quantity: batches.reduce((accumulator: number, item) => accumulator + item.quantity, 0),
            batchingCollectionCentreId: batches[0].batchingCollectionCentreId,
            batchingCollectionCentreName: batches[0].batchingCollectionCentreName,
            batchNumber: values.batchNumber,
            description: values.description,
            unitId: batches[0].unitId,
            unitName: batches[0].unitName?.toLowerCase(),
            batchIds: batches.map((batch, index) => (batch.id))
        }

        const url = buildUrl(remoteRoutes.inventoryService, '/batches')

        post(url, dataToSubmit, (response) => {

            CloseModal("combine-batches", () => {
                setSubmitting(false);
                resetForm();
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const validationSchema = CombineBatchesSchema(intl)
    return (

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({handleSubmit, isSubmitting, errors, values, setFieldValue}) => {

                return <ModalWrapper
                    id="combine-batches"
                    title={`${intl.formatMessage({ id: "FORM.TITLE.COMBINEBATCHES", defaultMessage: "Combine Batches" })}`}
                    size="md"
                    submitting={isSubmitting}
                    key={modalKey}
                    handleSubmit={handleSubmit}>
                    <Form>
                        <div className="row">

                            <div className="col-lg-12">
                                <Field
                                    disabled={isSubmitting}
                                    component={XTextArea}
                                    rows={6}
                                    value={values.batchNumber}
                                    label={`${intl.formatMessage({ id: "FORM.BATCHNUMBER", defaultMessage: "Batch Number" })}`}
                                    name="batchNumber"
                                />
                            </div>

                            <div className="col-lg-12">
                                <Field
                                    disabled={isSubmitting}
                                    component={XTextArea}
                                    rows={6}
                                    value={values.description}
                                    label={`${intl.formatMessage({ id: "FORM.DESCRIPTION", defaultMessage: "Description" })}`}
                                    name="description"
                                />
                            </div>
                        </div>
                    </Form>
                </ModalWrapper>

            }}
        </Formik>


    )
}

