import React, { useEffect, useState } from "react";
import { IProcessRun } from "../interfaces/IProcessRun";
import toast from "react-hot-toast";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { useDispatch } from "react-redux";
import { get, post, put } from "../../../../utils/ajax";
import { PROCESS_RUN_CONSTANTS } from "../../../../data/redux/processing/processRunsReducer";
import { ProcessingSchema } from "../schemas/ProcessingSchema";
import { useIntl } from "react-intl";

interface Props {
    initialValues: IProcessRun
}

const EditProcessRunForm = ({ initialValues }: Props) => {

    const intl = useIntl();

    const [modalKey, setModalKey] = useState(Date.now());
    const [varieties, setVarieties] = useState<IOption[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        handelFetchVarieties();
    }, [initialValues, dispatch]);

    const handelFetchVarieties = () => {
        const url = buildUrl(remoteRoutes.onboardingService, `/products/${initialValues.productId}`);

        get(url, (response) => {

            const data = response;

            if (!data || !data.varieties) {
                console.error("No varieties found in product data");
                setVarieties([]);
                return;
            }

            const varietyList = data.varieties
                .filter((variety: { id: string }) =>
                    initialValues
                        ? variety.id !== initialValues.inputVarietyId
                        : true
                )
                .map((variety: { id: any; name: any }) => ({
                    id: variety.id ?? '',
                    label: variety.name ?? 'Unnamed Variety',
                }))
                .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

            setVarieties(varietyList);

        }, (error) => {
            console.error("Error fetching data:", error);
        });
    };


    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.processingService, `/process-runs/${initialValues.id}`)
        const data: IProcessRun = {
            ...values,
            outputQuantity: values.outputQuantity,
            outputUnit: values.outputUnit.id,
            comment: values.comment,
            outputVarietyName: values.outputVarietyName.label,
            outputVarietyId: values.outputVarietyName.id,
        }

        put(url, data, (response) => {

            CloseModal("edit-process-run", () => {
                setSubmitting(false);
                resetForm();
            });

            dispatch({
                type: PROCESS_RUN_CONSTANTS.UPDATE_PROCESS_RUN,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {
        })
    }

    const units = [
        { id: "kg", label: "kg" },
        { id: "mt", label: "mt" }
    ]

    const validationSchema = ProcessingSchema(intl)

    return (
        <Formik
            initialValues={{
                ...initialValues,
                outputUnit: initialValues.outputUnit ? {
                    label: initialValues.outputUnit,
                    id: initialValues.outputUnit
                } : null,
                outputVarietyName: initialValues.outputVarietyName ? {
                    label: initialValues.outputVarietyName,
                    id: initialValues.outputVarietyId
                } : null,
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validate={(values) => {
                const errors: any = {};
                if (values?.outputQuantity != null && values.outputQuantity > initialValues.inputQuantity) {
                    errors.outputQuantity = `Output quantity cannot exceed ${initialValues.inputQuantity}`;
                }
                return errors;
            }}
        >
            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id={'edit-process-run'}
                    title={'Edit Process Run'}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={varieties}
                                label="Output Variety"
                                name="outputVarietyName"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Output Quantity"
                                name="outputQuantity"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                label="Output Units"
                                name="outputUnit"
                                options={units}
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Comments"
                                name="comment"
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default EditProcessRunForm