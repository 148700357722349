import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import React, { useEffect, useState } from "react";
import { IBatch, IBatchMovement } from "./interfaces/IBatch";
import { remoteRoutes } from "../../../data/constants";
import { put } from "../../../utils/ajax";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../data/toastDefaults";
import { useSelector } from "react-redux";
import { useAuth } from "../auth";
import useFetchPurchases from "../../hooks/useFetchPurchases";
import { IState } from "../../../data/types";
import XTable from "../../components/shared/XTable";
import { PurchaseColumns, useDynamicColumns } from "./Purchases";
import XPagination from "../../components/shared/XPagination";
import { XSearchFilter } from "../../components/shared/XSearchFilter";
import { printBatchStatus } from "../../../utils/enumHelpers";
import { BatchStatus } from "./interfaces/BatchStatus";
import { ReceiveBatchForm } from "./modals/ReceiveBatchForm";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import EditBatchMovementForm from "./modals/EditBatchMovementForm";
import DeleteBatchMovementForm from "./modals/DeleteBatchMovementForm";
import { authService } from "../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../hooks/roleChecker";

type Props = {
  close: () => void;
  show: boolean;
  batchMovement?: IBatchMovement | null | undefined;
  batch: IBatch | null | undefined;
};

export function BatchMovementDetails({
  close,
  show,
  batch,
  batchMovement,
}: Props) {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const { fetchPurchases, pagination } = useFetchPurchases();
  const { data, loading }: any = useSelector(
    (state: IState) => state.purchases,
  );

  batchMovement = useSelector((state: IState) =>
    Array.isArray(state.batchMovements.data)
      ? state.batchMovements.data.find((b) => b.id === batchMovement?.id)
      : null,
  );

  batch = useSelector((state: IState) =>
    Array.isArray(state.batches.data)
      ? state.batches.data.find((b) => b.id === batch?.id)
      : null,
  );

  const [batchMovementDetails, setBatchMovementDetails] = useState(batch);

  useEffect(() => {
    setBatchMovementDetails(batch);
  }, [batch]);

  const [updatingStatus, setUpdatingStatus] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const columns = useDynamicColumns();

  useEffect(() => {
    if (!batch?.id) return;

    let params: any = {
      hubId: currentUser?.hubId,
      id: batch.id,
    };

    if (searchTerm) params = { ...params, searchTerm };

    fetchPurchases(page, 7, params);
  }, [batch, page, searchTerm]);

  const handleSearchPurchases = (event: any) => {
    const { value } = event.target;
    if (!value) setSearchTerm(undefined);
    else {
      if (value && event.keyCode === 13) {
        setSearchTerm(value);
      }
    }
  };

  const handleConfirmDelivery = (batch: any) => {
    setUpdatingStatus(true);
    const updatedBatch = { ...batch, currentStatus: BatchStatus.Delivered };

    const url = remoteRoutes.inventoryService + "/batches/" + batch?.id;
    put(
      url,
      updatedBatch,
      (response) => {
        toast.success(toastMessages.default.success, overrideToastDefaults);
        setUpdatingStatus(false);
      },
      (error) => {
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  const formattedData = data.map((row: { date: string }) => ({
    ...row,
    date: printNaturalDateShort(row.date),
  }));

  const [loader, setLoader] = React.useState(true);
  // const [roles, setRoles] = React.useState<string[]>([]);

  // useEffect(() => {
  //   const fetchRoles = async () => {
  //     const roles = await authService.getRoles();
  //     setRoles(roles);
  //     setLoader(false);
  //   };
  //   fetchRoles();
  // }, []);

  const roles = authService.getRoles();
  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isMerchant = IsMerchant(roles);

  return (
    <>
      <XSidebarWrapper
        title={`${intl.formatMessage({
          id: "FORM.TITLE.BATCHDETAILS",
          defaultMessage: "Batch Movement Details",
        })}`}
        width={50}
        close={close}
        show={show}
      >
        <div className="d-flex justify-content-between w-100 align-items-baseline border-bottom mb-2">
          <div className="py-3">
            <h5>{batchMovementDetails?.batchNumber?.toUpperCase() || ""}</h5>
            <div className="small">
              {Number(batchMovementDetails?.quantity).toLocaleString()}{" "}
              {batch?.unitName}
            </div>
            <div className="fs-6 small">
              <span className="badge text-bg-info">
                {batchMovementDetails?.productName}
              </span>
            </div>
          </div>
          <div
            className={
              "d-flex align-items-center justify-content-around my-auto gap-1"
            }
          >
            <div className="btn-group" role="group">
              {batchMovementDetails?.currentStatus !== 3 &&
              batchMovementDetails?.currentStatus !== 6 ? (
                <>
                  {(isGlobalAdmin || isHubAdmin || isMerchant) && (
                    <button
                      type="button"
                      className={`btn btn-outline-dark dropdown-toggle ${updatingStatus ? "disabled" : ""}`}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {updatingStatus ? "Processing..." : "Manage"}
                    </button>
                  )}

                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        data-bs-toggle={"modal"}
                        data-bs-target={"#receive-batch"}
                        href="#"
                      >
                        Receive Batch
                      </a>
                    </li>
                  </ul>
                </>
              ) : (
                <span>
                  {printBatchStatus(batchMovementDetails?.currentStatus)}
                </span>
              )}
            </div>
            {batchMovementDetails?.currentStatus !== 2 &&
            batchMovementDetails?.currentStatus !== 4 ? (
              <>
                {/*{(isHubAdmin ||*/}
                {/*  isMerchant ||*/}
                {/*  isGlobalAdmin ||*/}
                {/*  isHubManager) && (*/}
                {/*  <div>*/}
                {/*    <button*/}
                {/*      className="btn btn-primary"*/}
                {/*      data-bs-toggle={"modal"}*/}
                {/*      data-bs-target={"#edit-batch-movement"}*/}
                {/*    >*/}
                {/*      <FormattedMessage*/}
                {/*        id={"BUTTONS.EDIT"}*/}
                {/*        defaultMessage={"Edit"}*/}
                {/*      />*/}
                {/*    </button>*/}
                {/*  </div>*/}
                {/*)}*/}

                {(isHubAdmin || isMerchant || isGlobalAdmin) && (
                  <div>
                    <button
                      className="btn btn-danger"
                      data-bs-toggle={"modal"}
                      data-bs-target={"#delete-batch-movement"}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className="scrollable-sidebar">
          <div className="p-3 bg-gray rounded-3 mb-2">
            <div className="row">
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.BATCHEDFROM"
                    defaultMessage="Batched from"
                  />
                </strong>
                <br />
                <span>
                  {batchMovementDetails?.batchingCollectionCentreName}
                </span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.CURRENTLOCATION"
                    defaultMessage="Current Location"
                  />
                </strong>
                <br />
                <span>{batchMovementDetails?.currentCollectionCentreName}</span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.STATUS"
                    defaultMessage="Status"
                  />
                </strong>
                <br />
                <span>
                  {printBatchStatus(batchMovementDetails?.currentStatus)}
                </span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.QUANTITY"
                    defaultMessage="Quantity"
                  />
                </strong>
                <br />
                <span>
                  {Number(batchMovementDetails?.quantity).toLocaleString()}{" "}
                  {batchMovementDetails?.unitName}
                </span>
              </div>
            </div>
          </div>

          {batchMovementDetails?.description && (
            <div className="p-3 bg-gray rounded-3 mb-2">
              <div className="row">
                <div className="col-lg-12 col-12 mb-2">
                  <strong className="small fw-semibold">
                    <FormattedMessage
                      id="DETAILS.DESCRIPTION"
                      defaultMessage="Description"
                    />
                  </strong>
                  <br />
                  <span>{batchMovementDetails?.description}</span>
                </div>
              </div>
            </div>
          )}

          <div className="rounded-3 mt-3">
            <h5>
              <FormattedMessage
                id="TABLE.TITLE.PURCHASES"
                defaultMessage="Purchases"
              />
            </h5>
            <XSearchFilter onKeyUp={(event) => handleSearchPurchases(event)} />
            <XTable
              checked={false}
              data={formattedData}
              columns={columns}
              loading={loading}
            />
            <XPagination
              dataLength={data.length}
              pagination={pagination}
              currentPage={page}
              setPage={(page) => setPage(page)}
            />
          </div>
        </div>
      </XSidebarWrapper>
      {batchMovementDetails && (
        <>
          <EditBatchMovementForm initialValues={batchMovement} />
          <DeleteBatchMovementForm
            id={batchMovement?.id}
            close={close}
            pagination={pagination}
          />
        </>
      )}
      <ReceiveBatchForm batch={batch} movement={batchMovement} />
    </>
  );
}
