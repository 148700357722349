import { Skeleton } from "@mui/material";
import React from "react";
import { SummaryCardLoader } from "./SummaryCardLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { TableLoader } from "./TableLoader";
import TableWrapper from "../TableWrapper";

const DashboardLoader = () => {
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body d-flex w-100 justify-content-between">
              <div className="d-flex justify-content-start align-items-center w-75">
                {Array.from({ length: 3 }).map((item, index) => {
                  return (
                    <Skeleton
                      width={200}
                      height={50}
                      className="me-2"
                      style={{ borderRadius: 20 }}
                      variant={"text"}
                      key={index}
                    />
                  );
                })}
              </div>

              <div className="d-flex justify-content-end align-items-center w-25">
                <Skeleton width={50} height={50} variant={"circular"} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <Skeleton
            height={400}
            className="w-100 mb-3"
            style={{ borderRadius: 10 }}
            variant={"rounded"}
          />
          <Skeleton
            height={350}
            className="w-100 mb-3"
            style={{ borderRadius: 10 }}
            variant={"rounded"}
          />
        </div>
        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-4">
              <Skeleton
                height={130}
                className="w-100 mb-3"
                style={{ borderRadius: 10 }}
                variant={"rounded"}
              />
            </div>
            <div className="col-lg-4">
              <Skeleton
                height={130}
                className="w-100 mb-3"
                style={{ borderRadius: 10 }}
                variant={"rounded"}
              />
            </div>
            <div className="col-lg-4">
              <Skeleton
                height={130}
                className="w-100 mb-3"
                style={{ borderRadius: 10 }}
                variant={"rounded"}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Skeleton
                height={450}
                className="w-100 mb-3"
                style={{ borderRadius: 10 }}
                variant={"rounded"}
              />
            </div>
            <div className="col-lg-6">
              <Skeleton
                height={400}
                className="w-100 mb-3"
                style={{ borderRadius: 10 }}
                variant={"rounded"}
              />
            </div>
            <div className="col-lg-6">
              <Skeleton
                height={400}
                className="w-100 mb-3"
                style={{ borderRadius: 10 }}
                variant={"rounded"}
              />
            </div>
          </div>
        </div>
      </div>

      <TableWrapper>
        <TableLoader />
      </TableWrapper>
    </>
  );
};

export default DashboardLoader;
