import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { useIntl } from "react-intl";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { IRole } from "../../interfaces/IHub";
import { put } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

type Props = {
  initialValues: IRole;
};

const RoleDetails = ({ initialValues }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const url = buildUrl(remoteRoutes.onboardingService, `/roles/${values.id}`);
    const data: IRole = {
      ...values,
    };

    put(
      url,
      data,
      (response) => {
        CloseModal("view-role-details", () => {
          setSubmitting(false);
          resetForm();
        });

        dispatch({
          type: SETTINGS_CONSTANTS.UPDATE_ROLE,
          payload: data,
        });

        toast.success(toastMessages.default.success, overrideToastDefaults);
        // setModalKey(Date.now());
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  useEffect(() => {}, []);

  // const initialValues = {
  //   roleName: initialValues?.name,
  //   roleDescription: initialValues?.description,
  // };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <ModalWrapper
            id={"view-role-details"}
            title={`${intl.formatMessage({ id: "FORM.TITLE.EDITROLE", defaultMessage: "Edit Role" })}`}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
            size="md"
          >
            <div className="col-lg-12">
              <Field
                type={"string"}
                label={`${intl.formatMessage({ id: "FORM.ROLENAME", defaultMessage: "Role Name" })}`}
                name={"name"}
                disabled={isSubmitting}
                as={XTextField}
              />
            </div>
            <div className="col-lg-12">
              <Field
                type={"string"}
                label={`${intl.formatMessage({ id: "FORM.DESCRIPTION", defaultMessage: "Description" })}`}
                as={XTextField}
                name={"description"}
              />
            </div>
            {/*<div>*/}
            {/*  <h6>Permissions</h6>*/}
            {/*</div>*/}
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default RoleDetails;
