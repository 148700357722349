import React, { useEffect, useState } from "react";
import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import { IPurchase } from "./interfaces/IPurchase";
import PolygonMapComponent from "../../components/shared/loaders/PolygonMapComponent";
import { env } from "../../../data/constants";
import { LoadScript } from "@react-google-maps/api";
import { printComplianceStatus } from "../../../utils/enumHelpers";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DeletePurchaseForm from "./modals/DeletePurchaseForm";
import { IPaginationMetadata } from "../../interfaces/IApiResponse";
import EditPurchaseForm from "./modals/EditPurchaseForm";
import { useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { authService } from "../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../hooks/roleChecker";

type Props = {
  close: () => void;
  show: boolean;
  purchaseId: any;
  pagination: IPaginationMetadata;
};

const PurchaseDetails = ({ close, show, purchaseId, pagination }: Props) => {
  const [loader, setLoader] = React.useState(true);
  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoader(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);
  const isMerchant = IsMerchant(roles);

  const key = env.googleMapsApiKey ?? "";

  const intl = useIntl();

  const { data, loading }: any = useSelector(
    (state: IState) => state.purchases,
  );

  const purchase = useSelector((state: IState) =>
    Array.isArray(state.purchases.data)
      ? state.purchases.data.find((p) => p.id === purchaseId)
      : undefined,
  );

  const [purchaseDetail, setPurchaseDetail] = useState(purchase);

  useEffect(() => {
    setPurchaseDetail(purchase);
  }, [purchase]);

  if (!purchase) return null;

  const farmPolygonDrawing =
    purchaseDetail?.farmCompliance?.farmPolygonDrawing ?? [];

  return (
    <>
      <XSidebarWrapper
        title={intl.formatMessage({
          id: "DETAILS.TITLE.PURCHASEDETAILS",
          defaultMessage: "Purchase Details",
        })}
        width={50}
        close={close}
        show={show}
      >
        <div className="d-flex justify-content-between w-100 align-items-baseline">
          <div className="d-flex justify-content-start w-100 align-items-center">
            <div className="py-3 px-3 rounded-1 bg-primary">
              <h2>
                {purchaseDetail?.productName &&
                  purchaseDetail?.productName[0].toUpperCase()}
              </h2>
            </div>
            <div className="px-3">
              <h5>
                {purchaseDetail?.productName} - {purchaseDetail?.varietyName}
              </h5>
              <div className="fs-6 small">
                <span className="badge text-bg-purple-light">
                  {Number(purchaseDetail?.quantity).toLocaleString() +
                    " " +
                    purchaseDetail?.unitName}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-around my-auto gap-1">
            {(isGlobalAdmin || isHubAdmin || isHubManager || isMerchant) && (
              <button
                className="btn btn-primary"
                data-bs-toggle={"modal"}
                data-bs-target={"#edit-purchase"}
              >
                <FormattedMessage id={"BUTTONS.EDIT"} defaultMessage={"Edit"} />
              </button>
            )}

            {(isGlobalAdmin || isHubAdmin || isMerchant) && (
              <button
                className="btn btn-danger"
                data-bs-toggle={"modal"}
                data-bs-target={"#delete-purchase"}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            )}
          </div>
        </div>

        <hr />

        <div className="scrollable-sidebar">
          <div className="p-3 bg-gray rounded-3 mb-2">
            <div className="row">
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.DATEOFPURCHASE"
                    defaultMessage="Date of Purchase"
                  />
                </strong>
                <br />
                <span>
                  {printNaturalDateShort(purchaseDetail?.date) || "-"}
                </span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.FARMERNAME"
                    defaultMessage="Farmer Name"
                  />
                </strong>
                <br />
                <span>{purchaseDetail?.farmerName || "-"}</span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.UNITPRICE"
                    defaultMessage="Unit Price"
                  />
                </strong>
                <br />
                <span>
                  UGX{" "}
                  {Number(purchaseDetail?.unitPrice).toLocaleString() || "-"}
                </span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.TOTALPRICE"
                    defaultMessage="Total Price"
                  />
                </strong>
                <br />
                <span>
                  UGX{" "}
                  {Number(purchaseDetail?.totalPrice).toLocaleString() || "-"}
                </span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.FARMCODE"
                    defaultMessage="Farm Code"
                  />
                </strong>
                <br />
                <span>{purchaseDetail?.farmCode || "-"}</span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.STATUS"
                    defaultMessage="Status"
                  />
                </strong>
                <br />
                <span>
                  {printComplianceStatus(
                    purchaseDetail?.farmCompliance?.status,
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="bg-gray border-1 rounded-2 pb-3">
            <LoadScript googleMapsApiKey={key}>
              <PolygonMapComponent coordinates={farmPolygonDrawing} />
            </LoadScript>
          </div>
        </div>
      </XSidebarWrapper>
      {purchaseDetail && (
        <>
          <DeletePurchaseForm
            id={purchase.id}
            pagination={pagination}
            close={close}
          />
          <EditPurchaseForm initialValues={purchaseDetail} />
        </>
      )}
    </>
  );
};

export default PurchaseDetails;
