import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";

type IProps = {
  id: string;
  title: string;
  size?: "lg" | "sm" | "xl" | "md";
  submitting: boolean;
  handleSubmit: () => void;
  handleReset?: () => void;
  children: any;
  position?: "centered" | "top";
  submitBtnLabel?: string;
  isDeleteAction?: boolean;
  isResetAction?: boolean;
  dismissable?: boolean;
  disabled?: boolean;
};

export const ModalWrapper = ({
  id,
  title,
  size = "lg",
  position = "centered",
  submitting,
  handleSubmit,
  handleReset,
  submitBtnLabel = "Submit",
  children,
  dismissable = true,
  isDeleteAction,
  isResetAction,
  disabled = false,
}: IProps) => {
  const intl = useIntl();
  return (
    <div
      data-bs-backdrop={"static"}
      className="modal fade"
      id={id}
      aria-hidden="true"
    >
      <div
        className={`modal-dialog modal-dialog-${position} modal-dialog-scrollable modal-${size}`}
      >
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header d-flex align-items-center justify-content-between">
              <h6>{title}</h6>
              {dismissable && (
                <div
                  className={`btn btn-sm btn-icon ${submitting ? "disabled" : ""}`}
                  data-bs-dismiss="modal"
                >
                  <FontAwesomeIcon size={"1x"} icon={faClose} />
                </div>
              )}
            </div>

            <div className="modal-body p-3">
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100">{children}</div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="d-flex w-100 justify-content-end">
                {!submitting && (
                  <div>
                    {isDeleteAction && (
                      <div
                        data-bs-dismiss="modal"
                        className={`btn me-2 btn-sm btn-gray ${submitting ? "disabled" : ""}`}
                      >
                        <span className="me-2">
                          <FormattedMessage
                            id="BUTTONS.CANCEL"
                            defaultMessage="Cancel"
                          />
                        </span>
                      </div>
                    )}
                    {isResetAction && handleReset && (
                      <button
                        className={`btn me-2 btn-gray ${submitting ? "disabled" : ""}`}
                        onClick={handleReset}
                      >
                        <span className="ms-2">
                          <FormattedMessage
                            id="BUTTONS.RESET"
                            defaultMessage="Reset"
                          />
                        </span>
                      </button>
                    )}
                    <button
                      type="submit"
                      className={`btn btn-${isDeleteAction ? "danger" : "primary"}`}
                      onClick={handleSubmit}
                      disabled={disabled}
                    >
                      {submitBtnLabel
                        ? submitBtnLabel
                        : !isDeleteAction
                          ? `${intl.formatMessage({ id: "BUTTONS.SUBMIT", defaultMessage: "Submit" })}`
                          : `${intl.formatMessage({ id: "BUTTONS.DELETE", defaultMessage: "Delete" })}`}
                    </button>
                  </div>
                )}
                {submitting && (
                  <button className="btn btn-dark" disabled>
                    <span className="">
                      <FormattedMessage
                        id="LOADERS.PLEASEWAIT"
                        defaultMessage="Please wait..."
                      />{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
