import {useState} from 'react'

import {authService} from "../../../../data/oidc/AuthService";
import {ICoreState} from "../../../../data/redux/coreReducer";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage} from "react-intl";

const logoStyle = {
    width: 50,
    height: 'auto'
};

const rightDivStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2rem'
};

const bottomTextStyle = {
    marginTop: 'auto',
    marginBottom: '1rem'
};

const centerTextStyle = {
    marginTop: 'auto'
};


export function Login() {

    const [loading, setLoading] = useState(false)
    const authState: ICoreState = useSelector((state: any) => state.core)
    const {isLoading, user} = authState

    const authenticateUser = (event: React.ChangeEvent<any>) => {

        event.preventDefault();
        setLoading(true)

        authService.login()
    }

    return (
        <>

            <div className="d-flex w-100 h-100 align-items-center">
                <div className="w-50 vh-100" style={{
                    backgroundImage: "url('/authBg.jpg')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: "cover",
                    backgroundPosition: 'center'
                }}>
                </div>
                <div className="w-50 vh-100 p-5 d-flex flex-column">
                    <img style={logoStyle} alt="logo" src="/logo.png"/>

                    <div style={{}}>
                        <h1 className='text-dark mt-3'>
                            <FormattedMessage id={"SIGNIN"} defaultMessage={"Sign in to Symos"} />
                        </h1>
                        <p>
                            <FormattedMessage id="YouNeedToLogin" defaultMessage="You need to login to access your account." />
                        </p>

                        <div className="d-inline-flex">
                            <button
                                style={{width: 200}}
                                onClick={authenticateUser}
                                className='btn btn-dark mt-5'
                            >
                                {!loading && <span>
                                    <FormattedMessage id="ContinueToLogin" defaultMessage="Continue to login" />
                                    <FontAwesomeIcon className="ms-2"
                                                                                      icon={faArrowRight}/></span>}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                      <FormattedMessage id="LOADERS.PLEASEWAIT" defaultMessage={"Please wait..."} />
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>

                    <div className='text-dark' style={bottomTextStyle}>
                        * <FormattedMessage id="TransformYourAgribusiness" />
                    </div>
                </div>
            </div>

        </>


    )
}