import { useState, useEffect } from 'react';
import { get } from '../../utils/ajax';
import { buildUrl } from '../../utils/queryBuilder';
import { remoteRoutes } from '../../data/constants';
import { IOption } from '../components/shared/forms/XAutoComplete';

export const useGeographicData = () => {
    const [districts, setDistricts] = useState<IOption[]>([]);
    const [counties, setCounties] = useState<IOption[]>([]);
    const [subCounties, setSubCounties] = useState<IOption[]>([]);
    const [parishes, setParishes] = useState<IOption[]>([]);

    const [selectedDistrictId, setSelectedDistrictId] = useState<string | number | null>(null);
    const [selectedCountyId, setSelectedCountyId] = useState<string | number | null>(null);
    const [selectedSubCountyId, setSelectedSubCountyId] = useState<string | number | null>(null);

    // Fetch Districts
    const fetchDistricts = () => {
        if (remoteRoutes.geoLookupService) {
            const metadataUrl = buildUrl(remoteRoutes.geoLookupService, '/districts?page=1&pageSize=1');

            get(metadataUrl, (metadataResponse: any) => {
                const { paginationMetadata } = metadataResponse;

                if (!paginationMetadata) {
                    console.error("Pagination metadata not found.");
                    return;
                }

                const totalItems = paginationMetadata.totalItems;
                const url = buildUrl(remoteRoutes.geoLookupService, `/districts?page=1&pageSize=${totalItems}`);

                get(
                    url, (response: any) => {
                        const districtList = response.data?.map((d: any) => ({
                            id: d.id ?? '',
                            label: d.name,
                        })).sort((a: IOption, b: IOption) => a.label.localeCompare(b.label));

                        setDistricts(districtList);
                    }
                );
            });
        }
    };

    // Fetch Counties for a specific District
    const fetchCounties = (districtId: string | number | null) => {
        if (districtId === null || districtId === undefined) {
            console.warn("Selected District ID is undefined. Fetching counties skipped.");
            return;
        }

        const url = buildUrl(remoteRoutes.geoLookupService, `/districts/${districtId}`);

        get(url, (metadataResponse: any) => {
            const district = metadataResponse;

            if (!district || !district.counties) {
                console.error("District or counties data not found.");
                return;
            }

            const countyList = district.counties
                .map((county: { id: string; name: string }) => ({
                    id: county.id,
                    label: county.name
                }))
                .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

            setCounties(countyList);
        }, (error) => {
            console.error("Error fetching district data:", error);
        });
    };

    // Fetch SubCounties for a specific County
    const fetchSubCounties = (countyId: string | number | null) => {
        if (countyId === null || countyId === undefined) {
            console.warn("Selected County ID is undefined. Fetching sub-counties skipped.");
            return;
        }

        const url = buildUrl(remoteRoutes.geoLookupService, `/counties/${countyId}`);

        get(url, (metadataResponse: any) => {
            const county = metadataResponse;

            if (!county || !county.subCounties) {
                console.error("County data not found.");
                return;
            }

            const subCountyList = county.subCounties
                .map((subCounty: { id: string; name: string }) => ({
                    id: subCounty.id,
                    label: subCounty.name
                }))
                .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

            setSubCounties(subCountyList);
        }, (error) => {
            console.error("Error fetching county data:", error);
        });
    };

    // Fetch Parishes for a specific SubCounty
    const fetchParishes = (subCountyId: string | number | null) => {
        if (subCountyId === null || subCountyId === undefined) {
            console.warn("Selected Sub-County ID is undefined. Fetching parishes skipped.");
            return;
        }

        const url = buildUrl(remoteRoutes.geoLookupService, `/subCounties/${subCountyId}`);

        get(url, (metadataResponse: any) => {
            const subCounty = metadataResponse;

            if (!subCounty || !subCounty.parishes) {
                console.error("Sub-County data not found.");
                return;
            }

            const parishList = subCounty.parishes
                .map((parish: { id: string; name: string }) => ({
                    id: parish.id,
                    label: parish.name
                }))
                .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

            setParishes(parishList);
        }, (error) => {
            console.error("Error fetching sub-county data:", error);
        });
    };

    // Use Effects to trigger fetches based on selections
    useEffect(() => {
        fetchDistricts();
    }, []);

    useEffect(() => {
        if (selectedDistrictId !== null && selectedDistrictId !== undefined) {
            fetchCounties(selectedDistrictId);
        }
    }, [selectedDistrictId]);

    useEffect(() => {
        if (selectedCountyId !== null && selectedCountyId !== undefined) {
            fetchSubCounties(selectedCountyId);
        }
    }, [selectedCountyId]);

    useEffect(() => {
        if (selectedSubCountyId !== null && selectedSubCountyId !== undefined) {
            fetchParishes(selectedSubCountyId);
        }
    }, [selectedSubCountyId]);

    return {
        districts,
        counties,
        subCounties,
        parishes,
        setSelectedDistrictId,
        setSelectedCountyId,
        setSelectedSubCountyId,
        fetchDistricts,
        fetchCounties,
        fetchSubCounties,
        fetchParishes
    };
};
