import React, { useEffect, useState } from "react";
import { remoteRoutes } from "../../../../data/constants";
import { useAuth } from "../../auth";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { IPaginationMetadata } from "../../../interfaces/IApiResponse";
import { useDispatch } from "react-redux";
import { Loading } from "../../../components/shared/Loading";
import { FormattedMessage } from "react-intl";

interface IInventoryStock {
  label: string;
  percentage: number;
  color: string;
  volume: string;
  units: string;
  variety: string;
  varietyId: string;
}

interface Props {
  productId: string | null | undefined;
  collectionCentreId: any | null | undefined;
  varietyId: string | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}

const InventoryStockProgress: React.FC<Props> = ({
  productId,
  collectionCentreId,
  varietyId,
  startDate,
  endDate,
}) => {
  const { currentUser } = useAuth();

  const [inventoryStockData, setInventoryStockData] = useState<
    IInventoryStock[]
  >([]);
  const [loading, setLoading] = useState(false);

  const colors = [
    "#AA0050",
    "#5F1ED9",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#4D5360",
  ];

  const handleFetchData = () => {
    if (remoteRoutes.inventoryService) {
      const hubId = currentUser?.hubId;

      let params: any = { hubId };

      if (productId) {
        params.productId = productId;
      }

      if (collectionCentreId) {
        params.collectionCentreId = collectionCentreId;
      }

      if (varietyId) {
        params.varietyId = varietyId;
      }

      if (startDate) {
        params.startDate = startDate;
      }

      if (endDate) {
        params.endDate = endDate;
      }

      const url = buildUrl(
        remoteRoutes.inventoryService,
        "/dashboard/stock/cards",
        params,
      );

      setLoading(true);

      get(
        url,
        (response) => {
          const transformedData = response.map(
            (
              item: {
                product: any;
                volume: any;
                unit: any;
                variety: any;
                varietyId: any;
              },
              index: number,
            ) => ({
              label: item.product || "Unknown Product",
              volume: Number(item.volume).toLocaleString(),
              units: item.unit,
              variety: item.variety,
              varietyId: item.varietyId,
              percentage: Math.random() * 100,
              color: colors[index % colors.length],
            }),
          );

          setInventoryStockData(transformedData);
          setLoading(false);
        },
        (error) => {
          console.error("API Error:", error);
          setLoading(false);
        },
      );
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [productId, collectionCentreId, varietyId, startDate, endDate]);

  return (
    <>
      {loading ? (
        <div
          className="loader d-flex justify-content-center align-items-center"
          style={{ height: 150 }}
        >
          <Loading />
        </div>
      ) : inventoryStockData.length === 0 ? (
        <div
          className="empty-message d-flex justify-content-center align-items-center"
          style={{ height: 150 }}
        >
          <p>
            <FormattedMessage
              id={"INVENTORY.DASHBOARD.NODATA"}
              defaultMessage="No data to display"
            />
          </p>
        </div>
      ) : (
        inventoryStockData.map((item, index) => (
          <div
            key={index}
            className="border-1 w-100 border border-gray-200 p-3 mb-2"
            style={{ borderRadius: 8 }}
          >
            <div className="row">
              <div className="col-12 d-flex align-items-center gap-2">
                <div
                  className="progress-dot"
                  style={{ background: item.color }}
                ></div>
                <div className="progress-label">
                  {item.label} - {item.variety}
                </div>
              </div>
              <div className="col-12 mx-4">
                <span>
                  <b>{item.volume}</b>
                </span>
                <span> {item.units}</span>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default InventoryStockProgress;
