import { Formik } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { IInputDistribution } from "../interfaces/IInputDistribution";
import { useState } from "react";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import { del } from "../../../../utils/ajax";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { useIntl } from "react-intl";

interface Props {
  item: IInputDistribution | null;
}

const DeleteInputStockForm = ({ item }: Props) => {
  const intl = useIntl();

  const [modalKey, setModalKey] = useState(Date.now());

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const url = buildUrl(
      remoteRoutes.inputsService,
      `/input/purchases/${item?.id}`,
    );

    del(
      url,
      (response) => {
        CloseModal("delete-input-stock", () => {
          setSubmitting(false);
          resetForm();
        });

        setModalKey(Date.now());
        toast.success(toastMessages.deleteSuccessful.success, overrideToastDefaults);
        window.location.reload();
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.deleteSuccessful.fail, overrideToastDefaults);
      },
      () => { },
    );
  };

  return (
    <Formik initialValues={{ item }} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id="delete-input-stock"
            title={`${intl.formatMessage({ id: "FORM.TITLE.DELETEINPUTSTOCK", defaultMessage: "Delete Input Stock Entry" })}`}
            size="md"
            isDeleteAction={true}
            key={modalKey}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>{`${intl.formatMessage({ id: "DELETE.INPUTSTOCK", defaultMessage: "Are you sure you want to delete this Input Stock Entry? This action cannot be undone." })}`}</p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteInputStockForm;
