import dayjs from "dayjs";
import * as Yup from "yup";

export const InputSaleSchema = Yup.object({
    date: Yup.mixed().required("Required")
        .test("is-valid-dayjs", "Required", (value) => {
            return dayjs.isDayjs(value) && value.isValid();
        }),
    farmerName: Yup.object().required("Farmer's name is required"),
    inputName: Yup.object().required("Input is required"),
    unitName: Yup.object().required("Unit is required"),
    quantity: Yup.number().required("Quantity is required"),
    totalPrice: Yup.number().required("Total amount is required"),
    amountPaid: Yup.number().required("Amount paid is required"),
})