import { FC } from "react";
import { useLang } from "./Symosi18n";
import { IntlProvider } from "react-intl";

import lugandaMessages from "./messages/luganda.json";
import runyankoleMessages from "./messages/runyankore.json";
import lhukonzoMessages from "./messages/lhukonzo.json";
import runyoroMessages from "./messages/runyoro.json";
import enMessages from "./messages/english.json";
import swMessages from "./messages/swahili.json";
import atesoMesages from "./messages/ateso.json";
import acholiMessages from "./messages/acholi.json";
import langiMessages from "./messages/langi.json";
import lusogaMessages from "./messages/lusoga.json";
import lugisuMessages from "./messages/lugisu.json";

const allMessages = {
  luganda: lugandaMessages,
  english: enMessages,
  swahili: swMessages,
  lhukonzo: lhukonzoMessages,
  runyoro: runyoroMessages,
  runya: runyankoleMessages,
  ateso: atesoMesages,
  acholi: acholiMessages,
  langi: langiMessages,
  lusoga: lusogaMessages,
  lugisu: lugisuMessages,
};

const I18nProvider: FC<any> = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
