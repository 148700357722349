import * as Yup from "yup";
import {IntlShape} from "react-intl";

export const CreateBatchSchema = (intl: IntlShape) => Yup.object({
    batchNumber: Yup.string().required(`${intl.formatMessage({
        id: "ERROR.BATCHNUMBER.REQUIRED",
        defaultMessage: "Batch number is required"
    })}`),
    location: Yup.object().nullable().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
    product: Yup.object().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
})