import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import ConfirmInputDeliveryForm from "./modals/ConfirmInputDeliveryForm";
import EditInputsMovementForm from "./modals/EditInputsMovementForm";
import DeleteInputMovementForm from "./modals/DeleteInputMovementForm";
import { printMovementStatus } from "../../../utils/enumHelpers";
import { useSelector } from "react-redux";
import { IState } from "../../../data/types";
import React, { useEffect, useState } from "react";
import { IInputMovement } from "./interfaces/IInputMovement";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { FormattedMessage, useIntl } from "react-intl";
import { IPaginationMetadata } from "../../interfaces/IApiResponse";
import { authService } from "../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../hooks/roleChecker";

type Props = {
  close: () => void;
  show: boolean;
  selectedRow: any;
  selectedInputRowId: string | null;
  pagination: IPaginationMetadata;
};

const InputMovementDetails = ({
  close,
  show,
  selectedRow,
  selectedInputRowId,
  pagination,
}: Props) => {
  const intl = useIntl();

  const inputDetails = useSelector((state: IState) =>
    Array.isArray(state.inputMovements.data)
      ? state.inputMovements.data.find((i) => i.id === selectedInputRowId)
      : undefined,
  );

  const [localInput, setLocalInput] = useState(inputDetails);

  useEffect(() => {
    setLocalInput(inputDetails);
  }, [inputDetails]);

  const actionButtons: IHeaderButtonProps[] = [];

  const [loader, setLoader] = React.useState(true);
  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoader(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);

  if (isGlobalAdmin || isHubAdmin || isHubManager) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
      cssClass: "btn btn-primary",
      dataTarget: "#edit-input-movement",
      dataToggle: "modal",
    });
  }

  if (isGlobalAdmin || isHubAdmin) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.DELETE", defaultMessage: "Delete" })}`,
      cssClass: "btn btn-outline-secondary",
      dataTarget: "#delete-input-movement",
      dataToggle: "modal",
    });
  }

  const status = printMovementStatus(selectedRow?.movementStatus);
  return (
    <>
      <XSidebarWrapper close={close} show={show} width={35}>
        <div className="d-flex justify-content-start w-100 align-items-center">
          <div className="py-3 px-3 rounded-1 bg-primary">
            <h2>
              {localInput?.inputName && localInput?.inputName[0].toUpperCase()}
            </h2>
          </div>
          <div className="px-3">
            <h5>{localInput?.inputName}</h5>
            <div className="small">
              {localInput?.quantity?.toLocaleString() +
                " " +
                localInput?.unitName}
            </div>
            <div className="fs-6">{status}</div>
          </div>

          {(isGlobalAdmin || isHubAdmin || isHubManager) && (
            <div className={"w-75 text-end"}>
              {localInput?.movementStatus === 1 ? (
                <>
                  <button
                    className={`btn btn-outline-dark`}
                    data-bs-toggle={"modal"}
                    data-bs-target={"#confirm-input-delivery"}
                  >
                    <FormattedMessage
                      id="BUTTONS.CONFIRMDELIVERY"
                      defaultMessage="Confirm Delivery"
                    />
                  </button>
                </>
              ) : null}
            </div>
          )}
        </div>
        <hr />
        <div className="p-3 bg-gray rounded-3 mb-3">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.MOVEMENTNUMBER"
                  defaultMessage="Movement Number"
                />
              </strong>
              <br />
              <span>{localInput?.movementNumber || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage id="DETAILS.DATE" defaultMessage="Date" />
              </strong>
              <br />
              <span>{printNaturalDateShort(localInput?.date)}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.SOURCE.ORIGIN"
                  defaultMessage="Source/ Origin"
                />
              </strong>
              <br />
              <span>{localInput?.sourceCollectionCentreName || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.DESTINATION"
                  defaultMessage="Destination"
                />
              </strong>
              <br />
              <span>{localInput?.destinationCollectionCentreName || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage id="DETAILS.AGENT" defaultMessage="Agent" />
              </strong>
              <br />
              <span>{localInput?.sourceAgentName || "-"}</span>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="d-flex justify-content-end gap-3 w-50">
            {actionButtons.map((button, index) => {
              return (
                <button
                  key={index}
                  className={button.cssClass}
                  data-bs-toggle={button.dataToggle}
                  data-bs-target={button.dataTarget}
                  onClick={() => button.onClick}
                >
                  {button.label}
                </button>
              );
            })}
          </div>
        </div>
      </XSidebarWrapper>
      {localInput && (
        <>
          <ConfirmInputDeliveryForm selectedRow={localInput} />
          <DeleteInputMovementForm
            item={localInput}
            pagination={pagination}
            close={close}
          />
          <EditInputsMovementForm selectedRow={localInput} />
        </>
      )}
    </>
  );
};

export default InputMovementDetails;
