export const AUTH_TOKEN_KEY = "__symos__backoffice__token";
export const AUTH_USER_KEY = "__symos__backoffice__user";
export const AUTH_HUB_KEY = "__symos__backoffice__hub";
export const AUTH_CATEGORIES_KEY = "__symos__backoffice__categories";
export const AUTH_ROLES_KEY = "__symos__backoffice__roles";
export const AUTH_ID_KEY = "__symos__backoffice__id";


export const systemRoles = {
    contacts: {
        view: "contacts_view",
        edit: "contacts_edit",
        chc: "contacts_chc",
        teams: "contacts_teams",
    },
};

export const redux = {
    doLogin: "DO_LOGIN",
    doLogout: "DO_LOGOUT",
    doSearch: "DO_SEARCH",
};

export const localRoutes = {
    callback: "/callback",
    reports: "/reports",
    dashboard: "/dashboard",
    contacts: "/contacts",
    settings: "/settings",
    users: "/users",
    usersDetails: "/users/:userId",
    help: "/help",
};

const evVar = process.env.NODE_ENV || "dev";
const environment = evVar.trim();
export const API_VERSION = "?v=2.0";
export const APPLICATIONS_API_VERSION = "v1";
export const APIM_VERSION = "1.0";

console.log(
    `############# Env : ${environment} | API VERSION: ${API_VERSION} ###############`
);

export const env = {
    profiles: process.env.REACT_APP_PROFILES,
    authServer: process.env.REACT_APP_AUTH,
    clientId: process.env.REACT_APP_CLIENTID,
    envScope: process.env.REACT_APP_CLIENT_SCOPES,
    inputsService: process.env.REACT_APP_INPUTS_SERVICE,
    trainingsService: process.env.REACT_APP_TRAINING_SERVICE,
    inventoryService: process.env.REACT_APP_INVENTORY_SERVICE,
    onboardingService: process.env.REACT_APP_ONBOARDING_SERVICE,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    complianceEngine: process.env.REACT_APP_COMPLIANCE_ENGINE,
    geoLookupService: process.env.REACT_APP_GEOLOOKUP_SERVICE,
    processingService: process.env.REACT_APP_COST_SERVICE
};

export const remoteRoutes = {
    profiles: env.profiles,
    authServer: env.authServer,
    inputsService: env.inputsService,
    inventoryService: env.inventoryService,
    onboardingService: env.onboardingService,
    trainingsService: env.trainingsService,
    forestCheckerService: process.env.REACT_APP_FOREST_COVER_SERVICE,
    complianceEngine: env.complianceEngine,
    geoLookupService: env.geoLookupService,
    processingService: env.processingService,
};