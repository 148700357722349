import {
  IHeaderButtonProps,
  SettingsPageHeader,
} from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import { ITableColumn, XTable } from "../../../components/shared/XTable";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import {
  ISettingsState,
  SETTINGS_CONSTANTS,
} from "../../../../data/redux/settings/settingsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import { ISystemProduct } from "../products/interfaces/ISystemProduct";
import SystemProductDetails from "./SystemProductDetails";
import CreateSystemProduct from "./modals/CreateSystemProductForm";
import { useAuth } from "../../auth";
import debounce from "lodash.debounce";
import { FormattedMessage, useIntl } from "react-intl";

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.PRODUCTNAME",
    label: "Product Name",
    id: "name",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.SCIENTIFICNAME",
    label: "Scientific Name",
    id: "scientificName",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.BRANDS",
    label: "Brand",
    id: "brand",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.PRODUCTCATEGORY",
    label: "Category",
    id: "categoryName",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];

type IProps = {
  title?: string;
  hubId?: string;
};

const SystemProducts = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const auth = useAuth();

  const initialValues = {
    name: "",
    units: [],
  };

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ISystemProduct[]>(
    [],
  );

  const { products, loading }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );
  const { pagination, data } = products;

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [selectedProduct, setSelectedProduct] =
    useState<ISystemProduct>(initialValues);

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.key === "Enter") {
      setSearchTerm(event.target.value);
    }
  };

  const productCategories = auth.categories?.productCategories;

  const setLoading = (state: boolean) => {
    dispatch({
      type: SETTINGS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const filterProducts = () => {
    const data: ISystemProduct[] = products.data.map(
      (product: ISystemProduct) => ({
        ...product,
        categoryName:
          productCategories?.find((q) => q.number == product.category)?.name ??
          "",
      }),
    );
    if (searchTerm) {
      setFilteredProducts(
        data.filter(
          (product) =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product.category
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredProducts(data);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  useEffect(() => {
    filterProducts();
  }, [searchTerm, products]);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [page, searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.onboardingService) {
      let params: any = { page, pageSize };

      const url = buildUrl(remoteRoutes.onboardingService, "/products", params);
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          dispatch({
            type: SETTINGS_CONSTANTS.FETCH_PRODUCTS,
            payload: { data, pagination: paginationMetadata },
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: ISystemProduct) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: ISystemProduct) => {
    setDrawerVisible(!drawerVisible);
    setSelectedProduct(item);
  };

  const actionButtons: IHeaderButtonProps[] = [
    {
      label: `${intl.formatMessage({ id: "BUTTONS.ADDPRODUCT", defaultMessage: "Add a Product" })}`,
      cssClass: "btn btn-primary btn-sm",
      dataTarget: "#new-system-product",
      dataToggle: "modal",
    },
  ];

  return (
    <>
      <SettingsPageHeader title="Settings > System Products" />
      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-auto">
            <input
              type="text"
              // onKeyUp={handleSearch}
              onChange={handleSearchChange}
              className="form-control"
              placeholder={intl.formatMessage({
                id: "TABLE.SEARCH",
                defaultMessage: "Type to search...",
              })}
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
            {/*    <FontAwesomeIcon icon={faFilter} className="text-orange"/>*/}
            {/*    <span className="ms-2">*/}
            {/*        <FormattedMessage*/}
            {/*            id={"BUTTONS.FILTERS"}*/}
            {/*            defaultMessage={"Filters"}*/}
            {/*        />*/}
            {/*    </span>*/}
            {/*</button>*/}
            {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">*/}
            {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
            {/*</button>*/}

            {showActionButtons &&
              actionButtons.map((button, index) => {
                return (
                  <button
                    data-bs-toggle={button.dataToggle}
                    data-bs-target={button.dataTarget}
                    className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                    key={index}
                    onClick={button.onClick}
                  >
                    {button.processing
                      ? `${intl.formatMessage({
                          id: "LOADERS.PLEASEWAIT",
                          defaultMessage: "Please Wait...",
                        })}`
                      : button.label}
                  </button>
                );
              })}
          </div>
        </div>

        <XTable
          checked={false}
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={filteredProducts}
          columns={columns}
          loading={loading}
        />

        <XPagination
          dataLength={data.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <CreateSystemProduct pagination={pagination} />
      <SystemProductDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        productId={selectedProduct.id}
      />
    </>
  );
};

export default SystemProducts;
