import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import React, { useEffect, useState } from "react";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import DeleteInputSaleForm from "./modals/DeleteInputSaleForm";
import EditInputSaleForm from "./modals/EditInputSaleForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleHalfStroke,
  faMultiply,
} from "@fortawesome/free-solid-svg-icons";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import { useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { authService } from "../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../hooks/roleChecker";

type Props = {
  close: () => void;
  show: boolean;
  selectedRow: IInputDistribution | null;
};

const InputSaleDetails = ({ close, show, selectedRow }: Props) => {
  const intl = useIntl();
  const location = useLocation();

  const inputDetails = useSelector((state: IState) =>
    state.inputs.data.find((input) => input.id === selectedRow?.id),
  );

  const [localInput, setLocalInput] = useState(inputDetails);

  useEffect(() => {
    setLocalInput(inputDetails);
  }, [inputDetails]);

  const actionButtons: IHeaderButtonProps[] = [];

  const roles = authService.getRoles();
  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);

  if (isGlobalAdmin || isHubAdmin || isHubManager) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
      cssClass: "btn btn-primary",
      dataTarget: "#edit-input-sale",
      dataToggle: "modal",
    });
  }
  if (isGlobalAdmin || isHubAdmin) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.DELETE", defaultMessage: "Delete" })}`,
      cssClass: "btn btn-outline-secondary",
      dataTarget: "#delete-input-sale",
      dataToggle: "modal",
    });
  }
  return (
    <>
      <XSidebarWrapper close={close} show={show} width={35}>
        <div className="d-flex justify-content-start w-100 align-items-center">
          <div className="py-3 px-3 rounded-1 bg-primary">
            <h2>
              {localInput?.inputName && localInput?.inputName[0].toUpperCase()}
            </h2>
          </div>
          <div className="row p-2">
            <div className="px-3">
              <h5>{localInput?.inputName}</h5>
              <div className="small">
                UGX {Number(localInput?.totalPrice).toLocaleString()}
              </div>
            </div>
            <div className="fs-6 d-flex justify-content-between w-100">
              <span className="badge text-bg-info">
                {Number(localInput?.quantity).toLocaleString() +
                  " " +
                  localInput?.unitName}
              </span>
              {localInput?.typeOfSale === 1 && (
                <>
                  <span className="badge bg-success">
                    <FormattedMessage
                      id="BADGE.FULLYPAID"
                      defaultMessage="Fully Paid"
                    />
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ paddingLeft: 5 }}
                    />
                  </span>
                </>
              )}
              {localInput?.typeOfSale === 2 && (
                <>
                  <span className="badge bg-danger">
                    <FormattedMessage
                      id="BADGE.ONCREDIT"
                      defaultMessage="On Credit"
                    />
                    <FontAwesomeIcon
                      icon={faMultiply}
                      style={{ paddingLeft: 5 }}
                    />
                  </span>
                </>
              )}
              {localInput?.typeOfSale === 3 && (
                <>
                  <span className="badge bg-orange">
                    <FormattedMessage
                      id="BADGE.PARTIALPAYMENT"
                      defaultMessage="Partial Payment"
                    />
                    <FontAwesomeIcon
                      icon={faCircleHalfStroke}
                      style={{ paddingLeft: 5 }}
                    />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="p-3 bg-gray rounded-3 mb-2">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.DATEOFDISTRIBUTION"
                  defaultMessage="Date of Distribution"
                />
              </strong>
              <br />
              <span>{printNaturalDateShort(localInput?.date)}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.INPUTCATEGORY"
                  defaultMessage="Input Category"
                />
              </strong>
              <br />
              <span>{"-"}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.UNITPRICE"
                  defaultMessage="Unit Price"
                />
              </strong>
              <br />
              <span>UGX {Number(localInput?.unitPrice).toLocaleString()}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.COLLECTIONCENTER"
                  defaultMessage="Collection Centre"
                />
              </strong>
              <br />
              <span>{localInput?.collectionCentreName || "-"}</span>
            </div>
          </div>
        </div>

        <div className="p-3 bg-gray rounded-3 mb-2">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <span className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.FARMERNAME"
                  defaultMessage="Farmer Name"
                />
              </span>
              <br />
              <span>{localInput?.farmerName || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.FARMERCONTACT"
                  defaultMessage="Farmer Contact"
                />
              </strong>
              <br />
              <span>{"-"}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.FARMERGROUP"
                  defaultMessage="Farmer Group"
                />
              </strong>
              <br />
              <span>{"-"}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">
                <FormattedMessage id="DETAILS.AGENT" defaultMessage="Agent" />
              </strong>
              <br />
              <span>{localInput?.agentName || "-"}</span>
            </div>
          </div>
        </div>

        {location.pathname.includes("/farmers") ? (
          <></>
        ) : (
          <div className="row justify-content-end">
            <div className="d-flex justify-content-end gap-3 w-50">
              {actionButtons.map((button, index) => {
                return (
                  <button
                    key={index}
                    className={button.cssClass}
                    data-bs-toggle={button.dataToggle}
                    data-bs-target={button.dataTarget}
                    onClick={() => button.onClick}
                  >
                    {button.label}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </XSidebarWrapper>
      <DeleteInputSaleForm item={selectedRow} />
      <EditInputSaleForm initialValues={selectedRow} />
    </>
  );
};

export default InputSaleDetails;
