import {
  ICategories,
  ICollectionCentre,
  IContactPerson,
  ICostCentre,
  IProcess,
  IRole,
  IUnit,
  IAgent
} from "../../../app/modules/settings/interfaces/IHub";
import { IInput } from "../../../app/modules/settings/inputs/interfaces/IInput";
import { IUser } from "../../../app/modules/onboarding/userManagement/interfaces/IUser";
import { IPaginationMetadata } from "../../../app/interfaces/IApiResponse";
import { ISystemProduct } from "../../../app/modules/settings/products/interfaces/ISystemProduct";

export const SETTINGS_CONSTANTS = {
  ATTACH_COLLECTION_CENTRES: "ATTACH_COLLECTION_CENTRES",
  DELETE_COLLECT_CENTRE: "DELETE_COLLECT_CENTRE",
  DELETE_CONTACT_PERSON: "DELETE_CONTACT_PERSON",
  DELETE_COST_CENTRE: "DELETE_COST_CENTRE",
  DELETE_INPUT: "DELETE_INPUT",
  DELETE_PROCESS: "DELETE_PROCESS",
  DELETE_ROLE: "DELETE_ROLE",
  DELETE_SYSTEM_PRODUCT: "DELETE_SYSTEM_PRODUCT",
  DELETE_USER: "DELETE_USER",
  FETCH_AGENTS: "FETCH_AGENTS", FETCH_CATEGORIES: "FETCH_CATEGORIES",
  FETCH_COLLECTION_CENTRES: "FETCH_COLLECTION_CENTRES",
  FETCH_CONTACT_PEOPLE: "FETCH_CONTACT_PEOPLE",
  FETCH_COST_CENTRES: "FETCH_COST_CENTRES",
  FETCH_DATA: "FETCH_DATA",
  FETCH_INPUTS: "FETCH_INPUTS",
  FETCH_PROCESSES: "FETCH_PROCESSES",
  FETCH_PRODUCTS: "FETCH_PRODUCTS",
  FETCH_ROLES: "FETCH_ROLES",
  FETCH_UNITS: "FETCH_UNITS",
  FETCH_USERS: "FETCH_USERS",
  FILTER_DATA: "FILTER_DATA",
  GET_DETAILS: "GET_DETAILS",
  LOADING_DATA: "LOADING_DATA",
  PAGINATION: "PAGINATION",
  POST_COLLECTION_CENTRES: "POST_COLLECTION_CENTRES",
  POST_CONTACT_PEOPLE: "POST_CONTACT_PEOPLE",
  POST_COST_CENTRES: "POST_COST_CENTRES",
  POST_DATA: "POST_DATA",
  POST_INPUTS: "POST_INPUTS",
  POST_PROCESSES: "POST_PROCESSES",
  POST_PRODUCTS: "POST_PRODUCTS",
  POST_ROLES: "POST_ROLES",
  POST_UNITS: "POST_UNITS",
  POST_USERS: "POST_USERS",
  RESET_FILTER: "RESET_FILTER",
  SET_SORT_PARAMS: "SET_SORT_PARAMS",
  STOP_FETCH: "STOP_FETCH",
  UPDATE_AGENT_DISTRICT: "UPDATE_AGENT_DISTRICT",
  UPDATE_COLLECTION_CENTRE: "UPDATE_COLLECTION_CENTRE",
  UPDATE_CONTACT_PERSON: "UPDATE_CONTACT_PERSON",
  UPDATE_COST_CENTRE: "UPDATE_COST_CENTRE",
  UPDATE_INPUT: "UPDATE_INPUT",
  UPDATE_PROCESS: "UPDATE_PROCESS",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  UPDATE_ROLE: "UPDATE_ROLE",
  UPDATE_UNIT: "UPDATE_UNIT",
  UPDATE_USER: "UPDATE_USER"
};

export interface ISettingsState {
  loading: boolean;
  inputs: {
    data: IInput[];
    pagination: IPaginationMetadata;
  };
  products: {
    data: ISystemProduct[];
    pagination: IPaginationMetadata;
  };
  costCenters: {
    data: ICostCentre[];
    pagination: IPaginationMetadata;
  };
  processes: {
    data: IProcess[];
    pagination: IPaginationMetadata;
  };
  collectionCentres: {
    data: ICollectionCentre[];
    pagination: IPaginationMetadata;
  };
  contactPeople: {
    data: IContactPerson[];
    pagination: IPaginationMetadata;
  };
  users: {
    data: IUser[];
    pagination: IPaginationMetadata;
  };
  agents: {
    data: IAgent[],
    pagination: IPaginationMetadata
  },
  roles: {
    data: IRole[];
    pagination: IPaginationMetadata;
  };
  units: {
    data: IUnit[];
    pagination: IPaginationMetadata;
  };
  categories: ICategories[];

  filter: {
    show: boolean;
    inputs: [];
    products: [];
    costCenters: [];
    processes: [];
    collectionCentres: [];
    contactPeople: [];
    users: [];
    units: [];
    roles: [];
    range: {
      startDate: string;
      endDate: string;
    };
    key: string;
    pagination: {
      itemsPerPage: number;
      activePage: number;
      isLastPage: boolean;
    };
  };
  sorting: {
    order: string;
    column: string;
  };
  reset: boolean;
}

const initialState: ISettingsState = {
  loading: false,
  inputs: {
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 5,
      hasPreviousPage: false,
      hasNextPage: false,
      totalItems: 0,
      totalPages: 0,
    },
  },
  products: {
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 5,
      hasPreviousPage: false,
      hasNextPage: false,
      totalItems: 0,
      totalPages: 0,
    },
  },
  costCenters: {
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 5,
      hasPreviousPage: false,
      hasNextPage: false,
      totalItems: 0,
      totalPages: 0,
    },
  },
  processes: {
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 5,
      hasPreviousPage: false,
      hasNextPage: false,
      totalItems: 0,
      totalPages: 0,
    },
  },
  collectionCentres: {
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 5,
      hasPreviousPage: false,
      hasNextPage: false,
      totalItems: 0,
      totalPages: 0,
    },
  },
  contactPeople: {
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 5,
      hasPreviousPage: false,
      hasNextPage: false,
      totalItems: 0,
      totalPages: 0,
    },
  },
  users: {
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 10,
      hasPreviousPage: false,
      hasNextPage: false,
      totalItems: 0,
      totalPages: 0,
    },
  },
  agents: {
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 5,
      hasPreviousPage: false,
      hasNextPage: false,
      totalItems: 0,
      totalPages: 0
    }
  },
  units: {
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 5,
      hasPreviousPage: false,
      hasNextPage: false,
      totalItems: 0,
      totalPages: 0,
    },
  },
  categories: [],
  roles: {
    data: [],
    pagination: {
      pageSize: 5,
      hasNextPage: false,
      currentPage: 1,
      hasPreviousPage: false,
      totalItems: 0,
      totalPages: 0,
    },
  },
  filter: {
    key: "",
    show: false,
    inputs: [],
    products: [],
    costCenters: [],
    processes: [],
    collectionCentres: [],
    contactPeople: [],
    users: [],
    units: [],
    roles: [],
    range: {
      startDate: "",
      endDate: "",
    },
    pagination: {
      itemsPerPage: 10,
      activePage: 1,
      isLastPage: false,
    },
  },
  sorting: {
    order: "asc",
    column: "",
  },
  reset: false,
};

export default function settingsReducer(state = initialState, action: any) {
  switch (action.type) {
    case SETTINGS_CONSTANTS.FILTER_DATA:
      return { ...state, ...action.payload, show: !state.filter.show };

    case SETTINGS_CONSTANTS.RESET_FILTER:
      initialState.filter.show = true;
      return { ...initialState, reset: true };

    case SETTINGS_CONSTANTS.SET_SORT_PARAMS:
      return { ...state, ...action.payload };

    case SETTINGS_CONSTANTS.PAGINATION:
      return { ...state, ...action.payload };

    case SETTINGS_CONSTANTS.FETCH_INPUTS: {
      return { ...state, loading: false, inputs: action.payload, reset: false };
    }

    case SETTINGS_CONSTANTS.FETCH_CATEGORIES: {
      return {
        ...state,
        loading: false,
        categories: action.payload,
        reset: false,
      };
    }

    case SETTINGS_CONSTANTS.FETCH_USERS: {
      return { ...state, loading: false, users: action.payload, reset: false };
    }

    case SETTINGS_CONSTANTS.FETCH_AGENTS: {
      return { ...state, loading: false, agents: action.payload, reset: false };
    }

    case SETTINGS_CONSTANTS.FETCH_UNITS: {
      return { ...state, loading: false, units: action.payload, reset: false };
    }

    case SETTINGS_CONSTANTS.FETCH_PRODUCTS: {
      return {
        ...state,
        loading: false,
        products: action.payload,
        reset: false,
      };
    }

    case SETTINGS_CONSTANTS.FETCH_COST_CENTRES: {
      return {
        ...state,
        loading: false,
        costCenters: action.payload,
        reset: false,
      };
    }

    case SETTINGS_CONSTANTS.FETCH_PROCESSES: {
      return {
        ...state,
        loading: false,
        processes: action.payload,
        reset: false,
      };
    }

    case SETTINGS_CONSTANTS.FETCH_COLLECTION_CENTRES: {
      return {
        ...state,
        loading: false,
        collectionCentres: action.payload,
        reset: false,
      };
    }

    case SETTINGS_CONSTANTS.FETCH_CONTACT_PEOPLE: {
      return {
        ...state,
        loading: false,
        contactPeople: action.payload,
        reset: false,
      };
    }

    case SETTINGS_CONSTANTS.FETCH_ROLES: {
      return {
        ...state,
        loading: false,
        roles: action.payload,
        reset: false,
      };
    }

    case SETTINGS_CONSTANTS.DELETE_ROLE: {
      const roleId: string = action.payload.id;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        roles: {
          data: state.roles.data.filter((role) => role.id !== roleId),
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.POST_ROLES: {
      const role: IRole = action.payload.data;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        roles: {
          data: [role, ...state.roles.data],
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.DELETE_INPUT: {
      const inputId: string = action.payload.id;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        inputs: {
          data: state.inputs.data.filter((input) => input.id !== inputId),
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_ROLE: {
      const updatedRole = action.payload;
      return {
        ...state,
        roles: {
          ...state.roles,
          data: state.roles.data.map((role) =>
            role.id === updatedRole.id ? updatedRole : role,
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.POST_INPUTS: {
      const input: IInput = action.payload.data;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        inputs: {
          data: [input, ...state.inputs.data],
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_INPUT: {
      const updatedInput = action.payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          data: state.inputs.data.map((input) =>
            input.id === updatedInput.id ? updatedInput : input,
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.POST_UNITS: {
      const unit: IUnit = action.payload.data;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        units: {
          data: [unit, ...state.units.data],
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_UNIT: {
      return {
        ...state,
        units: {
          ...state.units,
          data: state.units.data.map((unit) =>
            unit.id === action.payload.id ? action.payload : unit,
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.DELETE_PROCESS: {
      const processId: string = action.payload.id;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        processes: {
          data: state.processes.data.filter(
            (process) => process.id !== processId,
          ),
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.POST_PROCESSES: {
      const processes: IProcess = action.payload.data;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        processes: {
          data: [processes, ...state.processes.data],
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_PROCESS: {
      return {
        ...state,
        processes: {
          ...state.processes,
          data: state.processes.data.map((process) =>
            process.id === action.payload.id ? action.payload : process,
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.POST_USERS: {
      const users: IUser = action.payload.data;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        users: {
          data: [users, ...state.users.data],
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.DELETE_USER: {
      const userId: string = action.payload.id;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        users: {
          data: state.users.data.filter((user) => user.id !== userId),
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_USER: {
      const updatedData = action.payload;

      const updatedUser: IUser = {
        id: updatedData.userId,
        email: updatedData.email,
        phoneNumber: updatedData.phoneNumber,
        firstName: updatedData.claims.find((claim: { key: string; }) => claim.key === "given_name")?.value || "",
        lastName: updatedData.claims.find((claim: { key: string; }) => claim.key === "family_name")?.value || "",
        additionalClaims: updatedData.claims.filter(
          (claim: { key: string; }) => claim.key !== "given_name" && claim.key !== "family_name"
        ),
        password: ""
      };

      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map(user =>
            user.id === updatedUser.id ? { ...user, ...updatedUser } : user
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.DELETE_COLLECT_CENTRE: {
      const collectionCentreId: string = action.payload.id;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        collectionCentres: {
          data: state.collectionCentres.data.filter(
            (collectionCentre) => collectionCentre.id !== collectionCentreId,
          ),
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.ATTACH_COLLECTION_CENTRES: {
      const newCentres: ICollectionCentre[] =
        action.payload.data.collectionCentres || [];
      const existingCentres = state.collectionCentres.data;
      const updatedCentres = newCentres.map((newCentre) => {
        const existingCentre = existingCentres.find(
          (cc) => cc.id === newCentre.id,
        );
        return existingCentre ? { ...existingCentre, ...newCentre } : newCentre;
      });

      const pagination: IPaginationMetadata = action.payload.pagination;

      return {
        ...state,
        collectionCentres: {
          data: updatedCentres,
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.POST_COLLECTION_CENTRES: {
      const collectionCentre: ICollectionCentre = action.payload.data;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        collectionCentres: {
          data: [collectionCentre, ...state.collectionCentres.data],
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_COLLECTION_CENTRE: {
      const updatedCentre = action.payload;
      return {
        ...state,
        collectionCentres: {
          ...state.collectionCentres,
          data: state.collectionCentres.data.map((collectionCentre) =>
            collectionCentre.id === updatedCentre.id
              ? updatedCentre
              : collectionCentre,
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_AGENT_DISTRICT: {
      const { userId, claims } = action.payload;

      const updatedDistrictName = claims.find(
        (claim: { key: string }) => claim.key === "district_name"
      )?.value || "";

      const updatedDistrictId = claims.find(
        (claim: { key: string }) => claim.key === "district_id"
      )?.value || "";

      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map(user =>
            user.id === userId
              ? {
                ...user,
                districtName: updatedDistrictName,
                districtId: updatedDistrictId,
              }
              : user
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.DELETE_COST_CENTRE: {
      const costCentreId: string = action.payload.id;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        costCenters: {
          data: state.costCenters.data.filter(
            (costCenter) => costCenter.id !== costCentreId,
          ),
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.POST_COST_CENTRES: {
      const costCenter: ICostCentre = action.payload.data;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        costCenters: {
          data: [costCenter, ...state.costCenters.data],
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_COST_CENTRE: {
      const updatedCostCentre = action.payload;
      return {
        ...state,
        costCenters: {
          ...state.costCenters,
          data: state.costCenters.data.map((costCentre) =>
            costCentre.id === updatedCostCentre.id
              ? updatedCostCentre
              : costCentre,
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.DELETE_CONTACT_PERSON: {
      const contactPersonId: string = action.payload.id;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        contactPeople: {
          data: state.contactPeople.data.filter(
            (contactPerson) => contactPerson.id !== contactPersonId,
          ),
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.POST_CONTACT_PEOPLE: {
      const contactPerson: IContactPerson = action.payload.data;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        contactPeople: {
          data: [contactPerson, ...state.contactPeople.data],
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_CONTACT_PERSON: {
      const updatedContactPerson = action.payload;
      return {
        ...state,
        contactPeople: {
          ...state.contactPeople,
          data: state.contactPeople.data.map((contactPerson) =>
            contactPerson.id === updatedContactPerson.id
              ? updatedContactPerson
              : contactPerson,
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.DELETE_SYSTEM_PRODUCT: {
      const productId: string = action.payload.id;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        products: {
          data: state.products.data.filter(
            (product) => product.id !== productId,
          ),
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.POST_PRODUCTS: {
      const product: IContactPerson = action.payload.data;
      const pagination: IPaginationMetadata = action.payload.pagination;
      return {
        ...state,
        products: {
          data: [product, ...state.products.data],
          pagination,
        },
      };
    }

    case SETTINGS_CONSTANTS.UPDATE_PRODUCT: {
      return {
        ...state,
        products: {
          ...state.products,
          data: state.products.data.map((product) =>
            product.id === action.payload.id ? action.payload : product,
          ),
        },
      };
    }

    case SETTINGS_CONSTANTS.LOADING_DATA: {
      return { ...state, loading: action.payload };
    }

    case SETTINGS_CONSTANTS.GET_DETAILS: {
      return { ...state, requestDetails: action.payload };
    }

    case SETTINGS_CONSTANTS.STOP_FETCH: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
}
