import React, {useState} from 'react'
import {IBatchMovement} from "../interfaces/IBatch";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {Field, Formik} from "formik";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {XTextField} from "../../../components/shared/forms/XTextField";

interface Props {
    initialValues: IBatchMovement | null | undefined
}

const EditBatchMovementForm = ({initialValues}: Props) => {

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        CloseModal("edit-batch-movement", () => {
            setSubmitting(false);
            resetForm();
        })

        setModalKey(Date.now());
    }

    return (
        <Formik
            initialValues={{
                ...initialValues
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="edit-batch-movement"
                    title={'Edit Batch Movement'}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Batch Number"
                                name="batchNumber"
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default EditBatchMovementForm