import { SettingsPageHeader } from "../../../components/shared/SettingsPageHeader";
import React, { useCallback, useEffect, useState } from "react";
import TableWrapper from "../../../components/shared/TableWrapper";
import {
  ISettingsState,
  SETTINGS_CONSTANTS,
} from "../../../../data/redux/settings/settingsReducer";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../data/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faSearch,
  faTrashCanArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  IRowAction,
  ITableColumn,
  XTable,
} from "../../../components/shared/XTable";
import DeleteRoleForm from "./modals/DeleteRoleForm";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import XPagination from "../../../components/shared/XPagination";
import RoleDetails from "./modals/RoleDetails";
import { IButtonProps } from "../../../components/shared/PageHeader";
import CreateRoleForm from "./modals/CreateRoleForm";
import { IRole } from "../interfaces/IHub";
import debounce from "lodash.debounce";
import { useIntl } from "react-intl";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { authService } from "../../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../../hooks/roleChecker";

type IProps = {
  title?: string;
};

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.ROLENAME",
    label: "Role Name",
    id: "name",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.DESCRIPTION",
    label: "Description",
    id: "description",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];

const RolesPage = ({ title }: IProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    description: "",
  };

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [selectedRoleDetails, setSelectedRoleDetails] =
    useState<IRole>(initialValues);
  const { roles, loading }: ISettingsState = useSelector((state: IState) => {
    return state.settings;
  });
  const { pagination, data } = roles;
  const [page, setPage] = useState<number>(1);
  const [selectedRoleDelete, setSelectedRoleDelete] = useState<string>("");
  const [tableData, setTableData] = useState<IRole[]>([]);

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: SETTINGS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const filterRoles = () => {
    const data: IRole[] = roles?.data || [];
    if (searchTerm) {
      setTableData(
        data?.filter(
          (role) =>
            role.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            role.description
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setTableData(data);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  useEffect(() => {
    filterRoles();
  }, [roles, searchTerm]);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [page, searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.onboardingService) {
      let params: any = { page, pageSize };

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(remoteRoutes.onboardingService, "/roles", params);
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          dispatch({
            type: SETTINGS_CONSTANTS.FETCH_ROLES,
            payload: { data, pagination: paginationMetadata },
          });
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleRowClick = (item: IRole) => {
    setSelectedRoleDetails(item);
  };

  const actionButtons: IButtonProps[] = [
    {
      label: `${intl.formatMessage({ id: "BUTTONS.CREATENEWROLE", defaultMessage: "Create New Role" })}`,
      cssClass: "btn btn-primary btn-sm ms-2",
      dataTarget: "#add-role",
      dataToggle: "modal",
    },
  ];

  const rowActions: IRowAction[] = [
    {
      label: `${intl.formatMessage({ id: "BUTTONS.VIEWDETAILS", defaultMessage: "View Details" })}`,
      onClick: (item: IRole) => handleRowClick(item),
      icon: faEyeSlash,
      dataTarget: "#view-role-details",
      dataToggle: "modal",
    },
    {
      label: `${intl.formatMessage({ id: "BUTTONS.DELETE", defaultMessage: "Delete" })}`,
      onClick: (item: IRole) => {
        setSelectedRoleDelete(item.id ?? "");
      },
      icon: faTrashCanArrowUp,
      dataTarget: "#delete-role",
      dataToggle: "modal",
    },
  ];

  const [usage, setUsage] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const usage = await authService.getRoles();
      setUsage(usage);
      setLoading(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(usage);

  return (
    <>
      <>
        {title && <SettingsPageHeader title={title} />}
        <TableWrapper>
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="input-group w-auto">
              <input
                type="text"
                // onKeyUp={(event) => handleSearch(event)}
                onChange={handleSearchChange}
                className="form-control"
                placeholder={`${intl.formatMessage({ id: "TABLE.SEARCH", defaultMessage: "Type to search..." })}`}
              />
              <span className="input-group-text" id="addon-wrapping">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>

            <div className={"d-flex"}>
              <div className="action-buttons">
                {isGlobalAdmin && (
                  <>
                    {showActionButtons &&
                      actionButtons.map((button, index) => {
                        return (
                          <button
                            data-bs-toggle={button.dataToggle}
                            data-bs-target={button.dataTarget}
                            className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                            key={index}
                            onClick={button.onClick}
                          >
                            {button.processing
                              ? `${intl.formatMessage({ id: "LOADERS.PLEASEWAIT", defaultMessage: "Please wait..." })}`
                              : button.label}
                          </button>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>

          <XTable
            checked={false}
            columns={columns}
            loading={loading}
            data={tableData}
            rowActions={rowActions}
          />

          <XPagination
            currentPage={page}
            pagination={pagination}
            dataLength={tableData.length}
            setPage={(page) => setPage(page)}
          />
        </TableWrapper>
      </>
      <CreateRoleForm pagination={pagination} />
      <RoleDetails initialValues={selectedRoleDetails} />

      <DeleteRoleForm
        selectedRoleDelete={selectedRoleDelete}
        pagination={pagination}
      />
    </>
  );
};

export default RolesPage;
