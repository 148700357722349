import React, { useEffect, useState } from "react";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { IBatch } from "../interfaces/IBatch";
import { useDispatch } from "react-redux";
import { useAuth } from "../../auth";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get, post, put } from "../../../../utils/ajax";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { useIntl } from "react-intl";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import moment from "moment";
import { INVENTORY_BATCHES_CONSTANTS } from "../../../../data/redux/inventory/batchesReducer";
import { BatchStatus } from "../interfaces/BatchStatus";
import { PROCESS_RUN_CONSTANTS } from "../../../../data/redux/processing/processRunsReducer";

type Props = {
    selectedBatch?: IBatch | null
};

const SendForProcessingForm = ({ selectedBatch }: Props) => {

    const intl = useIntl();

    const [processes, setProcesses] = useState<IOption[]>([]);
    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());
    const [updatingStatus, setUpdatingStatus] = useState(false);

    const initialValues = {
        processes: [
            {
                id: null,
                label: null
            }
        ]
    };

    useEffect(() => {
        handleFetchData();
    }, [selectedBatch, dispatch]);

    const handleFetchData = () => {
        if (remoteRoutes.processingService) {

            const hubId = currentUser?.hubId
            const page = 1
            let pageSize = 1
            let params: any

            params = { hubId, page, pageSize };

            const metadataUrl = buildUrl(remoteRoutes.processingService, '/processes', params);

            get(metadataUrl, (metadataResponse: IApiResponse) => {

                const { paginationMetadata } = metadataResponse;
                if (!paginationMetadata) {
                    console.error("Pagination metadata not found.");
                    return;
                }

                const totalItems = paginationMetadata.totalItems;

                pageSize = totalItems
                params = { hubId, page, pageSize };

                const dataUrl = buildUrl(remoteRoutes.processingService, `/processes`, params);

                get(dataUrl, (response: IApiResponse) => {
                    const { data } = response;

                    const processList = data
                        ?.filter((p) =>
                            selectedBatch
                                ? p.products.some((product: { productId: string; }) => product.productId === selectedBatch.productId)
                                : true
                        )
                        .map((p) => ({
                            id: p.id ?? '',
                            label: p.name,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label));
                    setProcesses(processList)
                })
            })

        }
    }

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.processingService, '/process-runs');
        const data = {
            hubId: currentUser?.hubId,
            startTime: moment().toISOString().replace('Z', '+00:00'),
            processId: values.process.id,
            processName: values.process.label,
            batchId: selectedBatch?.id,
            batchNumber: selectedBatch?.batchNumber,
            collectionCentreId: selectedBatch?.currentCollectionCentreId,
            collectionCentreName: selectedBatch?.currentCollectionCentreName,
            productId: selectedBatch?.productId,
            productName: selectedBatch?.productName,
            inputVarietyName: selectedBatch?.variety,
            inputVarietyId: selectedBatch?.varietyId,
            inputQuantity: selectedBatch?.quantity,
            inputUnit: selectedBatch?.unitName,
        };

        post(
            url,
            data,
            (response) => {
                CloseModal("send-for-processing", () => {
                    setSubmitting(false);
                    resetForm();
                });

                dispatch({
                    type: PROCESS_RUN_CONSTANTS.POST_PROCESS_RUN,
                    payload: data,
                });

                handleUpdateStatus(BatchStatus.Processing);

                toast.success(toastMessages.default.success, overrideToastDefaults);
                setModalKey(Date.now());
            },
            (error) => {
                setSubmitting(false);
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => { }
        );
    };

    const handleUpdateStatus = (status: any) => {
        setUpdatingStatus(true);

        if (!selectedBatch?.id) {
            console.error("Batch ID is missing. Update cannot be performed.");
            setUpdatingStatus(false);
            return;
        }

        const updatedBatch = {
            ...selectedBatch,
            date: new Date(selectedBatch?.date).toISOString(),
            currentStatus: status,
        };

        const url = remoteRoutes.inventoryService + "/batches/" + selectedBatch?.id;
        put(
            url,
            updatedBatch,
            (response) => {
                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH,
                    payload: updatedBatch,
                });

                setUpdatingStatus(false);
            },
            (error) => {
                setUpdatingStatus(false);
            },
            () => { }
        );
    };

    useEffect(() => {
        handleFetchData();
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id="send-for-processing"
                    title={`${intl.formatMessage({ id: "FORM.TITLE.SENDFORPROCESSING", defaultMessage: "Send for Processing" })}`}
                    position="top"
                    submitting={isSubmitting}
                    size="sm"
                    submitBtnLabel="Send"
                    key={modalKey}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={processes}
                                name="process"
                                label={`${intl.formatMessage({ id: "FORM.SELECTPROCESS", defaultMessage: "Select Process" })}`}
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default SendForProcessingForm