import React, {useState} from "react";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {Formik} from "formik";
import {IBatch} from "../interfaces/IBatch";
import {buildUrl} from "../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../data/constants";
import {del} from "../../../../utils/ajax";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {useDispatch} from "react-redux";
import {IPaginationMetadata} from "../../../interfaces/IApiResponse";
import {INVENTORY_BATCHES_CONSTANTS} from "../../../../data/redux/inventory/batchesReducer";

interface Props {
    item: IBatch;
    pagination: IPaginationMetadata
    close: () => void;
}

const DeleteBatchForm = ({item, pagination, close}: Props) => {

    const dispatch = useDispatch()

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.inventoryService, `/batches/${item.id}`)

        const id = item.id
        del(url, (response) => {

            dispatch({
                type: INVENTORY_BATCHES_CONSTANTS.DELETE_BATCH,
                payload: {
                    id, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems - 1,
                    }
                }
            })
            CloseModal("delete-batch", () => {
                setSubmitting(false);
                resetForm();
            })

            close();

            setModalKey(Date.now());
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {
            console.log(error)
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={item}
            enableReinitialize={true}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="delete-batch"
                    title="Delete Batch"
                    size="md"
                    key={modalKey}
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <p>Are you sure you want to delete this batch? This action cannot be undone.</p>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default DeleteBatchForm;