import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOut} from "@fortawesome/free-solid-svg-icons";
import {authService} from '../../../data/oidc/AuthService';
import {FormattedMessage} from "react-intl";

export const Logout = () => {

    const handleLogout = async () => {
        await authService.logout();
    }

    return (
        <li onClick={handleLogout}>
            <a className={`dropdown-item py-2`} href="#">
                <div className="d-flex w-100 align-items-center justify-content-start">
                    <div>
                        <FontAwesomeIcon
                            className="me-2"
                            icon={faSignOut}
                            size="1x"/>
                    </div>
                    <div>
                        <FormattedMessage id="MENU.MAIN.SIGNOUT" defaultMessage={"Sign out"} />
                    </div>
                </div>
            </a>
        </li>
    )
}
