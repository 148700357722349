export const IsGlobalAdmin = (roles: string[] | undefined) => {
  if (roles) {
    return roles.includes("Global Administrator");
  }
};

export const IsHubAdmin = (roles: string[] | undefined) => {
  if (roles) {
    return roles.includes("Hub Administrator") || roles.includes("Hub Admin");
  }
}

export const IsHubManager = (roles: string[] | undefined) => {
  if (roles) {
    return roles.includes("Hub Manager");
  }
};

export const IsMerchant = (roles: string[] | undefined) => {
  if (roles) {
    return roles.includes("Merchant");
  }
};

export const IsAgent = (roles: string[] | undefined) => {
  if (roles) {
    return roles.includes("Agent");
  }
};

export const IsBatchDataAdmin = (roles: string[] | undefined) => {
  if (roles) {
    return roles.includes("Batch Data Admin");
  }
};
