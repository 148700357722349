import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  IOption,
  XAutoComplete,
} from "../../../components/shared/forms/XAutoComplete";
import { IFarmer, IInput } from "../../settings/interfaces/IHub";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { useAuth } from "../../auth";
import { useDispatch } from "react-redux";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  onApplyFilters: (filters: any) => void;
  setFilters: (filters: any) => void;
};

const InputSalesFilterForm: React.FC<Props> = ({
  onApplyFilters,
  setFilters,
}) => {
  const intl = useIntl();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [farmers, setFarmers] = useState<IFarmer[]>([]);
  const [loadingFarmers, setLoadingFarmers] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputs, setInputs] = useState<IInput[]>([]);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();

  const farmerList: IOption[] = loadingFarmers
    ? [
      {
        id: "loading",
        label: `${intl.formatMessage({ id: "LOADERS.FARMERLIST", defaultMessage: "Loading farmer list..." })}`,
      },
    ]
    : farmers
      .map((f: IFarmer) => ({
        id: f.id ?? "",
        label: f.fullname ?? "",
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

  const initialValues = {
    farmer: { label: "", id: "" },
    input: { label: "", id: "" },
    from: null,
    to: null,
  };

  const inputsList: IOption[] = inputs
    .map((i) => ({
      id: i.id ?? "",
      label: i.name ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    setLoading(true);
    handleFetchFarmers();
    handleFetchData();
  }, []);

  const handleFetchFarmers = () => {
    if (remoteRoutes.profiles) {
      const hubId = currentUser?.hubId;

      setLoadingFarmers(true);
      setFarmers([]);

      const fetchTotalFarmers = () => {
        const params = { hubId, page: 1, pageSize: 1 };
        const url = buildUrl(remoteRoutes.profiles, "/farmers", params);

        return new Promise((resolve, reject) => {
          get(
            url,
            (response: IApiResponse) => {
              const totalFarmers = response.paginationMetadata?.totalItems || 0;
              resolve(totalFarmers);
            },
            (error) => {
              console.error("Error fetching total farmers:", error);
              setLoadingFarmers(false);
              reject(error);
            },
          );
        });
      };

      const fetchAllFarmers = (totalFarmers: number) => {
        const params = { hubId, page: 1, pageSize: totalFarmers };
        const url = buildUrl(remoteRoutes.profiles, "/farmers", params);

        get(
          url,
          (response: IApiResponse) => {
            const { data } = response;
            setFarmers(data);
            setLoadingFarmers(false);
            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
          (error) => {
            console.error("Error fetching all farmers:", error);
            setLoadingFarmers(false);
            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        );
      };

      fetchTotalFarmers()
        .then((totalItems: any) => {
          if (totalItems > 0) {
            fetchAllFarmers(totalItems);
          } else {
            setLoadingFarmers(false);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch total farmers:", error);
          setLoadingFarmers(false);
        });
    }
  };

  const handleFetchData = () => {
    if (remoteRoutes.onboardingService) {
      let params: any = {
        pageSize: 100,
      };

      let url = "";

      const hubId = currentUser?.hubId;

      if (hubId) {
        params = { hubId };

        url = buildUrl(remoteRoutes.onboardingService, "/inputs", params);
        get(
          url,
          (response: IApiResponse) => {
            const { data } = response;
            setInputs(data);
          },
          async (error) => { },
          () => {

            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        );
      }
    }
  };

  const handleSubmit = (values: any) => {
    dayjs.extend(utc);

    const filters = {
      farmerId: values.farmer?.id ?? "",
      inputId: values.input?.id ?? "",

      from: values?.from
        ? fromZonedTime(
          toZonedTime(new Date(values.from), userTimeZone),
          "UTC",
        ).toISOString()
        : dayjs(0).utc().toISOString(),
      to: values?.to
        ? fromZonedTime(
          toZonedTime(new Date(values.to), userTimeZone),
          "UTC",
        ).toISOString()
        : dayjs().endOf("day").utc().toISOString(),
    };

    onApplyFilters(filters);
  };

  const handleClearForm = (resetForm: any) => {
    resetForm();
    setFilters({});
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ resetForm, values, dirty }) => {
          return (
            <Form>
              <div className={"row"}>
                <div className={"col-lg-12"}>
                  <div>
                    <Field
                      type="string"
                      component={XAutoComplete}
                      label={`${intl.formatMessage({ id: "FILTER.LABEL.FARMERNAME", defaultMessage: "Farmer name" })}`}
                      name={"farmer"}
                      value={values.farmer}
                      options={farmerList || []}
                      getOptionsLabel={(option: any) =>
                        option ? option.label : ""
                      }
                    />
                  </div>
                  <div>
                    <Field
                      type="string"
                      component={XAutoComplete}
                      label={`${intl.formatMessage({ id: "FILTER.LABEL.INPUTNAME", defaultMessage: "Input name" })}`}
                      name={"input"}
                      value={values.input}
                      options={inputsList || []}
                      getOptionsLabel={(option: any) =>
                        option ? option.label : ""
                      }
                    />
                  </div>
                  <div className="col-lg-12">
                    <Field
                      type="string"
                      label={`${intl.formatMessage({ id: "FILTER.LABEL.FROM", defaultMessage: "From" })}`}
                      as={XDatePicker}
                      name="from"
                      maxDate={dayjs(new Date())}
                    />
                  </div>
                  <div className="col-lg-12">
                    <Field
                      type="string"
                      label={`${intl.formatMessage({ id: "FILTER.LABEL.TO", defaultMessage: "To" })}`}
                      as={XDatePicker}
                      name="to"
                      maxDate={dayjs(new Date())}
                    />
                  </div>
                  <div className="border-top mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary ms-2"
                      disabled={!dirty}
                    >
                      <FormattedMessage
                        id="BUTTONS.APPLY"
                        defaultMessage="Apply"
                      />
                    </button>
                    <button
                      type="button"
                      title={"Clear Filters"}
                      onClick={() => handleClearForm(resetForm)}
                      disabled={!dirty}
                      className="btn btn-sm btn-outline-secondary ms-2"
                    >
                      <FormattedMessage
                        id="BUTTONS.CLEAR"
                        defaultMessage="Clear"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default InputSalesFilterForm;
