import { Formik, Field } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { IInputDistribution } from "../interfaces/IInputDistribution";
import React, { useEffect, useState } from "react";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import { get, put } from "../../../../utils/ajax";
import { useDispatch } from "react-redux";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { IHub, IInput, IUnit } from "../../settings/interfaces/IHub";
import { authService } from "../../../../data/oidc/AuthService";
import {
  XAutoComplete,
  IOption,
} from "../../../components/shared/forms/XAutoComplete";
import { InputAdornment } from "@mui/material";
import { IdName } from "../../settings/inputs/interfaces/IInput";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth";

type Props = {
  initialValues: IInputDistribution | null;
};

const EditInputSaleForm = ({ initialValues }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  const [modalKey, setModalKey] = useState(Date.now());
  const [inputs, setInputs] = useState<IInput[]>([]);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [maxQuantity, setMaxQuantity] = useState<number | null>(null);
  const [quantityFieldDisabled, setQuantityFieldDisabled] = useState(false);
  const options: IOption[] = units
    .map((u) => ({
      id: u.id ?? "",
      label: u.displayName ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const [inputSale, setInputSale] = useState<IInputDistribution>({
    ...initialValues,
    id: initialValues?.id,
    inputName: initialValues?.inputName,
    inputId: initialValues?.inputId,
    unitName: [] as IdName[],
  });

  const inputsList: IOption[] = inputs
    .map((i) => ({
      id: i.id ?? "",
      label: i.name ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    handleFetchData();

    if (initialValues) {
      setInputSale({
        ...initialValues,
      });
    }

  }, [initialValues]);

  const handleFetchData = () => {
    if (remoteRoutes.onboardingService) {

      let params: any = {
        pageSize: 100,
      };

      let url = "";

      const hubId = currentUser?.hubId;

      url = buildUrl(remoteRoutes.onboardingService, "/units", params);
      get(
        url,
        (response: IApiResponse) => {
          const { data } = response;
          setUnits(data);
        },
        async (error) => {
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
        },
      );

      if (hubId) {
        params = { hubId };

        url = buildUrl(remoteRoutes.onboardingService, "/inputs", params);
        get(
          url,
          (response: IApiResponse) => {
            const { data } = response;
            setInputs(data);
          },
          async (error) => { },
          () => {

            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        );
      }

      if (initialValues?.id) {
        const url = buildUrl(
          remoteRoutes.inputsService,
          `/input/sales/${initialValues.id}/editable/max`,
        );
        get(
          url,
          (response) => {
            setMaxQuantity(response);
          },
          async (error) => {
          },
          () => {
            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        );
      }
    }
  };

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

    const typeOfSale =
      values.amountPaid === 0
        ? 2
        : values.amountPaid === values.totalPrice
          ? 1
          : values.amountPaid < values.totalPrice
            ? 3
            : 1;

    const data: IInputDistribution = {
      ...values,
      inputName: values.inputName.label,
      inputId: values.inputName.id,
      unitName: values.unitName.label,
      unitId: values.unitName.id,
      typeOfSale: typeOfSale,
    };

    const url = buildUrl(
      remoteRoutes.inputsService,
      `/input/sales/${values.id}`,
    );

    put(
      url,
      data,
      (response) => {
        CloseModal("edit-input-sale", () => {
          setSubmitting(false);
          resetForm();
        });

        dispatch({
          type: INPUTS_DISTRIBUTION_CONSTANTS.UPDATE_DATA,
          payload: data,
        });

        setModalKey(Date.now());
        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => { },
    );
  };

  return (
    <Formik
      initialValues={{
        ...inputSale,
        date: dayjs(inputSale.date),

        inputName: inputSale.inputName
          ? {
            id: inputSale.inputId,
            label: inputSale.inputName,
          }
          : null,
        unitName: inputSale.unitName
          ? {
            id: inputSale.unitId,
            label: inputSale.unitName,
          }
          : null,
        quantity: inputSale.quantity || 0,
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (maxQuantity !== null && values.quantity > maxQuantity) {
          errors.quantity = `${intl.formatMessage({ id: "ERROR.QUANTITY.MAXIMUM", defaultMessage: `Edited amount cannot go above ${maxQuantity}. This avoids negative stock` })}`;
        }
        return errors;
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
        return (
          <ModalWrapper
            id="edit-input-sale"
            title={`${intl.formatMessage({ id: "FORM.TITLE.EDITINPUTSALE", defaultMessage: "Edit Input Sale" })}`}
            size={"lg"}
            key={modalKey}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-lg-12">
                <Field
                  name="farmerName"
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.FARMERNAME", defaultMessage: "Farmer Name" })}`}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={inputsList || []}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  values={values.inputName}
                  label={`${intl.formatMessage({ id: "FORM.INPUTNAME", defaultMessage: "Input Name" })}`}
                  name="inputName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={options || []}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  values={values.unitName}
                  label={`${intl.formatMessage({ id: "FORM.INPUTSUNIT", defaultMessage: "Inputs Unit" })}`}
                  name="unitName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled={quantityFieldDisabled || isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.QUANTITY", defaultMessage: "Quantity" })}`}
                  name="quantity"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const quantity = Number(e.target.value);
                    setFieldValue("quantity", quantity);
                    const updatedTotalPrice =
                      quantity * Number(values.unitPrice);
                    setFieldValue("totalPrice", updatedTotalPrice);
                    setFieldValue(
                      "balanceDue",
                      updatedTotalPrice - Number(values.amountPaid),
                    );
                  }}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.UNITPRICE", defaultMessage: "Unit Price" })}`}
                  name="unitPrice"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">UGX</InputAdornment>
                    ),
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const unitPrice = Number(e.target.value);
                    setFieldValue("unitPrice", unitPrice);
                    const updatedTotalPrice =
                      unitPrice * Number(values.quantity);
                    setFieldValue("totalPrice", updatedTotalPrice);
                    setFieldValue(
                      "balanceDue",
                      updatedTotalPrice - Number(values.amountPaid),
                    );
                  }}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.TOTALAMOUNTVALUE", defaultMessage: "Total Amount Value" })}`}
                  name="totalPrice"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">UGX</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.TOTALAMOUNTPAID", defaultMessage: "Total Amount Paid" })}`}
                  name="amountPaid"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">UGX</InputAdornment>
                    ),
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const amountPaid = Number(e.target.value);
                    setFieldValue("amountPaid", amountPaid);
                    setFieldValue(
                      "balanceDue",
                      Number(values.totalPrice) - amountPaid,
                    );
                  }}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.BALANCE", defaultMessage: "Balance" })}`}
                  name="balanceDue"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">UGX</InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default EditInputSaleForm;
