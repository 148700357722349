import { Field, Formik, useField } from "formik";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { del, post } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { IHub } from "../../../settings/interfaces/IHub";
import { removeTrailingS } from "../../../../hooks/mauNormalizer";
import { useNavigate } from "react-router-dom";

interface Props {
    item: IHub
    mau?: string
}

const DeleteCollectionCentreForm = ({ item, mau }: Props) => {

    const [modalKey, setModalKey] = useState(Date.now());
    const navigate = useNavigate()

    const handleSubmit = (values: any, resetForm: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, `/hubs/${item.id}`)

        del(url, (response) => {

            CloseModal("delete-hub")

            toast.success(toastMessages.deleteSuccessful.success, overrideToastDefaults)
            setModalKey(Date.now());
            navigate('/hubs')
        }, (error) => {
            toast.error(toastMessages.deleteSuccessful.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const minimalAggregationUnit = removeTrailingS(item.minimumAggregationUnit === "" ? mau : item.minimumAggregationUnit);

    useEffect(() => {
    }, []);


    return (
        <Formik
            initialValues={item}
            enableReinitialize={true}
            onSubmit={handleSubmit}>

            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id="delete-hub"
                    title={`Delete ${minimalAggregationUnit}`}
                    size="md"
                    key={modalKey}
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <p>{`Are you sure you want to delete this ${minimalAggregationUnit}? This action cannot be undone.`}</p>
                </ModalWrapper>
            }}

        </Formik>
    );
}

export default DeleteCollectionCentreForm;