import {
  faLayerGroup,
  faPeopleGroup,
  faShoppingCart,
  faTruck,
  faTableColumns,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { IMenuItem } from "../../../utils/MainMenu";
import { IntlShape } from "react-intl";

interface MainMenuProps {
  activePage: string;
}

export const InventoryInnerMenu = (
  intl: IntlShape,
  activePage: string,
): IMenuItem[] => {
  const menuItems = [
    {
      id: "INVENTORY.INNERMENU.OVERVIEW",
      label: intl.formatMessage({
        id: "INVENTORY.INNERMENU.OVERVIEW",
        defaultMessage: "Overview",
      }),
      path: "/inventory/dashboard",
      isActive: activePage.toLowerCase().split("/").includes("dashboard"),
      icon: faTableColumns,
    },
    {
      id: "INVENTORY.INNERMENU.PURCHASES",
      label: intl.formatMessage({
        id: "INVENTORY.INNERMENU.PURCHASES",
        defaultMessage: "Purchases",
      }),
      path: "/inventory/stock",
      isActive:
        activePage.toLowerCase() === "/inventory" ||
        activePage.toLowerCase().split("/").includes("stock"),
      icon: faShoppingCart,
    },
    {
      id: "INVENTORY.INNERMENU.BATCHES",
      label: intl.formatMessage({
        id: "INVENTORY.INNERMENU.BATCHES",
        defaultMessage: "Batches",
      }),
      path: "/inventory/batches",
      isActive: activePage.toLowerCase().split("/").includes("batches"),
      icon: faLayerGroup,
    },
    {
      id: "INVENTORY.INNERMENU.MOVEMENTS",
      label: intl.formatMessage({
        id: "INVENTORY.INNERMENU.MOVEMENTS",
        defaultMessage: "Movements",
      }),
      path: "/inventory/movements",
      isActive: activePage.toLowerCase().split("/").includes("movements"),
      icon: faTruck,
    },
    // {
    //   id: "INVENTORY.INNERMENU.SALES",
    //   label: intl.formatMessage({
    //     id: "INVENTORY.INNERMENU.SALES",
    //     defaultMessage: "Sales",
    //   }),
    //   path: "/inventory/outgoing",
    //   isActive: activePage.toLowerCase().split("/").includes("outgoing"),
    //   icon: faArrowRight,
    // },
    {
      id: "INVENTORY.INNERMENU.BUYERS",
      label: intl.formatMessage({
        id: "INVENTORY.INNERMENU.BUYERS",
        defaultMessage: "Buyers",
      }),
      path: "/inventory/buyers",
      isActive: activePage.toLowerCase().split("/").includes("buyers"),
      icon: faPeopleGroup,
    },
    {
      id: "INVENTORY.INNERMENU.SALES",
      label: intl.formatMessage({
        id: "INVENTORY.INNERMENU.SALES",
        defaultMessage: "Sales",
      }),
      path: "/inventory/sales",
      isActive: activePage.toLowerCase().split("/").includes("sales"),
      icon: faArrowRight,
    },
  ];

  // return process.env.REACT_APP_HIDE_OVERVIEW === "true"
  //   ? menuItems.filter((item) => item.label !== "Overview")
  //   : menuItems;
  return menuItems;
};
