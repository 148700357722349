import React, {useState} from 'react'
import {IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {useDispatch} from "react-redux";
import {IUser} from "../interfaces/IUser";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {del} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {Formik} from "formik";

type Props = {
    id: string | undefined | null
    pagination: IPaginationMetadata
    close: () => void;
}

const DeleteUserForm = ({id, pagination, close}: Props) => {

    const dispatch = useDispatch()

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues: IUser = {
        additionalClaims: [],
        confirmPassword: "",
        email: "",
        firstName: "",
        id: "",
        lastName: "",
        password: "",
        phoneNumber: "",
        userName: ""
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.authServer, `/api/v1/users/${id}`)

        del(url, (response) => {

            dispatch({
                type: SETTINGS_CONSTANTS.DELETE_USER,
                payload: {
                    id, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems - 1,
                    }
                }
            })

            CloseModal("delete-user", () => {
                setSubmitting(false);
                resetForm();
            });

            close();

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="delete-user"
                    title="Delete User"
                    size="md"
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    key={modalKey}
                    handleSubmit={handleSubmit}>
                    <p>Are you sure you want to delete this user? This action cannot be undone.</p>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default DeleteUserForm