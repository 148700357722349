import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { IProcessRun } from "../interfaces/IProcessRun";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get, post, put } from "../../../../utils/ajax";
import { useDispatch } from "react-redux";
import moment from "moment";
import { PROCESS_RUN_CONSTANTS } from "../../../../data/redux/processing/processRunsReducer";
import { ProcessingSchema } from "../schemas/ProcessingSchema";
import { useIntl } from "react-intl";
import { IBatch } from "../../inventory/interfaces/IBatch";
import { INVENTORY_BATCHES_CONSTANTS } from "../../../../data/redux/inventory/batchesReducer";
import { BatchStatus } from "../../inventory/interfaces/BatchStatus";

interface Props {
    processRun: IProcessRun
    batch?: IBatch | null
}

const EndProcessForm = ({ processRun, batch }: Props) => {

    const intl = useIntl();

    const [modalKey, setModalKey] = useState(Date.now());
    const [varieties, setVarieties] = useState<IOption[]>([]);
    const [batchDetails, setBatchDetails] = useState<IBatch | null>(null); // State for batch details
    const [updatingStatus, setUpdatingStatus] = useState(false);

    const dispatch = useDispatch();

    const initialValues = {
        id: processRun.id,
        productId: processRun.productId,
        outputQuantity: null,
        outputVarietyName: null,
        outputUnit: null
    }

    useEffect(() => {
        handelFetchVarieties();
        fetchBatchDetails();
    }, [processRun, dispatch]);

    const handelFetchVarieties = () => {
        const url = buildUrl(remoteRoutes.onboardingService, `/products/${initialValues.productId}`);

        get(url, (response) => {

            const data = response;

            if (!data || !data.varieties) {
                console.error("No varieties found in product data");
                setVarieties([]);
                return;
            }

            const varietyList = data.varieties
                .filter((variety: { id: string }) =>
                    processRun
                        ? variety.id !== processRun.inputVarietyId
                        : true
                )
                .map((variety: { id: any; name: any }) => ({
                    id: variety.id ?? '',
                    label: variety.name ?? 'Unnamed Variety',
                }))
                .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

            setVarieties(varietyList);

        }, (error) => {
            console.error("Error fetching data:", error);
        });
    };

    const fetchBatchDetails = () => {
        if (!processRun.batchId) {
            console.error("Batch ID is missing");
            return;
        }
        const url = buildUrl(remoteRoutes.inventoryService, `/batches/${processRun.batchId}`);
        get(
            url,
            (response) => {
                setBatchDetails(response);
            },
            (error) => {
                console.error("Error fetching batch details:", error);
            }
        );
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.processingService, `/process-runs/end`)
        const data = {
            processRunId: values.id,
            endTime: moment().toISOString().replace('Z', '+00:00'),
            outputQuantity: values.outputQuantity,
            outputUnit: values.outputUnit.id,
            comment: values.comment,
            outputVarietyName: values.outputVarietyName.label,
            outputVarietyId: values.outputVarietyName.id,
        }

        post(url, data, (response) => {

            CloseModal("end-process", () => {
                setSubmitting(false);
                resetForm();
            });

            dispatch({
                type: PROCESS_RUN_CONSTANTS.UPDATE_PROCESS_RUN,
                payload: data
            })

            handleUpdateStatus(BatchStatus.Processed);

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());

            window.location.reload()
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {
        })
    }

    const handleUpdateStatus = (status: any) => {
        setUpdatingStatus(true);

        if (!batchDetails?.id) {
            console.error("Batch ID is missing. Update cannot be performed.");
            setUpdatingStatus(false);
            return;
        }

        const updatedBatch = {
            ...batchDetails,
            date: new Date(batchDetails?.date).toISOString(),
            currentStatus: status,
        };

        const url = remoteRoutes.inventoryService + "/batches/" + batchDetails?.id;
        put(
            url,
            updatedBatch,
            (response) => {
                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH,
                    payload: updatedBatch,
                });

                setUpdatingStatus(false);
            },
            (error) => {
                setUpdatingStatus(false);
            },
            () => { }
        );
    };
    const units = [
        { id: "kg", label: "kg" },
        { id: "mt", label: "mt" }
    ]

    const validationSchema = ProcessingSchema(intl)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validate={(values) => {
                const errors: any = {};
                if (values?.outputQuantity != null && values.outputQuantity > processRun.inputQuantity) {
                    errors.outputQuantity = `Output quantity cannot exceed ${processRun.inputQuantity}`;
                }
                return errors;
            }}
        >
            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id={'end-process'}
                    title={'End Process'}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                    submitBtnLabel="End Process"
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={varieties}
                                label="Output Variety"
                                name="outputVarietyName"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Output Quantity"
                                name="outputQuantity"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                label="Output Units"
                                name="outputUnit"
                                options={units}
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Comments"
                                name="comment"
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default EndProcessForm