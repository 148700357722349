import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { IState } from "../../../../../data/types";
import { useAuth } from "../../../auth";
import { IApiResponse } from "../../../../interfaces/IApiResponse";
import { remoteRoutes } from "../../../../../data/constants";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { PRODUCTS_CONSTANTS } from "../../../../../data/redux/settings/productsReducer";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { IProcess, IProcessProduct } from "../../interfaces/IHub";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { get, put } from "../../../../../utils/ajax";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { ProcessSchema } from "../interfaces/IProcessSchema";
import { useIntl } from "react-intl";
import { IOption } from "../../../../components/shared/forms/XAutoComplete";
import { IdName } from "../../inputs/interfaces/IInput";

interface Props {
    initialValues: IProcess;
}

const EditProcessForm = ({ initialValues }: Props) => {
    const intl = useIntl();
    const [filteredProducts, setFilteredProducts] = useState<IProcessProduct[]>([]);

    const [selectedProducts, setSelectedProducts] = useState([]);

    const dispatch = useDispatch();
    const auth = useAuth();
    const currentUser = auth.currentUser;
    const userHub = auth.userHub;

    const defaultProducts = initialValues.products?.map((p) => ({
        productId: p.productId,
        productName: p.productName,
    }));

    const [modalKey, setModalKey] = useState(Date.now());

    const productList = userHub?.products?.map((p: { id: any; name: any; }) => ({
        productId: p.id ?? '',
        productName: p.name,
    })) || [];

    useEffect(() => {
        setFilteredProducts(productList);
    }, [productList]);

    useEffect(() => {
    }, []);

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.processingService, `/processes/${values.id}`)
        const data: IProcess = {
            id: values.id,
            hubId: values.hubId ?? currentUser?.hubId,
            products: values.products,
            name: values.name,
            costCentres: values.costCentres,
            description: values.description
        }

        put(url, data, (response) => {

            CloseModal("edit-process", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.UPDATE_PROCESS,
                payload: data
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
            setSelectedProducts([])
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const validationSchema = ProcessSchema(intl)

    return (
        <Formik
            initialValues={{
                ...initialValues,
                products: defaultProducts
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting, touched, errors, values, setFieldValue }) => {
                return <ModalWrapper
                    id="edit-process"
                    title={`${intl.formatMessage({ id: "FORM.TITLE.EDITPROCESS", defaultMessage: "Edit Process" })}`}
                    submitting={isSubmitting}
                    size="md"
                    key={modalKey}
                    handleSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>

                        <div className="col-lg-12 mb-4">
                            <Field name="products">
                                {() => (
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option: any) => option.productName}
                                        options={filteredProducts}
                                        onChange={(event, value) => {
                                            setFieldValue('products', value.map(prod => ({
                                                // processId: initialValues.id, // Or set this to a relevant value if `processId` is different
                                                productId: prod.productId,
                                                productName: prod.productName
                                            })));
                                        }}

                                        value={values.products}

                                        renderOption={(props, option) => (
                                            <li {...props} key={option.productId}>
                                                {option.productName}
                                            </li>
                                        )}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`${intl.formatMessage({ id: "FORM.SELECTPRODUCT", defaultMessage: "Select Products" })}`}
                                                variant="standard"
                                                error={touched.products && Boolean(errors.products)}
                                            />
                                        )} />
                                )}
                            </Field>
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.DESCRIPTION", defaultMessage: "Description" })}`}
                                name="description"
                            />
                        </div>

                        {/* <div className="col-lg-12 mb-4">
                            <Field name="costCentres">
                                {() => (
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option: ICostCentre) => option.name}
                                        options={costCenters.data}
                                        onChange={(event, value) => {
                                            setFieldValue('costCentres', value.map(prod => ({
                                                id: prod.id,
                                                name: prod.name,
                                                description: prod.description
                                            })));
                                        }}
                                        value={values.costCentres}

                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                          label={`${intl.formatMessage({ id: "FORM.SELECTCOSTCENTRE", defaultMessage: "Select Cost Centre" })}`}
                                                variant="standard"
                                                error={touched.costCentres && Boolean(errors.costCentres)}
                                            // helperText={touched.costCentres && errors.costCentres ? errors.costCentres : ''}
                                            />
                                        )} />
                                )}
                            </Field>
                        </div> */}

                    </div>

                </ModalWrapper>

            }}
        </Formik>
    );
}

export default EditProcessForm;
