import React, { useState } from 'react'
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { Formik } from "formik";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import { del } from "../../../../utils/ajax";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import { INVENTORY_PURCHASES_CONSTANTS } from "../../../../data/redux/inventory/purchasesReducer";
import { useDispatch } from "react-redux";
import { IPaginationMetadata } from "../../../interfaces/IApiResponse";

interface Props {
    ids: any[]
    pagination: IPaginationMetadata
    onDeleteCompleted: () => void;
}

const DeletePurchasesFrom = ({ ids, pagination, onDeleteCompleted }: Props) => {
    const dispatch = useDispatch()
    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues: any[] = []

    const handleSubmit = ({ resetForm, setSubmitting }: any) => {
        const params = { ids };

        const url = buildUrl(remoteRoutes.inventoryService, `/purchases/bulk/`, params);

        del(
            url,
            (response) => {
                dispatch({
                    type: INVENTORY_PURCHASES_CONSTANTS.DELETE_PURCHASES_BULK,
                    payload: {
                        ids,
                        pagination: {
                            ...pagination,
                            totalItems: pagination.totalItems - ids.length,
                        },
                    },
                });

                if (setSubmitting) {
                    setSubmitting(false);
                }
                if (resetForm) {
                    resetForm();
                }
                CloseModal("delete-purchases");

                onDeleteCompleted();

                toast.success(toastMessages.deleteSuccessful.success, overrideToastDefaults);
                setModalKey(Date.now());
            },
            (error) => {
                setSubmitting(false);
                toast.error(toastMessages.deleteSuccessful.fail, overrideToastDefaults);
            }
        );
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, isSubmitting }) => {
                    return <ModalWrapper
                        id="delete-purchases"
                        title="Delete Purchases"
                        size="md"
                        isDeleteAction={true}
                        submitting={isSubmitting}
                        key={modalKey}
                        handleSubmit={handleSubmit}>
                        <p>Are you sure you want to delete the purchase(s)? This action cannot be undone.</p>
                    </ModalWrapper>
                }}
            </Formik>
        </>
    )
}

export default DeletePurchasesFrom