import { Outlet, Route, Routes } from "react-router-dom";
import { InputPurchases } from "./InputPurchases";
import { InputSales } from "./InputSales";
import { InputMovements } from "./InputMovements";
import InputStockLedger from "./InputStockLedger";
import { InputDetails } from "./InputDetails";

const InputDistributionPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <InputPurchases />
            </>
          }
        />
        <Route path="purchases" element={<InputPurchases />} />
        <Route path="stock" element={<InputStockLedger />} />
        <Route path="sales" element={<InputSales />} />
        <Route path="movements" element={<InputMovements />} />
        <Route
          path=":stock/:collectionCentreId/"
          element={
            <>
              <InputDetails />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default InputDistributionPage;
