import dayjs from "dayjs";
import * as Yup from "yup"

export const InputMovementSchema = Yup.object({
    movementNumber: Yup.string().required("Movement number name is required"),
    quantity: Yup.number().required("Quantity is required"),
    destinationCollectionCentreName: Yup.object().required("Destination is required"),
})

export const ReceiveMovementSchema = Yup.object({
    confirmedQuantity: Yup.number().required("Required"),
    confirmationDate: Yup.mixed()
        .required("Required")
        .test("is-valid-dayjs", "Required", (value) => {
            return dayjs.isDayjs(value) && value.isValid();
        }),

})