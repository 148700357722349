import { Formik } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { IInputMovement } from "../interfaces/IInputMovement";
import { useState } from "react";
import toast from "react-hot-toast";
import { del } from "../../../../utils/ajax";
import { remoteRoutes } from "../../../../data/constants";
import {
  toastMessages,
  overrideToastDefaults,
} from "../../../../data/toastDefaults";
import { buildUrl } from "../../../../utils/queryBuilder";
import { useIntl } from "react-intl";
import { IPaginationMetadata } from "../../../interfaces/IApiResponse";
import { INPUT_MOVEMENT_CONSTANTS } from "../../../../data/redux/inputs/inputsMovementsReducer";
import { useDispatch } from "react-redux";

interface Props {
  item: IInputMovement | null;
  pagination: IPaginationMetadata
  close: () => void;
}

const DeleteInputMovementForm = ({ item, pagination, close }: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch()
  const [modalKey, setModalKey] = useState(Date.now());

  const id = item?.id

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const url = buildUrl(
      remoteRoutes.inputsService,
      `/input/movements/${item?.id}`,
    );

    del(
      url,
      (response) => {

        dispatch({
          type: INPUT_MOVEMENT_CONSTANTS.DELETE_MOVEMENT,
          payload: {
            id, pagination: {
              ...pagination,
              totalItems: pagination.totalItems - 1,
            }
          }
        })
        CloseModal("delete-input-movement", () => {
          setSubmitting(false);
          resetForm();
        });

        close();

        setModalKey(Date.now());
        toast.success(toastMessages.deleteSuccessful.success, overrideToastDefaults);
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.deleteSuccessful.fail, overrideToastDefaults);
      },
      () => { },
    );
  };

  return (
    <Formik
      initialValues={{ item }}
      enableReinitialize={true}
      onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id="delete-input-movement"
            title={`${intl.formatMessage({ id: "FORM.TITLE.DELETEINPUTMOVEMENT", defaultMessage: "Delete Input Movement" })}`}
            size="md"
            key={modalKey}
            isDeleteAction={true}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>{`${intl.formatMessage({ id: "DELETE.INPUTMOVEMENT", defaultMessage: "Are you sure you want to delete this Input Movement? This action cannot be undone." })}`}</p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteInputMovementForm;
