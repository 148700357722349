import {ISystemProduct} from "../../../app/modules/settings/products/interfaces/ISystemProduct";
import {IProduct} from "../../../app/modules/settings/interfaces/IHub";
import {IPaginationMetadata} from "../../../app/interfaces/IApiResponse";

export const PRODUCTS_CONSTANTS = {
    ATTACH_PRODUCTS: "ATTACH_PRODUCTS",
    FETCH_DATA: "FETCH_DATA",
    FILTER_DATA: "FILTER_DATA",
    GET_DETAILS: "GET_DETAILS",
    LOADING_DATA: "LOADING_DATA",
    PAGINATION: "PAGINATION",
    POST_DATA: "POST_DATA",
    RESET_FILTER: "RESET_FILTER",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    STOP_FETCH: "STOP_FETCH",
    UPDATE_DATA: "UPDATE_DATA",
}

export interface IProductState {
    loading: boolean
    data: IProduct[],
    pagination: IPaginationMetadata,
    filter: {
        show: boolean,
        data: IProduct[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IProductState = {
    loading: false,
    data: [],
    pagination: {
        currentPage: 1,
        pageSize: 5,
        hasPreviousPage: false,
        hasNextPage: false,
        totalItems: 0,
        totalPages: 0
    },
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function productsReducer(state = initialState, action: any) {
    switch (action.type) {

        case PRODUCTS_CONSTANTS.FILTER_DATA:
            return {...state, ...action.payload, show: !state.filter.show}

        case PRODUCTS_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return {...initialState, reset: true}

        case PRODUCTS_CONSTANTS.SET_SORT_PARAMS:
            return {...state, ...action.payload}

        case PRODUCTS_CONSTANTS.PAGINATION:
            return {...state, ...action.payload}

        case PRODUCTS_CONSTANTS.FETCH_DATA: {
            return {...state, loading: false, data: action.payload, reset: false}
        }

        // case PRODUCTS_CONSTANTS.ATTACH_PRODUCTS: {
        //     const newProducts: IProduct[] = action.payload.data || []
        //     const existingProducts = state.data
        //     const updatedProducts = newProducts.map((newProduct) => {
        //         const existingProduct = existingProducts.find((pp) => pp.id === newProduct.id)
        //         return existingProduct ? {...existingProduct, ...newProduct} : newProduct
        //     })
        //
        //     const pagination: IPaginationMetadata = action.payload.pagination;
        //
        //     return {
        //         ...state,
        //         data: updatedProducts,
        //         pagination
        //     }
        // }

        case PRODUCTS_CONSTANTS.ATTACH_PRODUCTS: {
            const newProducts = Array.isArray(action.payload.data) ? action.payload.data : [];
            const existingProducts = state.data;

            const updatedProducts = newProducts.map((newProduct: { id: string; }) => {
                const existingProduct = existingProducts.find((pp) => pp.id === newProduct.id);
                return existingProduct ? {...existingProduct, ...newProduct} : newProduct;
            });

            const pagination: IPaginationMetadata = action.payload.pagination;

            return {
                ...state,
                data: updatedProducts,
                pagination,
            };
        }


        case PRODUCTS_CONSTANTS.POST_DATA: {
            const product: IProduct = action.payload
            return {...state, data: [product, ...state.data]}
        }

        case PRODUCTS_CONSTANTS.UPDATE_DATA: {
            return {
                ...state,
                data: state.data.map(product => product.id === action.payload.id ? action.payload : product)
            }
        }

        case PRODUCTS_CONSTANTS.LOADING_DATA: {
            return {...state, loading: action.payload}
        }

        case PRODUCTS_CONSTANTS.GET_DETAILS: {
            return {...state, requestDetails: action.payload}
        }

        case PRODUCTS_CONSTANTS.STOP_FETCH: {
            return {...state, loading: false}
        }

        default: {
            return state
        }
    }
}