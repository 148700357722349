import { Formik, Field } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { authService } from "../../../../data/oidc/AuthService";
import {
  IHub,
  ICollectionCentre,
  IInput,
} from "../../settings/interfaces/IHub";
import {
  IOption,
  XAutoComplete,
} from "../../../components/shared/forms/XAutoComplete";
import toast from "react-hot-toast";
import { get, post } from "../../../../utils/ajax";
import { remoteRoutes } from "../../../../data/constants";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import {
  toastMessages,
  overrideToastDefaults,
} from "../../../../data/toastDefaults";
import { buildUrl } from "../../../../utils/queryBuilder";
import { IInputMovement } from "../interfaces/IInputMovement";
import { useAuth } from "../../auth";
import { InputMovementSchema } from "../schemas/InputMovementSchema";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { useIntl } from "react-intl";

type Props = {
  selectedInput: any;
};

const MoveInputsForm = ({ selectedInput }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  const [modalKey, setModalKey] = useState(Date.now());

  const [inputs, setInputs] = useState<any[] | undefined>(undefined);
  const [locations, setLocations] = useState<ICollectionCentre[]>([]);

  const collectionCentres: IOption[] = locations
    ?.filter((c) => c.id !== selectedInput?.collectionCentreId)
    .map((c) => ({
      id: c.id ?? "",
      label: c.name ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const initialValues = {
    date: selectedInput?.date
      ? new Date(selectedInput.date as string).toISOString()
      : new Date().toISOString(),
    movementNumber: "",
    inputName: selectedInput?.name,
    inputId: selectedInput?.id,
    quantity: "",
    sourceCollectionCentreName: selectedInput?.collectionCentreName,
    sourceCollectionCentreId: selectedInput?.collectionCentreId,
    destinationCollectionCentreName: null,
    unitName: selectedInput?.unit,
    unitId: selectedInput?.unitId,
  };

  useEffect(() => {
    handleFetchData();
    const hub: IHub | undefined = authService.getHub();

    if (hub) {
      const { inputs } = hub;

      const sortedInputs = inputs
        ?.map((input: IInput) => ({
          id: input.id,
          label: input.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setInputs(sortedInputs);
    }
  }, []);

  const handleFetchData = () => {
    if (remoteRoutes.onboardingService) {
      const hubId = currentUser?.hubId;
      let url = "";
      if (hubId) {
        const params = { hubId };

        url = buildUrl(
          remoteRoutes.onboardingService,
          "/collection/centres",
          params,
        );
        get(
          url,
          (response: IApiResponse) => {
            const { data } = response;
            setLocations(data);
          },
          async (error) => { },
          () => {
            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        );
      }
    }
  };

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const url = buildUrl(remoteRoutes.inputsService, `/input/movements`);

    const data: IInputMovement = {
      ...values,
      hubId: currentUser?.hubId,
      date: values.date.toISOString(),
      inputName: values.inputName.label,
      inputId: values.inputName.id ?? selectedInput?.inputId,
      destinationCollectionCentreId: values.destinationCollectionCentreName.id,
      destinationCollectionCentreName:
        values.destinationCollectionCentreName.label,
      sourceCollectionCentreId:
        values.sourceCollectionCentreName.id ??
        selectedInput?.collectionCentreId,
      sourceCollectionCentreName:
        values.sourceCollectionCentreName.label ??
        selectedInput?.collectionCentreName,
      typeOfMovement: 1,
      unitName: selectedInput?.unit,
      unitId: selectedInput?.unitId,
    };

    post(
      url,
      data,
      (response) => {
        CloseModal("move-inputs", () => {
          setSubmitting(false);
          resetForm();
        });
        dispatch({
          type: INPUTS_DISTRIBUTION_CONSTANTS.POST_DATA,
          payload: data,
        });

        const movedQuantity = parseFloat(values.quantity);
        const updatedQuantity = selectedInput.quantity - movedQuantity;

        selectedInput.quantity = updatedQuantity;
        
        setModalKey(Date.now());
        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => { },
    );
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        date: dayjs(initialValues?.date),
        inputName: initialValues.inputName
          ? {
            id: initialValues.inputId,
            label: initialValues.inputName,
          }
          : null,
        unitName: initialValues.unitName
          ? {
            id: initialValues.unitId,
            label: initialValues.unitName,
          }
          : null,
        sourceCollectionCentreName: initialValues.sourceCollectionCentreName
          ? {
            id: initialValues.sourceCollectionCentreId,
            label: initialValues.sourceCollectionCentreName,
          }
          : null,
      }}
      validate={(values) => {
        const errors: any = {};
        if (values.quantity > selectedInput.quantity) {
          errors.quantity = `Quantity cannot exceed ${selectedInput.quantity}`;
        }
        return errors;
      }}
      validationSchema={InputMovementSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <ModalWrapper
            id="move-inputs"
            title={`${intl.formatMessage({ id: "FORM.TITLE.MOVE", defaultMessage: "Move" })} ${initialValues?.inputName}`}
            size={"lg"}
            key={modalKey}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XDatePicker}
                  label={`${intl.formatMessage({ id: "FORM.DATE", defaultMessage: "Date" })}`}
                  name="date"
                  maxDate={dayjs(new Date())}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.MOVEMENTNUMBER", defaultMessage: "Movement Number" })}`}
                  name="movementNumber"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled
                  component={XAutoComplete}
                  options={inputs || []}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  values={values.inputName}
                  label={`${intl.formatMessage({ id: "FORM.INPUTNAME", defaultMessage: "Input Name" })}`}
                  name="inputName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.QUANTITY", defaultMessage: "Quantity" })}`}
                  name="quantity"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled
                  as={XTextField}
                  value={initialValues.sourceCollectionCentreName}
                  label={`${intl.formatMessage({ id: "FORM.ORIGIN.SOURCE", defaultMessage: "Origin/ Source" })}`}
                  name="sourceCollectionCentreName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={collectionCentres || []}
                  value={values.destinationCollectionCentreName}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  label={`${intl.formatMessage({ id: "FORM.DESTINATION", defaultMessage: "Destination" })}`}
                  name="destinationCollectionCentreName"
                />
              </div>
            </div>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default MoveInputsForm;
