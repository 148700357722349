import React from "react";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";

const Dashboard = () => {
    return (
        <>
            <div className="py-3">
                <InnerMenu module={Modules.Processing} />
            </div>
        </>
    )
}

export default Dashboard