import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { useState } from "react";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { orange } from "@mui/material/colors";
import { useIntl } from "react-intl";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { IRole, IUnit } from "../../interfaces/IHub";
import { post } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { IPaginationMetadata } from "../../../../interfaces/IApiResponse";
import { useDispatch } from "react-redux";

interface DummyData {
  [section: string]: string[];
}

type Props = {
  pagination: IPaginationMetadata;
};

const CreateRoleForm = ({ pagination }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  // const [checkedSections, setCheckedSections] = useState<
  //   Record<string, boolean>
  // >(
  //   Object.keys(dummyData).reduce(
  //     (acc, section) => {
  //       acc[section] = false;
  //       return acc;
  //     },
  //     {} as Record<string, boolean>,
  //   ),
  // );
  //
  // const [checkedPermissions, setCheckedPermissions] = useState<
  //   Record<string, Record<string, boolean>>
  // >(
  //   Object.keys(dummyData).reduce(
  //     (acc, section) => {
  //       acc[section] = dummyData[section].reduce(
  //         (permAcc, permission) => {
  //           permAcc[permission] = false;
  //           return permAcc;
  //         },
  //         {} as Record<string, boolean>,
  //       );
  //       return acc;
  //     },
  //     {} as Record<string, Record<string, boolean>>,
  //   ),
  // );
  //
  // const handleSectionChange = (section: string) => {
  //   setCheckedSections((prevState) => ({
  //     ...prevState,
  //     [section]: !prevState[section],
  //   }));
  // };
  //
  // const handlePermissionChange = (section: string, permission: string) => {
  //   setCheckedPermissions((prevState) => ({
  //     ...prevState,
  //     [section]: {
  //       ...prevState[section],
  //       [permission]: !prevState[section][permission],
  //     },
  //   }));
  // };

  const initialValues = {
    name: "",
    description: "",
  };

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const url = buildUrl(remoteRoutes.onboardingService, "/roles");
    const data: IRole = {
      name: values.name,
      description: values.description,
    };

    post(
      url,
      data,
      (response) => {
        CloseModal("add-role", () => {
          setSubmitting(false);
          resetForm();
        });

        dispatch({
          type: SETTINGS_CONSTANTS.POST_ROLES,
          payload: {
            data,
            pagination: {
              ...pagination,
              totalItems: pagination.totalItems + 1,
            },
          },
        });

        toast.success(toastMessages.default.success, overrideToastDefaults);
        // setModalKey(Date.now());
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, handleSubmit, values }) => (
        <ModalWrapper
          id={"add-role"}
          title={`${intl.formatMessage({ id: "FORM.TITLE.CREATENEWROLE", defaultMessage: "Create New Role" })}`}
          submitting={isSubmitting}
          handleSubmit={handleSubmit}
          size="md"
        >
          <div className="row">
            <div
              className="col-lg-12"
              style={{ maxHeight: "60vh", overflowY: "auto" }}
            >
              <div>
                <div className="col-lg-12">
                  <Field
                    type="string"
                    label={`${intl.formatMessage({ id: "FORM.ROLENAME", defaultMessage: "Role Name" })}`}
                    name="name"
                    values={values.name}
                    disabled={isSubmitting}
                    as={XTextField}
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    type="string"
                    label={`${intl.formatMessage({ id: "FORM.DESCRIPTION", defaultMessage: "Description" })}`}
                    as={XTextField}
                    name="description"
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              {/*<div>*/}
              {/*  <b>Permissions</b>*/}
              {/*  {Object.keys(dummyData).map((section) => (*/}
              {/*    <div key={section}>*/}
              {/*      /!* Section Checkbox *!/*/}
              {/*      <FormControlLabel*/}
              {/*        control={*/}
              {/*          <Checkbox*/}
              {/*            sx={{*/}
              {/*              "&.Mui-checked": {*/}
              {/*                color: "#DF901F",*/}
              {/*              },*/}
              {/*            }}*/}
              {/*            checked={checkedSections[section]}*/}
              {/*            onChange={() => handleSectionChange(section)}*/}
              {/*          />*/}
              {/*        }*/}
              {/*        label={section}*/}
              {/*        sx={{*/}
              {/*          "& .MuiTypography-root": {*/}
              {/*            fontSize: 12,*/}
              {/*            fontWeight: "bold",*/}
              {/*          },*/}
              {/*        }}*/}
              {/*      />*/}
              {/*      /!* Sub-permissions checkboxes *!/*/}
              {/*      <Box*/}
              {/*        sx={{*/}
              {/*          display: "flex",*/}
              {/*          flexDirection: "column",*/}
              {/*          ml: 4,*/}
              {/*          flexWrap: "wrap",*/}
              {/*          maxHeight: 200,*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        {dummyData[section].map((permission) => (*/}
              {/*          <FormControlLabel*/}
              {/*            key={permission}*/}
              {/*            control={*/}
              {/*              <Checkbox*/}
              {/*                sx={{*/}
              {/*                  "&.Mui-checked": {*/}
              {/*                    color: "#DF901F",*/}
              {/*                  },*/}
              {/*                }}*/}
              {/*                checked={checkedPermissions[section][permission]}*/}
              {/*                onChange={() =>*/}
              {/*                  handlePermissionChange(section, permission)*/}
              {/*                }*/}
              {/*              />*/}
              {/*            }*/}
              {/*            label={permission}*/}
              {/*            sx={{*/}
              {/*              "& .MuiTypography-root": {*/}
              {/*                fontSize: 12,*/}
              {/*              },*/}
              {/*            }}*/}
              {/*          />*/}
              {/*        ))}*/}
              {/*      </Box>*/}
              {/*    </div>*/}
              {/*  ))}*/}
              {/*</div>*/}
            </div>
          </div>
        </ModalWrapper>
      )}
    </Formik>
  );
};

export default CreateRoleForm;
