import {
  IHeaderButtonProps,
  SettingsPageHeader,
} from "../../../components/shared/SettingsPageHeader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TableWrapper from "../../../components/shared/TableWrapper";
import { ITableColumn, XTable } from "../../../components/shared/XTable";
import { ICostCentre } from "../interfaces/IHub";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import {
  ISettingsState,
  SETTINGS_CONSTANTS,
} from "../../../../data/redux/settings/settingsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import CostCentreDetails from "./CostCentreDetails";
import CreateCostCentre from "./modals/CreateCostCentre";
import { useIntl } from "react-intl";
import debounce from "lodash.debounce";
import { authService } from "../../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../../hooks/roleChecker";

interface IProps {
  hubId?: string;
  title?: string;
}

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.COSTCENTRENAME",
    label: "Cost Center Name",
    id: "name",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.DESCRIPTION",
    label: "Description",
    id: "description",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.UNITS",
    label: "Units",
    id: "units",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];

export const HubCostCentersPage = ({ title, hubId }: IProps) => {
  const intl = useIntl();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredCostCentres, setFilteredCostCentres] = useState<ICostCentre[]>(
    [],
  );

  const initialValues: ICostCentre = {
    hubId: "",
    name: "",
    description: "",
    units: [],
  };

  const { costCenters, loading }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );
  const { data, pagination } = costCenters;

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [selectedCostCentre, setSelectedCostCentre] =
    useState<ICostCentre>(initialValues);

  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoading(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: SETTINGS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const filterCostCentres = () => {
    if (searchTerm) {
      setFilteredCostCentres(
        data?.filter((costCentre) =>
          costCentre.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredCostCentres(data);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  useEffect(() => {
    filterCostCentres();
  }, [searchTerm, data]);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination?.pageSize || 10, searchTerm);
  }, [page, dispatch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.onboardingService) {
      let params: any = { hubId, page, pageSize };

      const url = buildUrl(
        remoteRoutes.onboardingService,
        "/cost/centres",
        params,
      );
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          dispatch({
            type: SETTINGS_CONSTANTS.FETCH_COST_CENTRES,
            payload: { data, pagination: paginationMetadata },
          });

          // toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: ICostCentre) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        // Remove the id if it's already in the array
        newSelected.splice(index, 1);
      } else {
        // Add the id if it's not in the array
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: ICostCentre) => {
    setDrawerVisible(!drawerVisible);
    setSelectedCostCentre(item);
  };

  const actionButtons: IHeaderButtonProps[] = [
    {
      label: `${intl.formatMessage({ id: "BUTTONS.ADDCOSTCENTRE", defaultMessage: "Add Cost Centre" })}`,
      cssClass: "btn btn-primary btn-sm",
      dataTarget: "#new-cost-centre",
      dataToggle: "modal",
    },
  ];

  return (
    <>
      {title && <SettingsPageHeader title={title} />}
      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-auto">
            <input
              type="text"
              // onKeyUp={(event) => handleSearch(event)}
              ref={searchInputRef}
              onChange={(event) => handleSearchChange(event)}
              className="form-control"
              placeholder={intl.formatMessage({
                id: "TABLE.SEARCH",
                defaultMessage: "Type to search...",
              })}
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="btn btn-sm btn-outline-secondary ms-2"*/}
            {/*>*/}
            {/*  <FontAwesomeIcon icon={faFilter} className="text-orange" />*/}
            {/*  <span className="ms-2">*/}
            {/*    <FormattedMessage*/}
            {/*      id={"BUTTONS.FILTERS"}*/}
            {/*      defaultMessage={"Filters"}*/}
            {/*    />*/}
            {/*  </span>*/}
            {/*</button>*/}
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="btn btn-sm btn-outline-secondary ms-2 me-2"*/}
            {/*>*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={faEllipsisVertical}*/}
            {/*    className="text-orange"*/}
            {/*  />*/}
            {/*</button>*/}

            {(isHubAdmin || isGlobalAdmin || isHubManager) && (
              <>
                {showActionButtons &&
                  actionButtons.map((button, index) => {
                    return (
                      <button
                        data-bs-toggle={button.dataToggle}
                        data-bs-target={button.dataTarget}
                        className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                        key={index}
                        onClick={button.onClick}
                      >
                        {button.processing
                          ? `${intl.formatMessage({
                              id: "LOADERS.PLEASEWAIT",
                              defaultMessage: "Please wait...",
                            })}`
                          : button.label}
                      </button>
                    );
                  })}
              </>
            )}
          </div>
        </div>

        <XTable
          checked={false}
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={filteredCostCentres}
          columns={columns}
          loading={loading}
        />

        <XPagination
          dataLength={costCenters?.data?.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <CostCentreDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        costCentreId={selectedCostCentre.id}
      />

      <CreateCostCentre pagination={pagination} hubId={hubId} />
    </>
  );
};
