import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { IOption } from "../../../../components/shared/forms/XAutoComplete";
import { useIntl } from "react-intl";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import { toastMessages, overrideToastDefaults } from "../../../../../data/toastDefaults";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { get, put } from "../../../../../utils/ajax";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { useDispatch } from "react-redux";
import { IApiResponse } from "../../../../interfaces/IApiResponse";
import { Autocomplete, TextField } from "@mui/material";

interface Props {
    ids: any[]
}

const AssignDistrictForm = ({ ids }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [modalKey, setModalKey] = useState(Date.now());
    const [districts, setDistricts] = useState<IOption[]>([]);

    const districtsList = districts.map(d => ({
        id: d.id,
        name: d.label
    }))

    const initialValues = {
        district: null,
    }

    useEffect(() => {
        handleFetchData()
    }, [dispatch]);

    const handleFetchData = () => {
        if (remoteRoutes.geoLookupService) {
            const metadataUrl = buildUrl(remoteRoutes.geoLookupService, '/districts?page=1&pageSize=1')

            get(metadataUrl, (metadataResponse: IApiResponse) => {
                const { paginationMetadata } = metadataResponse;

                if (!paginationMetadata) {
                    console.error("Pagination metadata not found.");
                    return;
                }

                const totalItems = paginationMetadata.totalItems;
                const url = buildUrl(remoteRoutes.geoLookupService, `/districts?page=1&pageSize=${totalItems}`)

                get(
                    url, (response: IApiResponse) => {
                        const { data } = response;

                        const districtList = data?.map(d => ({
                            id: d.id ?? '',
                            label: d.name,
                        })).sort((a, b) => a.label.localeCompare(b.label));

                        setDistricts(districtList)
                    }
                )

            })
        }

    }

    const handleSubmit = async (values: any, { resetForm, setSubmitting }: any) => {
        let successCount = 0;

        for (const id of ids) {
            const url = buildUrl(remoteRoutes.authServer, '/api/v1/users');
            const data = {
                userId: id,
                claims: [
                    { key: "district_name", value: values.district.name },
                    { key: "district_id", value: values.district.id }
                ],
            };

            await new Promise<void>((resolve) => {
                put(
                    url,
                    data,
                    (response) => {
                        successCount += 1;

                        dispatch({
                            type: SETTINGS_CONSTANTS.UPDATE_AGENT_DISTRICT,
                            payload: {
                                userId: id,
                                claims: data.claims,
                            },
                        });

                        toast.success(toastMessages.default.success, overrideToastDefaults);
                        resolve();
                    },
                    (error) => {
                        toast.error(toastMessages.default.fail, overrideToastDefaults);
                        resolve();
                    }
                );
            });
        }

        CloseModal("assign-district", () => {
            setSubmitting(false);
            resetForm();
        });

        setModalKey(Date.now());
    };


    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {({ handleSubmit, isSubmitting, values, touched, errors, setFieldValue }) => {
                    return (
                        <ModalWrapper
                            id={"assign-district"}
                            title={"Assign District"}
                            submitting={isSubmitting}
                            size="sm"
                            handleSubmit={handleSubmit}
                            key={modalKey}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Field name="district">
                                        {() => (
                                            <Autocomplete
                                                getOptionLabel={(option) => option.name}
                                                options={districtsList}
                                                onChange={(event, value) => {
                                                    setFieldValue('district', value);
                                                }}

                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>
                                                        {option.name}
                                                    </li>
                                                )}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="District"
                                                        variant="standard"
                                                        error={touched.district && Boolean(errors.district)}
                                                    />
                                                )} />
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </ModalWrapper>
                    )
                }}
            </Formik>
        </>
    )
}

export default AssignDistrictForm