import * as Yup from "yup";
import { IntlShape } from "react-intl";
import dayjs from "dayjs";

export const SellBatchSchema = Yup.object({
    quantity: Yup.string().required('Required'),
    buyer: Yup.object().nullable().required('Required'),
    unitPrice: Yup.number().required("Required"),
    date: Yup.mixed()
        .required("Required")
        .test("is-valid-dayjs", "Required", (value) => {
            return dayjs.isDayjs(value) && value.isValid();
        }),
})
