import React, {useState} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faCog, faSignOut, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {Logout, useAuth} from "../../../app/modules/auth";
import {AppBar, Toolbar, Typography} from "@mui/material";
import {drawerWidth} from "./MainMenuDrawer";
import {useNavigate} from "react-router-dom";
import {getIdToken, put} from "../../../utils/ajax";
import {remoteRoutes} from "../../../data/constants";
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {INPUTS_DISTRIBUTION_CONSTANTS} from "../../../data/redux/inputs/inputsReducer";
import {coreConstants, ICoreState} from "../../../data/redux/coreReducer";
import {IState} from "../../../data/types";
import {IFarmerProfile} from "../../../app/modules/profiling/interfaces/IFarmerProfile";
import {buildUrl} from "../../../utils/queryBuilder";
import {FARMER_PROFILES_CONSTANTS} from "../../../data/redux/farmers/profilesReducer";
import {CloseModal} from "../../helpers/components/modalHelpers";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../data/toastDefaults";

export const LANGUAGES = [
    {id: 'acholi', label: 'Acholi'},
    {id: 'ateso', label: 'Ateso'},
    {id: 'english', label: 'English'},
    {id: 'langi', label: 'Langi'},
    {id: 'lhukonzo', label: 'Lhukonzo'},
    {id: 'luganda', label: 'Luganda'},
    {id: 'lugisu', label: 'Lugisu'},
    {id: 'lusoga', label: 'Lusoga'},
    {id: 'runya', label: 'Runyankore'},
    {id: 'runyoro', label: 'Runyoro'},
    {id: 'swahili', label: 'Swahili'}
]

const Header = () => {

    const {currentUser, logout} = useAuth()
    const user: any = useSelector((state: IState) => state.core?.user)

    const dispatch = useDispatch()

    const location = useLocation()
    const isSearchPage = location.pathname.includes('/search');
    const includesInventoryPage = location.pathname.includes('/inventory');

    const handleChangeLanguage = (lang: string) => {

        const data: any = {
            userId: user?.user_id,
            claims: [
                {
                    key: "preferred_language",
                    value: lang
                }
            ]
        };

        const url = buildUrl(remoteRoutes.authServer, `/api/v1/users`);
        put(url, data, (response) => {

            dispatch({
                type: coreConstants.coreUpdateLanguage,
                payload: lang
            })

            toast.success(toastMessages.default.success, overrideToastDefaults);
        }, () => {
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });

    }

    return (
        <>
            {
                isSearchPage &&
                <>
                    <AppBar
                        elevation={0}
                        className="bg-white text-black"
                        position="fixed"
                        style={{zIndex: 1}}
                        sx={{width: "100%", ml: `${drawerWidth}px`}}
                    >
                        <Toolbar>
                            <Typography className="w-100" variant="inherit" noWrap component="div">
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                    <div>
                                        <img src="/symos_logo_green.png" className="text-center m-1" alt="Logo"
                                             height={70}/>
                                    </div>
                                    <div className="d-flex align-items-center flex-row">
                                        {/*<div className="px-2">*/}
                                        {/*    <FontAwesomeIcon icon={faCog} size="1x"/>*/}
                                        {/*</div>*/}
                                        {/*<div className="px-2">*/}
                                        {/*    <FontAwesomeIcon icon={faBell} size="1x"/>*/}
                                        {/*</div>*/}
                                        {currentUser ? (
                                            <div className="px-2">
                                                <a href={''}
                                                   id={`user-profile`}
                                                   role="button"
                                                   data-bs-toggle="dropdown"
                                                   aria-haspopup="true"
                                                   aria-expanded="false"
                                                   className="dropdown-toggle text-black">
                                                    {currentUser.given_name} {currentUser.family_name}
                                                </a>

                                                <ul className="dropdown-menu shadow border-0"
                                                    aria-labelledby={`user-profile`}>
                                                    <Logout/>
                                                </ul>

                                            </div>
                                        ) : <></>}

                                        <div className="px-2">
                                            <a href={''}
                                               id={`user-profile`}
                                               role="button"
                                               data-bs-toggle="dropdown"
                                               aria-haspopup="true"
                                               aria-expanded="false"
                                               className="dropdown-toggle text-capitalize text-black btn btn-light">
                                                {user?.preferred_language || 'English'}
                                            </a>

                                            <ul className="dropdown-menu shadow border-0"
                                                aria-labelledby={`user-profile`}>
                                                {LANGUAGES.map((lang: any, index) => {
                                                    return (
                                                        <li key={index} onClick={() => handleChangeLanguage(lang.id)}>
                                                            <a className="dropdown-item text-capitalize"
                                                               href="#">{lang.label}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ul>

                                        </div>

                                        <div className="px-2">
                                            <FontAwesomeIcon icon={faUserCircle} size="2x"/>
                                        </div>
                                    </div>
                                </div>
                            </Typography>
                        </Toolbar>
                    </AppBar>

                </>
            }

            {
                !isSearchPage &&
                <>
                    <AppBar
                        elevation={0}
                        className="bg-white text-black"
                        position="fixed"
                        style={{zIndex: 1}}
                        sx={{width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`}}
                    >
                        <Toolbar>
                            <Typography className="w-100" variant="inherit" noWrap component="div">
                                {includesInventoryPage && <>
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                        <div className="div">
                                            <b>Inventory Management</b>
                                        </div>
                                        <div className="d-flex align-items-center flex-row">
                                            {/*<div className="px-2">*/}
                                            {/*    <FontAwesomeIcon icon={faCog} size="1x"/>*/}
                                            {/*</div>*/}
                                            {/*<div className="px-2">*/}
                                            {/*    <FontAwesomeIcon icon={faBell} size="1x"/>*/}
                                            {/*</div>*/}
                                            {currentUser ? (
                                                <div className="px-2">
                                                    <a href={''}
                                                       id={`user-profile`}
                                                       role="button"
                                                       data-bs-toggle="dropdown"
                                                       aria-haspopup="true"
                                                       aria-expanded="false"
                                                       className="dropdown-toggle text-black">
                                                        {currentUser.given_name} {currentUser.family_name}
                                                    </a>

                                                    <ul className="dropdown-menu shadow border-0"
                                                        aria-labelledby={`user-profile`}>
                                                        <Logout/>
                                                    </ul>

                                                </div>
                                            ) : <></>}

                                            <div className="px-2">
                                                <a href={''}
                                                   id={`user-profile`}
                                                   role="button"
                                                   data-bs-toggle="dropdown"
                                                   aria-haspopup="true"
                                                   aria-expanded="false"
                                                   className="dropdown-toggle text-capitalize text-black btn btn-light">
                                                    {user?.preferred_language || 'English'}
                                                </a>

                                                <ul className="dropdown-menu shadow border-0"
                                                    aria-labelledby={`user-profile`}>
                                                    {LANGUAGES.map((lang: any, index) => {
                                                        return (
                                                            <li key={index} onClick={() => handleChangeLanguage(lang.id)}>
                                                                <a className="dropdown-item text-capitalize" href="#">{lang.label}</a>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>

                                            </div>

                                            <div className="px-2">
                                                <FontAwesomeIcon icon={faUserCircle} size="2x"/>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                {!includesInventoryPage && <>
                                    <div className="d-flex w-100 justify-content-end align-items-center">
                                        <div className="d-flex align-items-center flex-row">
                                            {/*<div className="px-2">*/}
                                            {/*    <FontAwesomeIcon icon={faCog} size="1x"/>*/}
                                            {/*</div>*/}
                                            {/*<div className="px-2">*/}
                                            {/*    <FontAwesomeIcon icon={faBell} size="1x"/>*/}
                                            {/*</div>*/}
                                            {currentUser ? (
                                                <div className="px-2">
                                                    <a href={''}
                                                       id={`user-profile`}
                                                       role="button"
                                                       data-bs-toggle="dropdown"
                                                       aria-haspopup="true"
                                                       aria-expanded="false"
                                                       className="dropdown-toggle text-black">
                                                        {currentUser.given_name} {currentUser.family_name}
                                                    </a>

                                                    <ul className="dropdown-menu shadow border-0"
                                                        aria-labelledby={`user-profile`}>
                                                        <Logout/>
                                                    </ul>

                                                </div>
                                            ) : <></>}

                                            <div className="px-2">
                                                <a href={''}
                                                   id={`user-profile`}
                                                   role="button"
                                                   data-bs-toggle="dropdown"
                                                   aria-haspopup="true"
                                                   aria-expanded="false"
                                                   className="dropdown-toggle text-capitalize text-black btn btn-light">
                                                    {user?.preferred_language || 'English'}
                                                </a>

                                                <ul className="dropdown-menu shadow border-0"
                                                    aria-labelledby={`user-profile`}>
                                                    {LANGUAGES.map((lang: any, index) => {
                                                        return (
                                                            <li key={index}
                                                                onClick={() => handleChangeLanguage(lang.id)}>
                                                                <a className="dropdown-item text-capitalize"
                                                                   href="#">{lang.label}</a>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>

                                            <div className="px-2">
                                                <FontAwesomeIcon icon={faUserCircle} size="2x"/>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                </>
            }
        </>

    );
};

export default Header;