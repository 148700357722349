import {
  faArrowRight,
  faBook,
  faShoppingCart,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { IMenuItem } from "../../../utils/MainMenu";
import { IntlShape } from "react-intl";
import { authService } from "../../../data/oidc/AuthService";
import { IsAgent } from "../../hooks/roleChecker";

interface MainMenuProps {
  activePage: string;
}

export const InputsInnerMenu = (
  intl: IntlShape,
  { activePage }: MainMenuProps,
): IMenuItem[] => {
  const menuItems = [
    {
      id: "INPUTS.INNERMENU.PURCHASES",
      label: intl.formatMessage({
        id: "INPUTS.INNERMENU.PURCHASES",
        defaultMessage: "Purchases",
      }),
      path: "/inputs/purchases",
      isActive:
        activePage.toLowerCase() === "/inputs" ||
        activePage.toLowerCase().split("/").includes("purchases"),
      icon: faShoppingCart,
    },
    {
      label: intl.formatMessage({
        id: "INPUTS.INNERMENU.STOCK",
        defaultMessage: "Stock",
      }),
      path: "/inputs/stock",
      isActive: activePage.toLowerCase().split("/").includes("stock"),
      icon: faBook,
    },
    {
      label: intl.formatMessage({
        id: "INPUTS.INNERMENU.SALES",
        defaultMessage: "Sales",
      }),
      path: "/inputs/sales",
      isActive: activePage.toLowerCase().split("/").includes("sales"),
      icon: faArrowRight,
    },
    {
      label: intl.formatMessage({
        id: "INPUTS.INNERMENU.MOVEMENTS",
        defaultMessage: "Movements",
      }),
      path: "/inputs/movements",
      isActive: activePage.toLowerCase().split("/").includes("movements"),
      icon: faTruck,
    },
  ];

  const roles = authService.getRoles();

  const isAgent = IsAgent(roles);
  return isAgent
    ? menuItems.filter((item) => item.label === "Inputs")
    : menuItems;
};
