import dayjs from "dayjs";
import * as Yup from "yup";

export const InputStockSchema = Yup.object({
    date: Yup.mixed().required("Record Date is required")
        .test("is-valid-dayjs", "Record Date is required", (value) => {
            return dayjs.isDayjs(value) && value.isValid();
        }),
    inputName: Yup.object().required("Input is required"),
    unitName: Yup.object().nullable().required("Unit is required"),
    quantity: Yup.number().required("Quantity is required"),
    unitCost: Yup.number().required("Unit cost is required"),
    totalCost: Yup.number().required("Total cost paid is required"),
    collectionCentreName: Yup.object().nullable().required("Required")
})