import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {remoteRoutes} from "../../../../data/constants";
import {useAuth} from "../../auth";
import {buildUrl} from "../../../../utils/queryBuilder";
import {post} from "../../../../utils/ajax";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {IState} from "../../../../data/types";
import ExcelUploader from "../../../components/shared/ExcelUploader";
import {IMPORT_PURCHASES_CONSTANTS} from "../../../../data/redux/inventory/importPurchasesReducer";
import {useIntl} from "react-intl";

export const ImportPurchasesForm = () => {
    const intl = useIntl();
    const {currentUser} = useAuth();
    const dispatch = useDispatch()

    const [modalKey, setModalKey] = useState(Date.now());

    const {data, loading}: any = useSelector((state: IState) => state.importPurchases)

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const dataToUpload = data.map((d: any, index: number) => (
            {
                hubId: currentUser?.hubId,
                date: d.date,
                plotCode: d.plot_code,
                // purchaseId: d.purchase_id,
                // batchNumber: d.batch_number,
                quantity: d.quantity,
                unit: d.unit
            }
        ))

        // console.log(dataToUpload)

        const url = buildUrl(remoteRoutes.inventoryService, `/purchases/import`);

        post(url, dataToUpload, () => {

            dispatch({
                type: IMPORT_PURCHASES_CONSTANTS.DELETE_PURCHASES
            })

            CloseModal("import-purchases", () => {
                setSubmitting(false);
                resetForm();
            })

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };

    return (
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting, values, setFieldValue}) => (
                <ModalWrapper
                    id="import-purchases"
                    title={`${intl.formatMessage({ id: "FORM.TITLE.IMPORTPURCHASES", defaultMessage: "Import Purchases" })}`}
                    size="lg"
                    key={modalKey}
                    position="centered"
                    submitBtnLabel="Import"
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row scrollable-content">
                        <div className="col-lg-12">
                            <ExcelUploader />
                        </div>
                    </div>
                </ModalWrapper>
            )}
        </Formik>
    );
};