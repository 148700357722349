import React, { useCallback, useEffect, useState } from "react";
import { IHeaderButtonProps, SettingsPageHeader } from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import { useIntl } from "react-intl";
import debounce from "lodash.debounce";
import XTable, { ITableColumn } from "../../../components/shared/XTable";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import XPagination from "../../../components/shared/XPagination";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { useDispatch, useSelector } from "react-redux";
import { ISettingsState, SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { remoteRoutes } from "../../../../data/constants";
import AssignDistrictForm from "./modals/AssignDistrictForm";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import toast from "react-hot-toast";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { IState } from "../../../../data/types";
import { IUser } from "../../onboarding/userManagement/interfaces/IUser";
import { useAuth } from "../../auth";

interface IProps {
    hubId?: string;
    title?: string;
    includeMyHubUsers?: boolean;
}

const columns: ITableColumn[] = [
    {
        localeId: "TABLE.COLUMN.FIRSTNAME",
        label: "First Name",
        id: "firstName",
        textAlign: "text-start",
    },
    {
        localeId: "TABLE.COLUMN.LASTNAME",
        label: "Last Name",
        id: "lastName",
        textAlign: "text-start",
    },
    {
        localeId: "TABLE.COLUMN.TELEPHONE",
        label: "Phone Number",
        id: "phoneNumber",
        textAlign: "text-start",
    },
    {
        localeId: "TABLE.COLUMN.EMAIL",
        label: "Email",
        id: "email",
        textAlign: "text-start",
    },
    {
        localeId: "TABLE.COLUMN.DISTRICT",
        label: "Distict Assigned",
        id: "districtName",
        textAlign: "text-start",
    },
]

const HubAgentsPage = ({ title, hubId, includeMyHubUsers }: IProps) => {
    document.title = "Symos > Agents";
    const intl = useIntl();
    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [page, setPage] = useState<number>(1);
    const [selected, setSelected] = useState<any[]>([]);
    const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);

    const { users, loading }: ISettingsState = useSelector(
        (state: IState) => state.settings,
    );
    const { pagination, data } = users;

    const filterUsers = () => {
        if (searchTerm) {
            setFilteredUsers(
                data.filter((user) =>
                    user.firstName.toLowerCase().includes(searchTerm.toLowerCase()),
                ),
            );
        } else {
            setFilteredUsers(data);
        }
    };

    useEffect(() => {
        filterUsers()
    }, [searchTerm, users]);

    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value);
            setPage(1);
        }, 800),
        [],
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        debouncedSearch(value);
    };

    const setLoading = (state: boolean) => {
        dispatch({
            type: SETTINGS_CONSTANTS.LOADING_DATA,
            payload: state,
        });
    };

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination?.pageSize, searchTerm);
    }, [page, searchTerm]);

    const handleFetchData = (page: number, pageSize: number, searchTerm?: string) => {
        if (remoteRoutes.authServer) {
            const Role = 'Agent'
            const myHubId = currentUser?.hubId;

            const url = buildUrl(
                remoteRoutes.onboardingService,
                `/hubs/${hubId ?? myHubId}/child-ids`,
            );
            get(
                url,
                (response: any) => {
                    if (hubId) response.push(hubId);
                    if (includeMyHubUsers) response.push(myHubId);

                    let params: any = { page, pageSize, Role, hubIds: response?.join(",") };

                    if (searchTerm) {
                        params.searchTerm = searchTerm;
                    }

                    const url = buildUrl(
                        remoteRoutes.authServer,
                        `/api/v1/users`,
                        params,
                    );
                    get(
                        url,
                        (response: IApiResponse) => {
                            const { data, paginationMetadata } = response;

                            dispatch({
                                type: SETTINGS_CONSTANTS.FETCH_USERS,
                                payload: { data, pagination: paginationMetadata },
                            });

                            toast.success(
                                toastMessages.default.success,
                                overrideToastDefaults,
                            );
                        },
                        async (error) => {
                            toast.error(toastMessages.default.fail, overrideToastDefaults);
                        },
                        () => {
                            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                            setLoading(false);
                        },
                    );
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => { },
            );
        }

    }

    const handleSelectAll = (rows: any[]) => {
        setSelected((prevSelected) => {
            if (prevSelected.length === rows.length) {
                return [];
            } else {
                return rows.map((row) => row.id);
            }
        });

        const allIds = rows.map((row) => row.id);

        setSelectedIds((prev) =>
            prev.length === allIds.length ? [] : allIds,
        );
    };

    const handleCheckRow = (item: IUser) => {
        setSelected((prevSelected) => {
            const newSelected = prevSelected ? [...prevSelected] : [];

            const index = newSelected.indexOf(item.id);
            if (index > -1) {
                newSelected.splice(index, 1);
            } else {
                newSelected.push(item.id);
            }

            setSelectedIds(newSelected);

            return newSelected;
        });

    };

    const actionButtons: IHeaderButtonProps[] = [
        {
            label: `${intl.formatMessage({ id: "BUTTONS.ASSIGNDISTRICT", defaultMessage: "Assign District" })}`,
            cssClass: "btn btn-primary btn-sm",
            dataTarget: "#assign-district",
            dataToggle: "modal",
        },
    ];

    return (
        <>
            {title && <SettingsPageHeader title={title} />}
            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-auto">
                        <input
                            type="text"
                            onChange={handleSearchChange}
                            className="form-control"
                            placeholder={intl.formatMessage({
                                id: "TABLE.SEARCH",
                                defaultMessage: "Type to search...",
                            })}
                        />
                        <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                    </div>

                    <div className="action-buttons">
                        {/* <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange" />
                            <span className="ms-2">Filters</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">
                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange" />
                        </button> */}


                        {selectedIds.length > 0 && showActionButtons &&
                            actionButtons.map((button, index) => {
                                return (
                                    <button
                                        data-bs-toggle={button.dataToggle}
                                        data-bs-target={button.dataTarget}
                                        className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                                        key={index}
                                        onClick={button.onClick}
                                    >
                                        {button.processing ? "Please wait..." : button.label}
                                    </button>
                                );
                            })}
                    </div>
                </div>

                <XTable
                    selected={selected}
                    onSelectAll={(rows) => handleSelectAll(rows)}
                    onCheckRow={(row) => handleCheckRow(row)}
                    onRowClick={(row) => handleCheckRow(row)}
                    data={filteredUsers}
                    columns={columns}
                    loading={loading}
                />

                <XPagination
                    dataLength={filteredUsers.length}
                    pagination={pagination}
                    currentPage={page}
                    setPage={(page) => setPage(page)}
                />
            </TableWrapper>

            <AssignDistrictForm ids={selectedIds} />
        </>
    )
}

export default HubAgentsPage