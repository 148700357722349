import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { Field, Form, Formik } from "formik";
import {get, post} from "../../../../utils/ajax";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import moment from "moment";
import { useAuth } from "../../auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { XTextArea } from "../../../components/shared/forms/XTextArea";
import {IOption, XAutoComplete} from "../../../components/shared/forms/XAutoComplete";
import {ICollectionCentre} from "../../settings/interfaces/IHub";
import { MoveBatchSchema } from "../schemas/MoveBatchSchema";
import { IBatch } from "../interfaces/IBatch";
import { XRadioGroup } from "../../../components/shared/forms/XRadioGroup";
import { INVENTORY_BATCH_MOVEMENTS_CONSTANTS } from "../../../../data/redux/inventory/batchMovementsReducer";
import { INVENTORY_BATCHES_CONSTANTS } from "../../../../data/redux/inventory/batchesReducer";
import { BatchStatus } from "../interfaces/BatchStatus";
import {useIntl} from "react-intl";
import {IApiResponse} from "../../../interfaces/IApiResponse";
import {SETTINGS_CONSTANTS} from "../../../../data/redux/settings/settingsReducer";

type Props = {
    batch?: IBatch | null
}

export const movements = [
    { id: 1, label: "Centre to Centre" },
    { id: 2, label: "Agent to Agent", disabled: true },
    { id: 3, label: "Farmer to Agent", disabled: true },
]

export const MoveBatchForm = ({ batch }: Props) => {

    const intl = useIntl();

    const movements = [
        {
            id: 1,
            label: `${intl.formatMessage({id: "BUTTONS.RADIO.CENTRETOCETRE", defaultMessage: "Centre to Centre"})}`,
        },
        {
            id: 2,
            label: `${intl.formatMessage({id: "BUTTONS.RADIO.AGENTTOAGENT", defaultMessage: "Agent to Agent"})}`,
            disabled: true
        },
        {
            id: 3,
            label: `${intl.formatMessage({id: "BUTTONS.RADIO.FARMERTOAGENT", defaultMessage: "Farmer to Agent"})}`,
            disabled: true
        },
    ]

    const dispatch = useDispatch()
    const { currentUser } = useAuth()
    const [locations, setLocations] = useState<ICollectionCentre[]>([]);

    const collectionCentres: IOption[] = locations
        ?.filter((c) => c.id !== batch?.currentCollectionCentreId)
        .map((c) => ({
            id: c.id ?? "",
            label: c.name ?? "",
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        date: batch?.date ? new Date(batch.date as string).toISOString() : new Date().toISOString(),
        destinationCollectionCentre: null,
        typeOfMovement: 1,
        remarks: ''
    }

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            let params: any = {
                pageSize: 100,
            };

            let url = ''

            const hubId = currentUser?.hubId

            if (hubId) {
                params = {hubId};

                url = buildUrl(remoteRoutes.onboardingService, "/collection/centres", params);
                get(
                    url,
                    (response: IApiResponse) => {
                        const {data} = response;
                        setLocations(data);
                    },
                    async (error) => {
                    },
                    () => {
                        dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                    },
                )
            }

        }
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.inventoryService, '/movements')
        const data = {
            hubId: currentUser?.hubId,
            date: moment().toISOString().replace('Z', '+00:00'),
            batchId: batch?.id,
            batchNumber: batch?.batchNumber,
            sourceCollectionCentreId: batch?.currentCollectionCentreId,
            sourceCollectionCentreName: batch?.currentCollectionCentreName,
            destinationCollectionCentreId: values.destinationCollectionCentre.id,
            destinationCollectionCentreName: values.destinationCollectionCentre.label,
            typeOfMovement: values.typeOfMovement,
            remarks: values.remarks,
            batchStatus: 2,
        }

        post(url, data, (response) => {

            CloseModal("move-batch", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: INVENTORY_BATCH_MOVEMENTS_CONSTANTS.POST_BATCH_MOVEMENT,
                payload: data
            })

            dispatch({
                type: INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH,
                payload: { ...batch, currentStatus: BatchStatus.InTransit }
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
        handleFetchData();
    }, []);

    const validationSchema = MoveBatchSchema(intl)
    return (

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ handleSubmit, isSubmitting, errors, values, setFieldValue }) => {

                return <ModalWrapper
                    id="move-batch"
                    title={`${intl.formatMessage({id: "FORM.TITLE.MOVEABATCH", defaultMessage: "Move a Batch"})}`}
                    size="lg"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <Form>
                        <div className="row">
                            <div className="col-12 col-lg-12">
                                <Field
                                    row={true}
                                    disabled={isSubmitting}
                                    component={XRadioGroup}
                                    errors={errors}
                                    value={values.typeOfMovement}
                                    options={movements || []}
                                    label={`${intl.formatMessage({ id: "FORM.TYPEOFMOVEMENT", defaultMessage: "Type of Movement" })}`}
                                    name="typeOfMovement"
                                />
                            </div>

                            <div className="col-lg-12 col-12">
                                <Field
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={collectionCentres || []}
                                    value={values.destinationCollectionCentre}
                                    getOptionLabel={(option: any) => (option ? option.label : '')}
                                    label={`${intl.formatMessage({ id: "FORM.DESTINATION", defaultMessage: "Destination" })}`}
                                    name="destinationCollectionCentre"
                                />
                            </div>

                            <div className="col-lg-12">
                                <Field
                                    disabled={isSubmitting}
                                    component={XTextArea}
                                    rows={6}
                                    value={values.remarks}
                                    label={`${intl.formatMessage({ id: "FORM.REMARKS", defaultMessage: "Remarks" })}`}
                                    name="remarks"
                                />
                            </div>

                        </div>
                    </Form>
                </ModalWrapper>

            }}
        </Formik>


    )
}

