import { Formik } from "formik";
import toast from "react-hot-toast";
import { ICostCentre } from "../../interfaces/IHub";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { del } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { useIntl } from "react-intl";
import {IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";

interface Props {
  item: ICostCentre;
  pagination: IPaginationMetadata
  close: () => void;
}

const DeleteCostCentreForm = ({item, pagination, close}: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const [modalKey, setModalKey] = useState(Date.now());

  const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
    const url = buildUrl(
      remoteRoutes.onboardingService,
      `/cost/centres/${item.id}`,
    );

    const id = item.id

    del(
      url,
      (response) => {

        dispatch({
          type: SETTINGS_CONSTANTS.DELETE_COST_CENTRE,
          payload: {
            id, pagination: {
              ...pagination,
              totalItems: pagination.totalItems - 1,
            }
          }
        })

        CloseModal("delete-cost-centre", () => {
          setSubmitting(false);
          resetForm();
        });

        close();
        toast.success(toastMessages.default.success, overrideToastDefaults);
        setModalKey(Date.now());
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  return (
    <Formik
      initialValues={item}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      key={modalKey}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id="delete-cost-centre"
            title={`${intl.formatMessage({ id: "FORM.TITLE.DELETECOSTCENTRE", defaultMessage: "Delete Cost Centre" })}`}
            size="md"
            isDeleteAction={true}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>
              Are you sure you want to delete this cost centre? This action
              cannot be undone.
            </p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteCostCentreForm;
