import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import utc from "dayjs/plugin/utc";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import {
  ICollectionCentre,
  IInput,
} from "../../settings/interfaces/IHub";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { FormattedMessage, useIntl } from "react-intl";
import { get } from "../../../../utils/ajax";
import { remoteRoutes } from "../../../../data/constants";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { buildUrl } from "../../../../utils/queryBuilder";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { useDispatch } from "react-redux";
import { useAuth } from "../../auth";

type Props = {
  onApplyFilters: (filters: any) => void;
  setFilters: (filters: any) => void;
};

const InputMovementsFilterForm: React.FC<Props> = ({
  onApplyFilters,
  setFilters,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [inputs, setInputs] = useState<IInput[]>([]);
  const [locations, setLocations] = useState<ICollectionCentre[]>([]);

  const initialValues = {
    input: { label: "", id: "" },
    sourceCollectionCentre: { label: "", id: "" },
    destinationCollectionCentre: { label: "", id: "" },
    from: null,
    to: null,
  };

  const collectionCentres: IOption[] = locations
    .map((c) => ({
      id: c.id ?? "",
      label: c.name ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const inputsList: IOption[] = inputs
    .map((i) => ({
      id: i.id ?? "",
      label: i.name ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleFetchData = () => {
    if (remoteRoutes.onboardingService) {
      let params: any = {
        pageSize: 100,
      };

      let url = "";

      const hubId = currentUser?.hubId;

      if (hubId) {
        params = { hubId };

        url = buildUrl(remoteRoutes.onboardingService, "/collection/centres", params,
        ); get(
          url,
          (response: IApiResponse) => {
            const { data } = response;
            setLocations(data);
          },
          async (error) => {
          },
          () => {
            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        );

        url = buildUrl(remoteRoutes.onboardingService, "/inputs", params);
        get(
          url,
          (response: IApiResponse) => {
            const { data } = response;
            setInputs(data);
          },
          async (error) => { },
          () => {

            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        );
      }
    }
  };
  
  const handleSubmit = (values: any) => {
    dayjs.extend(utc);

    const filters = {
      inputId: values.input?.id ?? "",
      sourceCollectionCentreId: values.sourceCollectionCentre?.id ?? "",
      destinationCollectionCentreId:
        values.destinationCollectionCentre?.id ?? "",
      from: values?.from
        ? fromZonedTime(
          toZonedTime(new Date(values.from), userTimeZone),
          "UTC",
        ).toISOString()
        : dayjs(0).utc().toISOString(),
      to: values?.to
        ? fromZonedTime(
          toZonedTime(new Date(values.to), userTimeZone),
          "UTC",
        ).toISOString()
        : dayjs().endOf("day").utc().toISOString(),
    };
    onApplyFilters(filters);
  };

  const handleClearForm = (resetForm: any) => {
    resetForm();
    setFilters({});
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ resetForm, values, dirty }) => {
          return (
            <Form>
              <div className={"row"}>
                <div className={"col-lg-12"}>
                  <Field
                    type="string"
                    component={XAutoComplete}
                    options={inputsList || []}
                    getOptionsLabel={(option: any) =>
                      option ? option.label : ""
                    }
                    values={values.input}
                    label={`${intl.formatMessage({ id: "FILTER.LABEL.INPUTNAME", defaultMessage: "Input name" })}`}
                    name={"input"}
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    type="string"
                    component={XAutoComplete}
                    options={
                      collectionCentres?.filter(
                        (centre) => centre.id !== values.destinationCollectionCentre?.id
                      ) || []
                    }
                    value={values.sourceCollectionCentre}
                    label={`${intl.formatMessage({
                      id: "FILTER.LABEL.SOURCE",
                      defaultMessage: "Source",
                    })}`}
                    name="sourceCollectionCentre"
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    type="string"
                    component={XAutoComplete}
                    options={
                      collectionCentres?.filter(
                        (centre) => centre.id !== values.sourceCollectionCentre?.id
                      ) || []
                    }
                    value={values.destinationCollectionCentre}
                    label={`${intl.formatMessage({
                      id: "FILTER.LABEL.DESTINATION",
                      defaultMessage: "Destination",
                    })}`}
                    name="destinationCollectionCentre"
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    type="string"
                    label={`${intl.formatMessage({ id: "FILTER.LABEL.FROM", defaultMessage: "From" })}`}
                    as={XDatePicker}
                    name="from"
                    maxDate={dayjs(new Date())}
                    value={values.from || null}
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    type="string"
                    label={`${intl.formatMessage({ id: "FILTER.LABEL.TO", defaultMessage: "To" })}`}
                    as={XDatePicker}
                    name="to"
                    maxDate={dayjs(new Date())}
                    value={values.to || null}
                  />
                </div>
                <div className="border-top mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary ms-2"
                    disabled={!dirty}
                  >
                    <FormattedMessage
                      id="BUTTONS.APPLY"
                      defaultMessage="Apply"
                    />
                  </button>
                  <button
                    type="button"
                    title={"Clear Filters"}
                    onClick={() => handleClearForm(resetForm)}
                    disabled={!dirty}
                    className="btn btn-sm btn-outline-secondary ms-2"
                  >
                    <FormattedMessage
                      id="BUTTONS.CLEAR"
                      defaultMessage="Clear"
                    />
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default InputMovementsFilterForm;
