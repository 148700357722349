import React, {useEffect, useState} from 'react';
import {ITableColumn} from "../../../components/shared/XTable";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import Map from "../../../components/shared/Map";
import {useAuth} from "../../auth";

interface IProps {
    plots: any[]
}

const Plots = (props: IProps) => {
    const {plots} = props

    const [polygons, setPolygons] = useState<any>([])

    useEffect(() => {
        const polygonArray = plots.map(plot =>
            plot.polygonCoordinates.sort((a: any, b: any) => a.position - b.position).map((coord: any) => ({
                lat: coord.latitude,
                lng: coord.longitude,
                position: coord.position
            }))
        );

        setPolygons(polygonArray)

    }, [plots])

    return (
        <>
            <div className="mb-3 rounded-5">
                <Map width={'100%'} height={'800px'} zoom={14} polygons={polygons} plots={plots}/>
            </div>
        </>
    );
};

export default Plots;