import {
  GoogleMap,
  InfoWindow,
  InfoWindowF,
  LoadScript,
  MarkerF,
} from "@react-google-maps/api";
import React, { useState } from "react";
import { env } from "../../../data/constants";
import { FormattedMessage } from "react-intl";

interface Props {
  lat: number;
  lng: number;
}

const MapComponent = ({ lat, lng }: Props) => {
  const [selected, setSelected] = useState<{ lat: number; lng: number } | null>(
    null,
  );
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const handleMarkerClick = () => {
    setSelected(centre);
  };

  const handleInfoWindowCloseClick = () => {
    setSelected(null);
  };

  const onLoad = (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  };

  const onUnmount = () => {
    setMap(null);
  };

  const mapContainerStyle = {
    height: "250px",
    width: "100%",
  };

  const options = {
    disableDefaultUI: true, // Disable all default UI
    zoomControl: false, // Disable the zoom control
    mapTypeControl: false, // Disable the map/satellite switcher
    streetViewControl: false, // Disable the street view control
    fullscreenControl: false, // Disable the fullscreen control
  };

  const centre = { lat: lat, lng: lng };

    return (
        <GoogleMap
            zoom={14}
            center={centre}
            options={options}
            mapContainerStyle={mapContainerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <MarkerF position={centre} onClick={handleMarkerClick}/>

      {selected && (
        <InfoWindowF
          position={selected}
          onCloseClick={handleInfoWindowCloseClick}
        >
          <table>
            <thead>
              <tr>
                <th className="border-bottom">
                  <FormattedMessage
                    id="MAP.COLLECTIONCENTRE"
                    defaultMessage="Collection Centre"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="marker-tr">
                <td className="w-100">
                  <div className="row">
                    <div className="col-lg-6">
                      <FormattedMessage
                        id="MAP.LATITUDE"
                        defaultMessage="Latitude"
                      />
                    </div>
                    <div className="col-lg-6">{lat}</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <FormattedMessage
                        id="MAP.LONGITUDE"
                        defaultMessage="Longitude"
                      />
                    </div>
                    <div className="col-lg-6">{lng}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </InfoWindowF>
      )}
    </GoogleMap>
  );
};

export default MapComponent;
