import React, {useEffect, useState} from 'react'
import {Field, Formik} from "formik";
import {IBatch} from "../interfaces/IBatch";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {XTextField} from "../../../components/shared/forms/XTextField";
import {useDispatch} from "react-redux";
import {ICollectionCentre} from "../../settings/interfaces/IHub";
import {IOption, XAutoComplete} from "../../../components/shared/forms/XAutoComplete";
import {useAuth} from "../../auth";
import {buildUrl} from "../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../data/constants";
import {get, put} from "../../../../utils/ajax";
import {IApiResponse} from "../../../interfaces/IApiResponse";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {INVENTORY_BATCHES_CONSTANTS} from "../../../../data/redux/inventory/batchesReducer";

interface Props {
    initialValues: IBatch
}

const EditBatchForm = ({initialValues}: Props) => {

    const dispatch = useDispatch();
    const {currentUser} = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());
    const [locations, setLocations] = useState<ICollectionCentre[]>([]);

    const collectionCentres: IOption[] = locations
        .map((c) => ({
            id: c.id ?? "",
            label: c.name ?? "",
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        handleFetchData();
    }, []);

    const handleFetchData = () => {
        const hubId = currentUser?.hubId

        let url = ''
        let params: any

        if (hubId) {
            params = {hubId};

            url = buildUrl(remoteRoutes.onboardingService, "/collection/centres", params);
            get(
                url,
                (response: IApiResponse) => {
                    const {data} = response;
                    setLocations(data);
                },
                async (error) => {
                },
                () => {
                    dispatch({type: INVENTORY_BATCHES_CONSTANTS.STOP_FETCH});
                },
            )
        }

    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.inventoryService, `/batches/${values.id}`)
        const data: IBatch = {
            ...values,
            date: initialValues?.date instanceof Date ? initialValues.date.toISOString() : new Date(initialValues?.date).toISOString(),
            currentCollectionCentreName: values.currentCollectionCentreName.label,
            currentCollectionCentreId: values.currentCollectionCentreName.id,
        }

        put(url, data, (response) => {
                CloseModal("edit-batch", () => {
                    setSubmitting(false);
                    resetForm();
                })

                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH,
                    payload: data
                })

                toast.success(toastMessages.default.success, overrideToastDefaults);
                setModalKey(Date.now());
            }, (error) => {
                setSubmitting(false);
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            }, () => {

            }
        )
    }

    return (
        <Formik
            initialValues={{
                ...initialValues,
                currentCollectionCentreName: initialValues?.currentCollectionCentreName
                    ? {
                        id: initialValues.currentCollectionCentreId,
                        label: initialValues.currentCollectionCentreName
                    }
                    : null
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id={'edit-batch'}
                    title={'Edit Batch Details'}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Batch Number"
                                name="batchNumber"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled
                                as={XTextField}
                                label="Quantity"
                                name="quantity"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled
                                as={XTextField}
                                label="Product"
                                name="productName"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled
                                as={XTextField}
                                label="Variety"
                                name="variety"
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={collectionCentres || []}
                                getOptionLabel={(option: any) => (option ? option.label : "")}
                                label="Where is this batch?"
                                name="currentCollectionCentreName"
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Description"
                                name="description"
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default EditBatchForm