import React, { useEffect, useState } from "react"
import { IBuyer } from "../interfaces/IBuyer";
import { useIntl } from "react-intl";
import { Field, Formik } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import { useDispatch } from "react-redux";
import { put } from "../../../../utils/ajax";
import { BUYERS_CONSTANTS } from "../../../../data/redux/inventory/buyersReducer";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";

type Props = {
    initialValues: IBuyer
}
const EditBuyerForm = ({ initialValues }: Props) => {

    const intl = useIntl()
    const dispatch = useDispatch();
    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.inventoryService, `/buyers/${values.id}`)

        const data: IBuyer = {
            ...values
        }

        put(url, data, (response) => {
            CloseModal("edit-buyer", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: BUYERS_CONSTANTS.UPDATE_BUYER,
                payload: data
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id="edit-buyer"
                    title={`${intl.formatMessage({ id: "FORM.TITLE.EDITBUYER", defaultMessage: "Edit Buyer" })}`}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.NAME", defaultMessage: "Name" })}`}
                                name="name"
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.EMAIL", defaultMessage: "Email" })}`}
                                name="email"
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.PHONENUMBER", defaultMessage: "Phone Number" })}`}
                                name="phoneNumber"
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.EORINUMBER", defaultMessage: "EORINumber" })}`}
                                name="eoriNumber"
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.ADDRESS", defaultMessage: "Address" })}`}
                                name="address"
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default EditBuyerForm