import {
    faShoppingCart,
    faTableColumns
} from "@fortawesome/free-solid-svg-icons";
import { IMenuItem } from "../../../utils/MainMenu";
import {IntlShape} from "react-intl";

interface MainMenuProps {
  activePage: string;
}

export const ProcessingInnerMenu = (intl: IntlShape, { activePage }: MainMenuProps): IMenuItem[] => {
    const menuItems = [
        {
            id: "INVENTORY.INNERMENU.OVERVIEW",
            label: intl.formatMessage({
                id: "INVENTORY.INNERMENU.OVERVIEW",
                defaultMessage: "Overview",
            }),
            path: '/processing/dashboard',
            isActive: activePage.toLowerCase().split("/").includes('dashboard'),
            icon: faTableColumns
        },
        {
            id: "INVENTORY.INNERMENU.PROCESSRUNS",
            label: intl.formatMessage({
                id: "INVENTORY.INNERMENU.PROCESSRUNS",
                defaultMessage: "Process Runs",
            }),
            path: "/processing/runs",
            isActive: activePage.toLowerCase() === '/processing' || activePage.toLowerCase().split("/").includes('runs'),
            icon: faShoppingCart,
        }
    ];

    return process.env.REACT_APP_HIDE_OVERVIEW === 'true'
        ? menuItems.filter(item => item.label !== "Overview")
        : menuItems;
}
