import {Formik} from "formik";
import toast from "react-hot-toast";
import React, {useState} from "react";
import {remoteRoutes} from "../../../../data/constants";
import {useAuth} from "../../auth";
import {buildUrl} from "../../../../utils/queryBuilder";
import {post} from "../../../../utils/ajax";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import moment from "moment/moment";
import {FormattedMessage, useIntl} from "react-intl";

type Props = {
    purchases?: any[]
    batches?: any[]
}

export const DownloadCsvForm = ({purchases, batches}: Props) => {
    const {currentUser} = useAuth();
    const intl = useIntl();

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.inventoryService, `/purchases/sale/third-party`);

        let saleData = {}
        if (purchases) {
            saleData = {
                date: moment().toISOString(),
                hubId: currentUser?.hubId,
                purchaseIds: purchases
            }
        }

        if (batches) {
            saleData = {
                date: moment().toISOString(),
                hubId: currentUser?.hubId,
                batchIds: batches
            }
        }

        const data = {
            saleData,
            userDetails: {
                name: `${currentUser?.given_name} ${currentUser?.family_name}`,
                email: currentUser?.email
            }
        }

        post(url, data, () => {

            CloseModal("download-csv", () => {
                setSubmitting(false);
                resetForm();
            })

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };

    return (
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting, values, setFieldValue}) => (
                <ModalWrapper
                    id="download-csv"
                    title={`${intl.formatMessage({ id: "FORM.TITLE.CONFIRMATION", defaultMessage: "Confirmation" })}`}
                    size="md"
                    position="top"
                    key={modalKey}
                    submitBtnLabel={`${intl.formatMessage({
                        id: "BUTTONS.DOWNLOAD",
                        defaultMessage: "Download"
                    })}`}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <FormattedMessage
                                id={"CONFIRMATION.MESSAGE.EXPORTPURCHASES"}
                                defaultMessage={"An excel sheet will be generated and sent to your email address "}
                            />
                            <strong>{currentUser?.email}</strong>
                        </div>
                    </div>
                </ModalWrapper>
            )}
        </Formik>
    );
};