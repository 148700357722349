import * as Yup from "yup";
import { IntlShape } from "react-intl";

export interface IHubDto {
  name: string;
  email: string;
  phoneNumber: string;
  district: string;
  districtId: string;
  parentHubId?: string;
  subCounty?: string;
  county?: string;
  parish?:string;
  village?: string;
  postalAddress?: string;
  minimumAggregationUnit?: string;
  id?: string;
}

const phoneValidationSchema = (intl: IntlShape) =>
  Yup.string()
    .matches(
      /^\d{9}$/,
      `${intl.formatMessage({
        id: "ERROR.TELEPHONE.LENGTH",
        defaultMessage: "Phone number must be exactly 9 digits",
      })}`,
    )
    .required(
      `${intl.formatMessage({
        id: "ERROR.TELEPHONE.REQUIRED",
        defaultMessage: "Phone number is required.",
      })}`,
    );

export const HubSchema = (intl: IntlShape) =>
  Yup.object({
    name: Yup.string()
      .nullable()
      .required(
        `${intl.formatMessage({
          id: "ERROR.FIELD.REQUIRED",
          defaultMessage: "Required",
        })}`,
      ),
      district: Yup.object()
      .nullable()
      .required(
        `${intl.formatMessage({
          id: "ERROR.FIELD.REQUIRED",
          defaultMessage: "Required",
        })}`,
      ),
    phoneNumber: phoneValidationSchema(intl),
    email: Yup.string()
      .nullable()
      .email(
        `${intl.formatMessage({
          id: "ERROR.EMAIL.INVALID",
          defaultMessage: "Invalid email",
        })}`,
      ),
  });
