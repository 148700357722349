import React, { useEffect, useState } from "react";
import {
  IContactPerson,
  IHub,
  IProduct,
} from "../../../settings/interfaces/IHub";
import TableWrapper from "../../../../components/shared/TableWrapper";
import { TableFilterLoader } from "../../../../components/shared/loaders/TableFilterLoader";
import { TableLoader } from "../../../../components/shared/loaders/TableLoader";
import HubProductsPage from "../../../settings/products/HubProductsPage";
import {
  HUBS_CONSTANTS,
  IHubState,
} from "../../../../../data/redux/settings/hubsReducer";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../../data/types";
import { ITableColumn } from "../../../../components/shared/XTable";
import Products from "./_products";
import Agents from "./_agents";
import { remoteRoutes } from "../../../../../data/constants";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { get } from "../../../../../utils/ajax";
import { IApiResponse } from "../../../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { ITrainingDetails } from "../../../training/interfaces/ITraining";
import { TRAININGS_CONSTANTS } from "../../../../../data/redux/trainings/trainingsReducer";
import { ContactPeople } from "./_contactPeople";
import { IButtonProps } from "../../../../components/shared/PageHeader";
import { IHeaderButtonProps } from "../../../../components/shared/SettingsPageHeader";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  hub?: IHub;
};

const HubDetails = ({ hub }: Props) => {
  const intl = useIntl();

  const contactPersons: IContactPerson[] = hub?.contactPersons || [];
  const products: IProduct[] = hub?.products || [];

  const contactPerson = contactPersons?.length ? contactPersons[0] : null;

  return (
    <>
      <div className="card shadow-sm border-0 py-3 px-3">
        <div className="card shadow-sm border-0 py-3 px-3">
          <div className="row">
            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage
                  id="DETAILS.PHONENUMBER"
                  defaultMessage="Phone number"
                />
              </strong>
              <br />
              <span>
                  {hub?.phoneNumber
                      ? (hub.phoneNumber.startsWith('+256')
                          ? hub.phoneNumber
                          : `+256 ${hub.phoneNumber}`)
                      : '-'
                  }
              </span>
            </div>
            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage id="DETAILS.EMAIL" defaultMessage="Email" />
              </strong>
              <br />
              <span>{hub?.email ?? "-"}</span>
            </div>

            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage id="DETAILS.MAU" defaultMessage="Mau"/>
              </strong>
              <br />
              <span>{hub?.minimumAggregationUnit ?? "-"}</span>
            </div>

            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage
                  id="DETAILS.DISTRICT"
                  defaultMessage="District"
                />
              </strong>
              <br />
              <span>{hub?.district || '-'}</span>
            </div>
            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage
                  id="DETAILS.SUBCOUNTY"
                  defaultMessage="Sub county"
                />
              </strong>
              <br />
              <span>{hub?.subCounty ?? "-"}</span>
            </div>
            <div className="col-lg-4 mb-4">
              <strong className="small">
                <FormattedMessage
                  id="DETAILS.VILLAGE"
                  defaultMessage="Village"
                />
              </strong>
              <br />
              <span>{hub?.village ?? "-"}</span>
            </div>
          </div>

          {/*<hr/>*/}
        </div>

                {contactPersons && contactPersons.length > 0 &&
                    <div className="row">
                        <div className="col-12">
                            <ContactPeople contactPersons={contactPersons}/>
                        </div>
                    </div>
                }

            </div>

        </>
    );
}

export default HubDetails;
