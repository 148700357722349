import { Formik, Field } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { IInputDistribution } from "../interfaces/IInputDistribution";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ICollectionCentre,
  IHub,
  IIdNamePair,
  IInput,
  IUnit,
} from "../../settings/interfaces/IHub";
import {
  IOption,
  XAutoComplete,
} from "../../../components/shared/forms/XAutoComplete";
import { authService } from "../../../../data/oidc/AuthService";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get, put } from "../../../../utils/ajax";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { InputStockSchema } from "../schemas/InputStockSchema";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth";

type Props = {
  initialValues: IInputDistribution | null;
};

const EditInputStockForm = ({ initialValues }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { currentUser } = useAuth();

  const [modalKey, setModalKey] = useState(Date.now());
  const [inputs, setInputs] = useState<IInput[]>([]);
  const [units, setUnits] = useState<IIdNamePair[]>([]);
  const [locations, setLocations] = useState<ICollectionCentre[]>([]);
  const [minQuantity, setMinQuantity] = useState<number | null>(null);
  const [quantityFieldDisabled, setQuantityFieldDisabled] = useState(false);

  const collectionCentres: IOption[] = locations
    .map((c) => ({
      id: c.id ?? "",
      label: c.name ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const inputsList: IOption[] = inputs
    .map((i) => ({
      id: i.id ?? "",
      label: i.name ?? "",
      units: i.units || []
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const [inputStock, setInputStock] = useState<IInputDistribution>({
    ...initialValues,
  });

  const handleInputChange = (inputId: string | null, setFieldValue: any) => {
    if (!inputId) {
      setUnits([])
      setFieldValue('inputName', { id: "", label: "", units: [], unitName: null })
    }

    const selectedInput = inputs?.find(input => input.id === inputId)

    if (selectedInput) {
      const sortedUnits: IIdNamePair[] = (selectedInput.units || []).map((unit: any) => ({
        id: unit.id,
        name: unit.label,
      })).sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name));

      setUnits(sortedUnits);
      setFieldValue('product', { ...selectedInput, units: sortedUnits });
    } else {
      setFieldValue('inputName', { id: "", label: "", units: [], unitName: null });

    }
  }

  useEffect(() => {
    handleFetchData();

    if (initialValues) {
      setInputStock({
        ...initialValues,
      });
    }
  }, [initialValues]);

  const handleFetchData = () => {
    if (remoteRoutes.onboardingService) {
      let url = "";

      let params: any = {
        pageSize: 100,
      };

      const hubId = currentUser?.hubId;

      if (hubId) {
        params = { hubId };

        url = buildUrl(remoteRoutes.onboardingService, "/collection/centres", params,
        ); get(
          url,
          (response: IApiResponse) => {
            const { data } = response;
            setLocations(data);
          },
          async (error) => {
          },
          () => {
            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        );

        url = buildUrl(remoteRoutes.onboardingService, "/inputs", params);
        get(
          url,
          (response: IApiResponse) => {
            const { data } = response;
            setInputs(data);
          },
          async (error) => { },
          () => {

            dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          },
        );
      }

    }

    if (initialValues?.id) {
      const url = buildUrl(
        remoteRoutes.inputsService,
        `/input/purchases/${initialValues?.id}/editable/min`,
      );

      get(
        url,
        (response: number) => {
          if (response === 0) {
            setMinQuantity(null);
          } else {
            setMinQuantity(response);
          }
        },
        async (error) => { },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
        },
      );
    }
  };

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const data: IInputDistribution = {
      ...values,
      date: values.date.toISOString(),
      inputName: values.inputName.label,
      inputId: values.inputName.id,
      unitName: values.unitName.name,
      unitId: values.unitName.id,
      collectionCentreName: values.collectionCentreName.label,
      collectionCentreId: values.collectionCentreName.id,
    };

    const url = buildUrl(
      remoteRoutes.inputsService,
      `/input/purchases/${values.id}`,
    );

    put(
      url,
      data,
      (response) => {
        CloseModal("edit-input-stock", () => {
          setSubmitting(false);
          resetForm();
        });

        dispatch({
          type: INPUTS_DISTRIBUTION_CONSTANTS.UPDATE_DATA,
          payload: data,
        });
        setModalKey(Date.now());
        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => { },
    );
  };

  return (
    <Formik
      initialValues={{
        ...inputStock,
        date: dayjs(inputStock?.date),
        inputName: inputStock.inputName
          ? {
            id: inputStock.inputId,
            label: inputStock.inputName,
            units: inputs?.find(input => input?.id === inputStock?.inputId)?.units?.map(unit => ({
              id: unit.id,
              name: unit.name,
            })) || [],
          }
          : null,
        unitName: (() => {
          const units = inputs?.find(input => input?.id === inputStock?.inputId)?.units?.map(unit => ({
            id: unit.id,
            name: unit.name,
          })) || [];
          return units.find(unit => unit.id === inputStock?.unitId) || null;
        })(),
        collectionCentreName: inputStock.collectionCentreName
          ? {
            id: inputStock.collectionCentreId,
            label: inputStock.collectionCentreName,
          }
          : null,
        quantity: inputStock.quantity || 0,
      }}
      validate={(values) => {
        const errors: any = {};
        if (minQuantity !== null && values.quantity < minQuantity) {
          errors.quantity = `${intl.formatMessage({ id: "ERROR.QUANTITY.MINIMUM", defaultMessage: `Edited amount cannot go below ${minQuantity}. This avoids negative stock` })}`;
        }
        return errors;
      }}
      onSubmit={handleSubmit}
      validationSchema={InputStockSchema}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue, touched, errors }) => {
        return (
          <ModalWrapper
            id="edit-input-stock"
            title={`${intl.formatMessage({ id: "FORM.TITLE.EDITINPUTSTOCKRECORD", defaultMessage: "Edit Input Stock Record" })}`}
            size={"lg"}
            submitting={isSubmitting}
            key={modalKey}
            handleSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XDatePicker}
                  label={`${intl.formatMessage({ id: "FORM.DATE", defaultMessage: "Date" })}`}
                  name="date"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={inputsList || []}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  values={values.inputName}
                  label={`${intl.formatMessage({ id: "FORM.INPUTNAME", defaultMessage: "Input Name" })}`}
                  name="inputName"
                  onChange={(selectedInput: any) => {
                    if (selectedInput && selectedInput.id) {
                      handleInputChange(selectedInput.id, setFieldValue);
                    } else {
                      setFieldValue('inputName', { id: "", label: "", units: [], unitName: null });
                      setUnits([]);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled={quantityFieldDisabled || isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.QUANTITY", defaultMessage: "Quantity" })}`}
                  name="quantity"
                />
              </div>
              <div className="col-lg-6">
                <Field name="unitName">
                  {({ field, form }: any) => (
                    <Autocomplete
                      getOptionLabel={(option: IIdNamePair) => option.name || ""}
                      options={values.inputName?.units || []}
                      value={field.value || null}
                      onChange={(event, value) => {
                        form.setFieldValue('unitName', value || null);
                        form.setFieldValue('unitId', value ? value.id : null);
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            !values.inputName?.id
                              ? "Units"
                              : (values.inputName?.units?.length === 0
                                ? "Input has no units"
                                : "Units")
                          }
                          variant="standard"
                          error={touched.inputName && Boolean(errors.inputName)}
                        />
                      )}
                    />
                  )}
                </Field>
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.UNITPRICE", defaultMessage: "Unit Price" })}`}
                  name="unitCost"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">UGX</InputAdornment>
                    ),
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const unitCost = Number(e.target.value);
                    setFieldValue("unitCost", unitCost);
                    setFieldValue(
                      "totalCost",
                      Number(values.quantity) * unitCost,
                    );
                  }}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.TOTALAMOUNTPAID", defaultMessage: "Total Amount Paid" })}`}
                  name="totalCost"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">UGX</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-lg-12">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={collectionCentres || []}
                  value={values.collectionCentreName}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  label={`${intl.formatMessage({ id: "FORM.COLLECTIONCENTRE", defaultMessage: "Collection Centre" })}`}
                  name="collectionCentreName"
                />
              </div>
            </div>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default EditInputStockForm;
