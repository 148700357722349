import React from 'react';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SummaryCardLoader} from "./loaders/SummaryCardLoader";

interface IProps {
    isLoading: boolean
    label: string
    value: string
    percentage?: string
    icon: IconDefinition
    iconColor?: string
    iconBgColor?: string
    bgColor?: string
    textColor?: string
}

function XSummaryCard({isLoading, bgColor, textColor, label, value, percentage, icon, iconColor, iconBgColor}: IProps) {
    return (

        <>
            {isLoading && <SummaryCardLoader/>}
            {!isLoading && <div style={{backgroundColor: bgColor ?? '#FFFFFF', color: textColor ?? '#000000'}}
                                className="card shadow-sm rounded-3 border-0 w-100">
                <div className="card-body">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                        <div>
                            <div className="">{label}</div>
                            <h1 className="fw-bolder">{value}</h1>
                            <div><small>{percentage}</small></div>
                        </div>
                        <div id="icon" style={{
                            width: 50,
                            height: 50,
                            backgroundColor: iconBgColor ?? "#DF901F",
                            borderRadius: '50%',
                        }}
                             className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon size={"2x"} style={{color: iconColor ?? "#FFFFFF"}} icon={icon}/>
                        </div>
                    </div>
                </div>
            </div>}
        </>

    );
}

export default XSummaryCard;