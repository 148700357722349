import * as Yup from "yup";
import { IntlShape } from "react-intl";

export interface IInput {
    id: string
    hubId: string
    name: string
    category: string
    units?: IdName[] | []
    description?: string
}

export interface IHubInput {
    id?: string
    hubId?: string
    name: string
    description?: string
    category: string
    categoryName?: string
    otherInputCategory?: string,
    units?: IdName[] | []
}

export interface IInputDto {
    id?: string
    hubId?: string
    name: string
    description?: string
    category: number
    otherInputCategory?: string,
    units?: IdName[]
}

export interface IdName {
    id: string
    name: string
}

export const InputSchema = (intl: IntlShape) => Yup.object({
    name: Yup.string()
        .nullable()
        .required(`${intl.formatMessage({
            id: "ERROR.FIELD.REQUIRED",
            defaultMessage: "Required",
        })}`),
    category: Yup.object()
        .required(`${intl.formatMessage({
            id: "ERROR.FIELD.REQUIRED",
            defaultMessage: "Required",
        })}`),
    hubId: Yup.string()
        .required(`${intl.formatMessage({
            id: "ERROR.FIELD.REQUIRED",
            defaultMessage: "Required",
        })}`),
    units: Yup.array()
        .of(
            Yup.object({
                id: Yup.string().required(`${intl.formatMessage({
                    id: "ERROR.FIELD.REQUIRED",
                    defaultMessage: "Required",
                })}`),
                name: Yup.string().required(`${intl.formatMessage({
                    id: "ERROR.FIELD.REQUIRED",
                    defaultMessage: "Required",
                })}`),
            })
        )
        .min(1, `${intl.formatMessage({
            id: "ERROR.FIELD.MIN_UNITS",
            defaultMessage: "At least one unit is required",
        })}`)
        .required(`${intl.formatMessage({
            id: "ERROR.FIELD.REQUIRED",
            defaultMessage: "Required",
        })}`),
});
