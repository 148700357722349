import { XSidebarWrapper } from "../../../components/shared/XSidebarWrapper";
import React, { useEffect, useState } from "react";
import { IHeaderButtonProps } from "../../../components/shared/SettingsPageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faTrash } from "@fortawesome/free-solid-svg-icons";
import { IUser } from "./interfaces/IUser";
import { FormattedMessage, useIntl } from "react-intl";
import { IPaginationMetadata } from "../../../interfaces/IApiResponse";
import DeleteUserForm from "./modals/DeleteUserForm";
import { authService } from "../../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../../hooks/roleChecker";
import { useSelector } from "react-redux";
import { IState } from "../../../../data/types";
import EditUserForm from "./modals/EditUserForm";

type Props = {
  close: () => void;
  show: boolean;
  user: IUser;
  pagination: IPaginationMetadata;
};

const UserDetails = ({ close, show, user, pagination }: Props) => {
  const intl = useIntl();
  const [loading, setLoading] = React.useState(true);

  const [roles, setRoles] = React.useState<string[]>([]);

  const userDetails = useSelector((state: IState) =>
    state.settings.users.data.find((u) => u.id === user.id),
  );

  const [localUser, setLocalUser] = useState(userDetails);

  useEffect(() => {
    setLocalUser(userDetails);
  }, [userDetails]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoading(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);
  const isMerchant = IsMerchant(roles);

  const actionButtons: IHeaderButtonProps[] = [];

  if (isGlobalAdmin || isHubAdmin || isHubManager || isMerchant) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
      cssClass: "btn btn-primary btn-sm me-1",
      dataTarget: "#edit-user",
      dataToggle: "modal",
    });
  }

  if (isGlobalAdmin || isHubAdmin) {
    actionButtons.push({
      cssClass: "btn btn-danger btn-sm",
      dataTarget: "#delete-user",
      dataToggle: "modal",
      deleteIcon: true,
    });
  }

  return (
    <>
      <XSidebarWrapper
        title={`${intl.formatMessage({ id: "DETAILS.TITLE.USERDETAILS", defaultMessage: "User Details" })}`}
        width={35}
        close={close}
        show={show}
      >
        <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
          <div className="py-3 px-4 rounded-1 bg-primary">
            <h2>
              {localUser?.firstName?.[0] || ""}
              {localUser?.lastName?.[0] || ""}
            </h2>
          </div>
          <div className="py-3 w-100">
            <h5>
              {localUser?.firstName} {localUser?.lastName}
            </h5>
            <>
              {localUser?.role}
            </>
            <div className="d-flex justify-content-between w-100">
              <div className="fs-6 small d-flex justify-content-between align-items-center gap-2">
                <FontAwesomeIcon icon={faPhone} />
                <span>{localUser?.phoneNumber}</span>
              </div>

              <div className="action-buttons">
                {actionButtons.map((button, index) => {
                  return (
                    <button
                      data-bs-toggle={button.dataToggle}
                      data-bs-target={button.dataTarget}
                      className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                      key={index}
                      onClick={button.onClick}
                    >
                      {button.deleteIcon && <FontAwesomeIcon icon={faTrash} />}
                      {button.processing
                        ? `${intl.formatMessage({
                          id: "LOADERS.PLEASEWAIT",
                          defaultMessage: "Please wait...",
                        })}`
                        : button.label}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 bg-gray rounded-3 mb-3">
          <div className="row">
            <div className="col-lg-12 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage id="DETAILS.EMAIL" defaultMessage="Email" />
              </strong>
              <br />
              <span>{localUser?.email}</span>
            </div>
            <div className="col-lg-12 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.USERNAME"
                  defaultMessage="Username"
                />
              </strong>
              <br />
              <span>{localUser?.userName}</span>
            </div>
          </div>
        </div>
      </XSidebarWrapper>

      {localUser && (
        <>
          <EditUserForm initialValues={localUser} />
          <DeleteUserForm
            id={localUser?.id}
            close={close}
            pagination={pagination}
          />
        </>
      )}
    </>
  );
};

export default UserDetails;
