import React, { useEffect, useRef, useState } from "react";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import { PageHeader } from "../../components/shared/PageHeader";
import XPagination from "../../components/shared/XPagination";
import { Modules } from "../../interfaces/Enums";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { remoteRoutes } from "../../../data/constants";
import {
  IInputsDistributionState,
  INPUTS_DISTRIBUTION_CONSTANTS,
} from "../../../data/redux/inputs/inputsReducer";
import {
  toastMessages,
  overrideToastDefaults,
} from "../../../data/toastDefaults";
import { IState } from "../../../data/types";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../interfaces/IApiResponse";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import { get } from "../../../utils/ajax";
import { buildUrl } from "../../../utils/queryBuilder";
import { useAuth } from "../auth";
import InputStockDetails from "./InputStockDetails";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";
import {
  faChevronDown,
  faShop,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { authService } from "../../../data/oidc/AuthService";
import { IHub } from "../settings/interfaces/IHub";
import MoveInputsForm from "./modals/MoveInputsForm";
import SellInputForm from "./modals/SellInputForm";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Loading } from "../../components/shared/Loading";

interface ICollectionCentre {
  id: string;
  name: string;
  location: string;
}
const InputStockLedger = () => {
  document.title = "Inputs > Stock";
  const intl = useIntl();

  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const [selectedRow, setSelectedRow] = useState<IInputDistribution | null>(
    null,
  );
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [filteredData, setFilteredData] = useState<IInputDistribution[]>([]);
  const [collectionCentres, setCollectionCentres] = useState<
    ICollectionCentre[]
  >([]);

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 15,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: 0,
    totalPages: 0,
  });

  const { data, loading }: IInputsDistributionState = useSelector(
    (state: IState) => state.inputs,
  );
  const navigate = useNavigate();

  const setLoading = (state: boolean) => {
    dispatch({
      type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [page, searchTerm]);

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    const hub: IHub | undefined = authService.getHub();

    if (remoteRoutes.profiles) {
      const HubId = currentUser?.hubId;
      const parentHubId = hub?.parentHubId;

      let params: any = { page, pageSize };

      if (parentHubId === null) {
        params = { HubId, page, pageSize };
      } else {
        params = { page, pageSize };
      }

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(
        remoteRoutes.onboardingService,
        "/collection/centres",
        params,
      );

      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          const centres = response.data
            .map(
              (item: { id: any; name: any; district: any }, index: number) => ({
                id: item.id,
                name: item.name,
                location: item.district,
              }),
            )
            .sort((a, b) => a.name.localeCompare(b.name));

          setCollectionCentres(centres);
          setFilteredData(centres);
          setPagination(paginationMetadata);

          dispatch({
            type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
            payload: data,
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      const term = searchInputRef.current?.value || "";
      setSearchTerm(term);
      setPage(1);
    }
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchTerm(value);
      setPage(1);
      handleFetchData(page, pagination.pageSize, value);
    }, 800),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleCollectionCentreClick = (collectionCentreId: any) => {
    navigate(`/inputs/stock/${collectionCentreId}`);
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        label: `${intl.formatMessage({ id: "BREADCRUMBS.INPUTS", defaultMessage: "Inputs" })}`,
        url: undefined,
      },
      {
        label: `${intl.formatMessage({ id: "BREADCRUMBS.STOCK", defaultMessage: "Stock" })}`,
        url: undefined,
      },
    ]);
  }, []);

  return (
    <>
      {loading && <PageHeaderLoader />}
      {!loading && (
        <>
          <PageHeader breadcrumbs={breadcrumbs}>
            <InnerMenu module={Modules.Inputs} />
          </PageHeader>
        </>
      )}

      <div className="card border-0 pt-3 px-3">
        <div className="row">
          <div className="col-6">
            <h6>All Collection Centres</h6>
          </div>

          <div className="col-6">
            <div className="d-flex align-items-center justify-content-end">
              <div className="input-group w-50">
                <input
                  type="text"
                  ref={searchInputRef}
                  onChange={handleSearchChange}
                  className="form-control"
                  placeholder={`${intl.formatMessage({ id: "TABLE.SEARCH", defaultMessage: "Type to search..." })}`}
                />
                <span className="input-group-text" id="addon-wrapping">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div
            className="loader d-flex justify-content-center align-items-center"
            style={{ height: 150 }}
          >
            <Loading />
          </div>
        ) : (
          <div className="row pt-3">
            {collectionCentres.map((item, index) => (
              <div
                className="col-12 col-md-6 col-lg-4 mb-3 clickable-row"
                key={index}
                onClick={() => handleCollectionCentreClick(item.id)}
              >
                <div className="d-flex align-items-center w-100 justify-content-between border-1 border border-gray-200 p-3 centre-card">
                  <div className="d-flex align-items-center justify-content-start">
                    <div className="d-flex justify-content-center align-items-center me-3 badge-icon">
                      <FontAwesomeIcon icon={faShop} className="text-green" />
                    </div>

                    <div className="d-flex flex-column">
                      <span className="fs-4 fw-bold">{item.name}</span>
                      <span className="text-small">{item.location}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <XPagination
          currentPage={page}
          pagination={pagination}
          dataLength={filteredData.length}
          setPage={setPage}
        />
      </div>

      <InputStockDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        selectedRow={selectedRow}
      />
    </>
  );
};

export default InputStockLedger;
