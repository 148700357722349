import React, { useEffect, useState } from "react";
import { IMenuItem } from "../../../utils/MainMenu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { InventoryInnerMenu } from "../../modules/inventory/InventoryInnerMenu";
import { Modules } from "../../interfaces/Enums";
import { ProfilesInnerMenu } from "../../modules/profiling/ProfilesInnerMenu";
import { InputsInnerMenu } from "../../modules/input-distribution/InputsInnerMenu";
import { FormattedMessage, useIntl } from "react-intl";
import { ProcessingInnerMenu } from "../../modules/processing/ProcessingInnerMenu";

type Props = {
  module: Modules;
};

export const InnerMenu = ({ module }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const activePage = location.pathname.toLowerCase();

  const [subMenuItems, setSubMenuItems] = useState<IMenuItem[] | undefined>([]);

  useEffect(() => {
    let items: IMenuItem[] = [];

    if (module === Modules.Inventory) {
      items = InventoryInnerMenu(intl, activePage);
    }

    if (module === Modules.Profiles) {
      items = ProfilesInnerMenu(intl, activePage);
    }

    if (module === Modules.Inputs) {
      items = InputsInnerMenu(intl, { activePage });
    }

    if (module === Modules.Processing) {
      items = ProcessingInnerMenu(intl, { activePage });
    }

    setSubMenuItems(items);
  }, [activePage]);

  return (
    <div className="w-100">
      <ul className="nav">
        {subMenuItems?.map((item, index) => (
          <li
            key={index}
            className={`nav-item ${item.isActive ? "active" : ""} mb-1 me-2`}
          >
            <button
              className={`nav-link`}
              style={{ color: item.isActive ? "white" : "black" }}
              aria-current="page"
              onClick={() => (item.path ? navigate(item.path) : "")}
            >
              {item.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
