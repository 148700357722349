import React, { useEffect, useState } from "react";
import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import { IProcessRun } from "./interfaces/IProcessRun";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import { FormattedMessage, useIntl } from "react-intl";
import { printProcessingStatus } from "../../../utils/enumHelpers";
import EditProcessRunForm from "./modals/EditProcessRunForm";
import DeleteProcessRun from "./modals/DeleteProcessRun";
import EndProcessForm from "./modals/EndProcessForm";
import { useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { IProcessRunState } from "../../../data/redux/processing/processRunsReducer";
import { printNaturalDateShort } from "../../../utils/dateHelpers";

type Props = {
    close: () => void;
    show: boolean;
    processRunId: any;
};

const ProcessRunDetails = ({ close, show, processRunId }: Props) => {

    const intl = useIntl();

    const { data, loading, pagination }: any = useSelector(
        (state: IState) => state.processRuns
    )

    const processRun = useSelector((state: IState) =>
        Array.isArray(state.processRuns.data)
            ? state.processRuns.data.find((pr) => pr.id === processRunId)
            : undefined,
    );

    const [localProcessRun, setLocalProcessRun] = useState(processRun)

    useEffect(() => {
        setLocalProcessRun(processRun)
    }, [processRun])

    const actionButtons: IHeaderButtonProps[] = [
        {
            cssClass: "btn btn-danger btn-sm",
            dataTarget: "#delete-process-run",
            dataToggle: "modal",
            deleteIcon: true,
        }
    ];

    if (localProcessRun?.status === 2) {
        const editButton = {
            label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
            cssClass: "btn btn-primary btn-sm me-1",
            dataTarget: "#edit-process-run",
            dataToggle: "modal",
        };

        actionButtons.splice(0, 0, editButton);
    }

      return (
        <>
            <XSidebarWrapper
                title={localProcessRun?.processName}
                width={50}
                close={close}
                show={show}
            >
                <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
                    {/* <div className="py-3 px-4 rounded-1 bg-primary">
                        <h2>
                            {processRunDetails?.name?.substring(0, 2).toUpperCase()}
                        </h2>
                    </div> */}
                    <div className="d-flex justify-content-between w-100 pb-2 row">
                        <div className="col-9">
                            <div className="mb-2">
                                {localProcessRun?.productName} - {localProcessRun?.inputVarietyName} - {localProcessRun?.batchNumber}
                            </div>
                            <div className="mb-2">
                                {printProcessingStatus(localProcessRun?.status)}
                            </div>
                            <div className="d-flex mb-2 row">
                                <div className="col">
                                    Conversion rate: <span className="badge bg-success">{localProcessRun?.conversionRate || "-"}</span>
                                </div>
                                <div className="col">
                                    Processing loss: <span className="badge bg-danger">{localProcessRun?.shrinkage || "-"}</span>
                                </div>
                            </div>
                        </div>

                        <div className="action-buttons mt-auto d-flex flex-wrap justify-content-end col">
                            {localProcessRun?.status === 1 ? (
                                <>
                                    <div
                                        className="btn btn-outline-primary btn-sm me-1"
                                        data-bs-toggle={"modal"}
                                        data-bs-target={"#end-process"}
                                    >
                                        <FormattedMessage id="BUTTONS.ENDPROCESS" defaultMessage={"End Process"} />
                                    </div>
                                </>
                            ) : null}
                            {actionButtons.map((button, index) => {
                                return (
                                    <button
                                        data-bs-toggle={button.dataToggle}
                                        data-bs-target={button.dataTarget}
                                        className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                                        key={index}
                                        onClick={button.onClick}
                                    >
                                        {button.deleteIcon && <FontAwesomeIcon icon={faTrash} />}
                                        {button.processing
                                            ? `${intl.formatMessage({
                                                id: "LOADERS.PLEASEWAIT",
                                                defaultMessage: "Please wait...",
                                            })}`
                                            : button.label}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">
                                <FormattedMessage
                                    id="DETAILS.PROCESSEDON"
                                    defaultMessage="Processed on"
                                />
                            </strong>
                            <br />
                            <span>{printNaturalDateShort(localProcessRun?.startTime) || "-"}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">
                                <FormattedMessage id="DETAILS.INITIALVARIETY" defaultMessage="Initial Variety" />
                            </strong>
                            <br />
                            <span>{localProcessRun?.inputVarietyName || "-"} ({Number(localProcessRun?.inputQuantity).toLocaleString()} {localProcessRun?.inputUnit})</span>
                        </div>
                        <div className="col-lg-6 col-12 ">
                            <strong className="small fw-semibold">
                                <FormattedMessage
                                    id="DETAILS.OUTCOMEVARIETY"
                                    defaultMessage="Outcome Variety"
                                />
                            </strong>
                            <br />
                            {localProcessRun?.outputVarietyName ? (
                                <span>
                                    {localProcessRun?.outputVarietyName} ({Number(localProcessRun?.outputQuantity).toLocaleString()} {localProcessRun?.outputUnit})
                                </span>
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                        <div className="col-lg-6 col-12">
                            <strong className="small fw-semibold">
                                <FormattedMessage
                                    id="DETAILS.DURATION"
                                    defaultMessage="Duration"
                                />
                            </strong>
                            <br />
                            <span>
                                {localProcessRun?.duration
                                    ? (() => {
                                        const duration = localProcessRun?.duration;
                                        if (isNaN(duration)) return "-";

                                        if (duration < 1) {
                                            const minutes = Math.round(duration * 60);
                                            return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
                                        } else if (duration >= 1) {
                                            return `${duration.toFixed(2)} ${duration === 1 ? "hour" : "hours"}`;
                                        } else {
                                            return "-";
                                        }
                                    })()
                                    : "-"}
                            </span>
                        </div>


                    </div>
                </div>

                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">
                                <FormattedMessage
                                    id="DETAILS.COMMENTS"
                                    defaultMessage="Comments"
                                />
                            </strong>
                            <br />
                            <span>{localProcessRun?.comment || "-"}</span>
                        </div>
                    </div>
                </div>
            </XSidebarWrapper>

            {localProcessRun && (
                <>
                    <EditProcessRunForm initialValues={localProcessRun} />
                    <DeleteProcessRun
                        item={localProcessRun}
                        close={close}
                    />
                    <EndProcessForm processRun={localProcessRun} />
                </>
            )}

        </>
    )
}

export default ProcessRunDetails