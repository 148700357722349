import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import React, { useEffect, useState } from "react";
import { remoteRoutes } from "../../../data/constants";
import { put } from "../../../utils/ajax";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../data/toastDefaults";
import { useSelector } from "react-redux";
import { useAuth } from "../auth";
import useFetchPurchases from "../../hooks/useFetchPurchases";
import { IState } from "../../../data/types";
import XTable from "../../components/shared/XTable";
import { useDynamicColumns } from "./Purchases";
import XPagination from "../../components/shared/XPagination";
import { XSearchFilter } from "../../components/shared/XSearchFilter";
import { printBatchStatus } from "../../../utils/enumHelpers";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import ExportBatchForm from "./modals/ExportBatchForm";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import EditBatchForm from "./modals/EditBatchForm";
import DeleteBatchForm from "./modals/DeleteBatchForm";
import { authService } from "../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../hooks/roleChecker";
import SendForProcessingForm from "./modals/SendForProcessingForm";
import SellBatchForm from "./modals/SellBatchForm";

type Props = {
  close: () => void;
  show: boolean;
  batchId: any;
};

export function BatchDetails({ close, show, batchId }: Props) {
  const intl = useIntl();

  const columns = useDynamicColumns();
  const { currentUser } = useAuth();
  const { fetchPurchases, pagination } = useFetchPurchases();

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);

  const { data, loading }: any = useSelector(
    (state: IState) => state.purchases,
  );

  const batch = useSelector((state: IState) =>
    Array.isArray(state.batches.data)
      ? state.batches.data.find((b) => b.id === batchId)
      : undefined,
  );

  const [batchDetails, setBatchDetails] = useState(batch);

  useEffect(() => {
    setBatchDetails(batch);
  }, [batch]);

  const [updatingStatus, setUpdatingStatus] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!batchDetails?.id) return;

    let params: any = {
      hubId: currentUser?.hubId,
      id: batchDetails.id,
    };

    if (searchTerm) params = { ...params, searchTerm };

    fetchPurchases(page, 7, params);
  }, [batchDetails, page, searchTerm]);

  const handleSearchPurchases = (event: any) => {
    const { value } = event.target;
    if (!value) setSearchTerm(undefined);
    else {
      if (value && event.keyCode === 13) {
        setSearchTerm(value);
      }
    }
  };

  const handleUpdateStatus = (status: any) => {
    setUpdatingStatus(true);

    const dateString = batch?.date;
    const dateObject = new Date(dateString);
    const isoStringDate = dateObject.toISOString();

    const updatedBatch = {
      ...batch,
      date: isoStringDate,
      currentStatus: status,
    };

    const url = remoteRoutes.inventoryService + "/batches/" + batch?.id;
    put(
      url,
      updatedBatch,
      (response) => {
        toast.success(toastMessages.default.success, overrideToastDefaults);
        setUpdatingStatus(false);
      },
      (error) => {
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => { },
    );
  };

  const actionButton: IHeaderButtonProps = {
    label: `${intl.formatMessage({ id: "BUTTONS.EXPORTBATCH", defaultMessage: "Export Batch" })}`,
    cssClass: "btn btn-primary btn-sm",
    dataTarget: "#export-batch",
    dataToggle: "modal",
  };

  const [loader, setLoader] = React.useState(true);
  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoader(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);
  const isMerchant = IsMerchant(roles);

  const status = printBatchStatus(batchDetails?.currentStatus);

  const actionButtons: IHeaderButtonProps[] = [
    {
      label: `${intl.formatMessage({ id: "BUTTONS.MARKASSSOLD", defaultMessage: "Export" })}`,
      cssClass: "dropdown-item",
      dataTarget: "#export-batch",
      dataToggle: "modal",
    }
  ];

  if (isGlobalAdmin || isHubAdmin || isHubManager && batch?.currentStatus !== 4) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.SENDFORPROCESSING", defaultMessage: "Send for Processing" })}`,
      cssClass: "dropdown-item",
      dataTarget: "#send-for-processing",
      dataToggle: "modal",
    });
  }

  const formattedData = data.map((row: { date: string }) => ({
    ...row,
    date: printNaturalDateShort(row.date),
  }));

  return (
    <>
      <XSidebarWrapper
        title={`${intl.formatMessage({ id: "DETAILS.TITLE.BATCHDETAILS", defaultMessage: "Batch Details" })}`}
        width={50}
        close={close}
        show={show}
      >
        <div className="d-flex justify-content-between w-100 align-items-baseline border-bottom mb-2">
          <div className="py-2">
            <h5>{batchDetails?.batchNumber?.toUpperCase() || ""}</h5>
            <div className="mb-2">
              {batchDetails?.productName} - {batchDetails?.variety}
            </div>

            <div className="d-flex align-items-center justify-content-start my-auto gap-1">
              <div>
                <span className="badge text-bg-purple-light d-inline">
                  {Number(batchDetails?.quantity).toLocaleString()}{" "}
                  {batchDetails?.unitName}
                </span>
              </div>
              <div>
                <span>{status}</span>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-around my-auto gap-1">
            {batchDetails?.currentStatus !== 6 &&
              batchDetails?.currentStatus !== 2 &&
              batchDetails?.currentStatus !== 4 &&
              batchDetails?.currentStatus !== 7 ? (
              <div className="btn-group" role="group">
                {(isGlobalAdmin || isHubAdmin || isMerchant) && (
                  <button
                    id="btnGroupDrop1"
                    type="button"
                    className={`btn btn-outline-primary dropdown-toggle ${updatingStatus ? "disabled" : ""}`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {updatingStatus
                      ? "Processing..."
                      : `${intl.formatMessage({ id: "BUTTONS.MANAGE", defaultMessage: "Manage" })}`}
                  </button>
                )}

                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                  {batchDetails?.currentStatus !== 2 && (
                    <>
                      <li>
                        <a
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#sell-batch"
                          href="#"
                        >
                          <FormattedMessage
                            id={"BUTTONS.SELLBATCH"}
                            defaultMessage={"Sell Batch"}
                          />
                        </a>
                      </li>
                      <li className="dropdown-divider"></li>
                      <li>
                        <a
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#move-batch"
                          href="#"
                        >
                          <FormattedMessage
                            id={"BUTTONS.MOVEBATCH"}
                            defaultMessage={"Move Batch"}
                          />
                        </a>
                      </li>

                      {(isHubAdmin || isGlobalAdmin) && showActionButtons &&
                        actionButtons.map((button, index) => {
                          return (
                            <>
                              <li className="dropdown-divider"></li>
                              <button
                                data-bs-toggle={button.dataToggle}
                                data-bs-target={button.dataTarget}
                                className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                                key={index}
                                onClick={button.onClick}
                              >
                                {button.processing
                                  ? `${intl.formatMessage({
                                    id: "LOADERS.PLEASEWAIT",
                                    defaultMessage: "Please wait...",
                                  })}`
                                  : button.label}
                              </button>
                            </>
                          );
                        })}
                    </>
                  )}
                </ul>
              </div>
            ) : (
              <span>{status}</span>
            )}
            {batchDetails?.currentStatus !== 2 &&
              batchDetails?.currentStatus !== 4 &&
              batchDetails?.currentStatus !== 6 &&
              batchDetails?.currentStatus !== 7 ? (
              <>
                {(isGlobalAdmin ||
                  isHubAdmin ||
                  isMerchant ||
                  isHubManager) && (
                    <div>
                      <button
                        className="btn btn-primary"
                        data-bs-toggle={"modal"}
                        data-bs-target={"#edit-batch"}
                      >
                        <FormattedMessage
                          id={"BUTTONS.EDIT"}
                          defaultMessage={"Edit"}
                        />
                      </button>
                    </div>
                  )}

                {(isGlobalAdmin || isHubAdmin || isMerchant) && (
                  <div>
                    <button
                      className="btn btn-danger"
                      data-bs-toggle={"modal"}
                      data-bs-target={"#delete-batch"}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className="scrollable-sidebar">
          <div className="p-3 bg-gray rounded-3 mb-2">
            <div className="row">
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.BATCHEDON"
                    defaultMessage="Batched on"
                  />
                </strong>
                <br />
                <span>
                  {printNaturalDateShort(new Date(batchDetails?.date))}
                </span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.BATCHEDFROM"
                    defaultMessage="Batched at"
                  />
                </strong>
                <br />
                <span>{batchDetails?.batchingCollectionCentreName}</span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.CURRENTLOCATION"
                    defaultMessage="Current Location"
                  />
                </strong>
                <br />
                <span>{batchDetails?.currentCollectionCentreName}</span>
              </div>
              <div className="col-lg-6 col-12 mb-2">
                <strong className="small fw-semibold">
                  <FormattedMessage
                    id="DETAILS.BATCHEDBY"
                    defaultMessage="Batched by"
                  />
                </strong>
                <br />
                <span>-</span>
              </div>
            </div>
          </div>
          {batchDetails?.description && (
            <div className="p-3 bg-gray rounded-3 mb-2">
              <div className="row">
                <div className="col-lg-12 col-12 mb-2">
                  <strong className="small fw-semibold">
                    <FormattedMessage
                      id="DETAILS.DESCRIPTION"
                      defaultMessage="Description"
                    />
                  </strong>
                  <br />
                  <span>{batchDetails?.description}</span>
                </div>
              </div>
            </div>
          )}

          <div className="rounded-3 mt-3">
            <h5>
              <FormattedMessage
                id={"TABLE.TITLE.PURCHASES"}
                defaultMessage={"Purchases"}
              />
            </h5>
            <XSearchFilter onKeyUp={(event) => handleSearchPurchases(event)} />
            <XTable
              checked={false}
              data={formattedData}
              columns={columns}
              loading={loading}
            />
            <XPagination
              dataLength={data.length}
              pagination={pagination}
              currentPage={page}
              setPage={(page) => setPage(page)}
            />
          </div>
        </div>
      </XSidebarWrapper>
      {batchDetails && (
        <>
          <ExportBatchForm initialValues={batchDetails} />
          <SellBatchForm selectedBatch={batchDetails} />
          <EditBatchForm initialValues={batchDetails} />
          <DeleteBatchForm
            item={batchDetails}
            close={close}
            pagination={pagination}
          />
          <SendForProcessingForm selectedBatch={batchDetails} />
        </>
      )}
    </>
  );
}
