import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { authService } from "../../../../data/oidc/AuthService";
import { IHub, IIdNamePair, IProduct } from "../../settings/interfaces/IHub";
import { Autocomplete, TextField } from "@mui/material";

interface SelectProductFormProps {
    onSelectId: (id: string | null | undefined, isVariety: boolean) => void;
}

const SelectProductForm: React.FC<SelectProductFormProps> = ({ onSelectId }) => {
    const [modalKey, setModalKey] = useState(Date.now());
    const [products, setProducts] = useState<any[] | undefined>(undefined);
    const [varieties, setVarieties] = useState<IIdNamePair[] | undefined>(undefined);

    const initialValues = {
        product: {
            id: "",
            label: "",
            varieties: [] as IIdNamePair[],
            variety: null as IIdNamePair | null,
        },
    }

    const hub: IHub | undefined = authService.getHub();


    useEffect(() => {
        if (hub) {
            const { products } = hub;
            const sortedProducts = products?.map((product: IProduct) => ({
                id: product.id,
                label: product.name,
                varieties: product.varieties || [],
            })).sort((a, b) => a.label.localeCompare(b.label));

            setProducts(sortedProducts || []);
        }
    }, []);

    const handleProductChange = (productId: string | null, setFieldValue: any) => {

        if (!productId) {
            setVarieties([]);
            setFieldValue('product', { id: "", label: "", varieties: [], variety: null });
            return;
        }

        const selectedProduct = products?.find(product => product.id === productId);

        if (selectedProduct) {
            const sortedVarieties: IIdNamePair[] = (selectedProduct.varieties || []).map((variety: any) => ({
                id: variety.id,
                label: variety.label,
            })).sort((a: { label: string; }, b: { label: any; }) => a.label.localeCompare(b.label));

            setVarieties(sortedVarieties);
            setFieldValue('product', { ...selectedProduct, varieties: sortedVarieties });
        } else {
            console.error("Selected product not found in the list");
        }
    };

    const handleSubmit = (values: any, { setSubmitting }: any) => {
        const selectedProductId = values.product?.id || null;
        const selectedVarietyId = values.product?.variety?.id || null;

        if (selectedVarietyId) {
            onSelectId(selectedVarietyId, true);
        } else if (selectedProductId) {
            onSelectId(selectedProductId, false);
        }

        CloseModal("select-product", () => {
            setSubmitting(false);
        });
    };

    const isSubmitDisabled = (values: any) => {
        const selectedProduct = values.product;
        if (!selectedProduct?.id) return true;
        if (selectedProduct.varieties.length > 0 && !selectedProduct.variety) return true;
        return false;
    };

    const handleClearForm = (resetForm: () => void) => {
        onSelectId(undefined, false)

        resetForm();

        CloseModal("select-product");
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, isSubmitting, resetForm, values, setFieldValue, touched, errors }) => {
                    return (
                        <ModalWrapper
                            id="select-product"
                            title="Select product to batch"
                            size="sm"
                            key={modalKey}
                            submitting={isSubmitting}
                            handleSubmit={handleSubmit}
                            disabled={isSubmitDisabled(values)}
                            isResetAction
                            handleReset={() => handleClearForm(resetForm)}
                        >
                            <div className="row">
                                <div className="col-lg-12">
                                    <Field
                                        type={"string"}
                                        component={XAutoComplete}
                                        name="product"
                                        label="Product"
                                        options={products}
                                        onChange={(selectedProduct: any) => {
                                            if (selectedProduct && selectedProduct.id) {
                                                handleProductChange(selectedProduct.id, setFieldValue);
                                            } else {
                                                setFieldValue('product', { id: "", label: "", varieties: [], variety: null });
                                                setVarieties([]);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <Field name="variety">
                                        {() => (
                                            <Autocomplete
                                                getOptionLabel={(option: IIdNamePair) => option.name || ""}
                                                options={values.product?.varieties || []}
                                                onChange={(event, value) => {
                                                    setFieldValue('product.variety', value || null);
                                                }}
                                                disabled={!values.product?.id || (values.product?.varieties?.length === 0)}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>
                                                        {option.name}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={
                                                            !values.product?.id
                                                                ? "Variety"
                                                                : (values.product?.varieties?.length === 0
                                                                    ? "Product has no varieties"
                                                                    : "Variety")
                                                        }
                                                        variant="standard"
                                                        error={touched.product && Boolean(errors.product)}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </ModalWrapper>
                    )
                }}
            </Formik>
        </>
    );
}

export default SelectProductForm