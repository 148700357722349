import React from "react";
import Map from "../../shared/Map"

interface PolygonMapProps {
    coordinates: { latitude: number; longitude: number }[];
}

const PolygonMapComponent = ({coordinates}: PolygonMapProps) => {
    if (coordinates.length === 0) return <div className={"text-center justify-content-center"}>No Polygon Data</div>;

    const paths = coordinates.map(coord => ({
        lat: coord.latitude,
        lng: coord.longitude,
    }));

    return (
        <Map height={"300px"} width={"100%"} zoom={14} polygons={[paths]}/>
    );
};

export default PolygonMapComponent;
