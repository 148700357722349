import { XSidebarWrapper } from "../../../components/shared/XSidebarWrapper";
import React, { useEffect, useState } from "react";
import { IHeaderButtonProps } from "../../../components/shared/SettingsPageHeader";
import { LoadScript } from "@react-google-maps/api";
import { env } from "../../../../data/constants";
import MapComponent from "../../../components/shared/MapComponent";
import EditCollectionCentreForm from "./modals/EditCollectionCentreForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteCollectionCentreForm from "./modals/DeleteCollectionCentreForm";
import { useSelector } from "react-redux";
import { IState } from "../../../../data/types";
import { FormattedMessage, useIntl } from "react-intl";
import { ISettingsState } from "../../../../data/redux/settings/settingsReducer";
import { authService } from "../../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../../hooks/roleChecker";

type Props = {
  close: () => void;
  show: boolean;
  collectionCentreId: any;
};

const CostCentreDetails = ({ close, show, collectionCentreId }: Props) => {
  const key = env.googleMapsApiKey ?? "";
  const intl = useIntl();
  const collectionCentre = useSelector((state: IState) =>
    state.settings.collectionCentres.data.find(
      (cc) => cc.id === collectionCentreId,
    ),
  );

  const { collectionCentres }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );
  const { pagination } = collectionCentres;

  const [localCollectionCentre, setLocalCollectionCentre] =
    useState(collectionCentre);

  useEffect(() => {
    setLocalCollectionCentre(collectionCentre);
  }, [collectionCentre]);

  const actionButtons: IHeaderButtonProps[] = [];

  const [loading, setLoading] = React.useState(true);

  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoading(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);
  const isMerchant = IsMerchant(roles);

  if (isGlobalAdmin || isHubAdmin || isMerchant || isHubManager) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
      cssClass: "btn btn-primary btn-sm me-1",
      dataTarget: "#edit-collection-centre",
      dataToggle: "modal",
    });
  }

  if (isGlobalAdmin || isHubAdmin) {
    actionButtons.push({
      // label: "Delete",
      cssClass: "btn btn-danger btn-sm",
      dataTarget: "#delete-collection-centre",
      dataToggle: "modal",
      deleteIcon: true,
    });
  }

  return (
    <>
      <XSidebarWrapper
        title={intl.formatMessage({
          id: "DETAILS.TITLE.COLLECTIONCENTREDETAILS",
          defaultMessage: "Collection Centre Details",
        })}
        width={35}
        close={close}
        show={show}
      >
        <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
          <div className="py-3 px-4 rounded-1 bg-primary">
            <h2>
              {localCollectionCentre?.name?.substring(0, 2).toUpperCase()}
            </h2>
          </div>
          <div className="py-3 w-100">
            <h5>{localCollectionCentre?.name}</h5>
            <div className="d-flex justify-content-between w-100">
              <div className="fs-6 small">
                <span className="badge text-bg-danger">
                  {collectionCentre?.district}
                </span>
                <span className="badge badge-danger">37 farmers</span>
              </div>

              <div className="action-buttons">
                {actionButtons.map((button, index) => {
                  return (
                    <button
                      data-bs-toggle={button.dataToggle}
                      data-bs-target={button.dataTarget}
                      className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                      key={index}
                      onClick={button.onClick}
                    >
                      {button.deleteIcon && <FontAwesomeIcon icon={faTrash} />}
                      {button.processing
                        ? `${intl.formatMessage({
                            id: "LOADERS.PLEASEWAIT",
                            defaultMessage: "Please wait...",
                          })}`
                        : button.label}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 bg-gray rounded-3 mb-3">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.SUBCOUNTY"
                  defaultMessage="Sub county"
                />
              </strong>
              <br />
              <span>{collectionCentre?.subCounty}</span>
            </div>

            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage id="DETAILS.PARISH" defaultMessage="Parish" />
              </strong>
              <br />
              <span>{localCollectionCentre?.parish}</span>
            </div>

            <div className="col-lg-6 col-12 ">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.VILLAGE"
                  defaultMessage="Village"
                />
              </strong>
              <br />
              <span>{localCollectionCentre?.village}</span>
            </div>
          </div>
        </div>

        <div className="bg-gray border-1 rounded-2">
          <LoadScript googleMapsApiKey={key}>
            <MapComponent
              lat={localCollectionCentre?.latitude ?? 0}
              lng={localCollectionCentre?.longitude ?? 0}
            />
          </LoadScript>
        </div>
      </XSidebarWrapper>

      {localCollectionCentre && (
        <>
          <EditCollectionCentreForm initialValues={localCollectionCentre} />
          <DeleteCollectionCentreForm
            item={localCollectionCentre}
            close={close}
            pagination={pagination}
          />
        </>
      )}
    </>
  );
};

export default CostCentreDetails;
