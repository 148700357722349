import { Formik, Field, ErrorMessage } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useAuth } from "../../auth";
import React, { useEffect, useState } from "react";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import { IInputDistribution } from "../interfaces/IInputDistribution";
import { get, post } from "../../../../utils/ajax";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import toast from "react-hot-toast";
import {
    overrideToastDefaults,
    toastMessages,
} from "../../../../data/toastDefaults";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import {
    ICollectionCentre,
    IInput,
    IIdNamePair,
} from "../../settings/interfaces/IHub";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import {
    IOption,
    XAutoComplete,
} from "../../../components/shared/forms/XAutoComplete";
import { InputStockSchema } from "../schemas/InputStockSchema";
import { useIntl } from "react-intl";

const AddStockForm = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());
    const [inputs, setInputs] = useState<IInput[]>([]);
    const [units, setUnits] = useState<IIdNamePair[]>([]);
    const [locations, setLocations] = useState<ICollectionCentre[]>([]);

    const collectionCentres: IOption[] = locations
        .map((c) => ({
            id: c.id ?? "",
            label: c.name ?? "",
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const hubInputs: any[] = inputs
        .map((i: IInput) => ({
            id: i.id ?? "",
            label: i.name ?? "",
            units: i.units || []
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const initialValues = {
        inputName: {
            id: null,
            label: null,
            units: [] as IIdNamePair[],
            unitName: null as IIdNamePair | null,
        },
        unitName: null,
        quantity: "",
        unitCost: "",
        collectionCentreName: null,
        date: null,
    };

    const handleInputChange = (inputId: string | null, setFieldValue: any) => {
        if (!inputId) {
            setUnits([])
            setFieldValue('inputName', { id: "", label: "", units: [], unitName: null })
        }

        const selectedInput = inputs?.find(input => input.id === inputId)

        if (selectedInput) {
            const sortedUnits: IIdNamePair[] = (selectedInput.units || []).map((unit: any) => ({
                id: unit.id,
                name: unit.label,
            })).sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name));

            setUnits(sortedUnits);
            setFieldValue('inputName', { ...selectedInput, units: sortedUnits });
        } else {
            setFieldValue('inputName', { id: "", label: "", units: [], unitName: null });

        }
    }

    useEffect(() => {
        handleFetchData();
    }, []);

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            let url = "";

            const hubId = currentUser?.hubId;

            if (hubId) {
                const params = { hubId };

                url = buildUrl(remoteRoutes.onboardingService, "/collection/centres", params,
                ); get(
                    url,
                    (response: IApiResponse) => {
                        const { data } = response;
                        setLocations(data);
                    },
                    async (error) => {
                    },
                    () => {
                        dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                    },
                );

                url = buildUrl(remoteRoutes.onboardingService, "/inputs", params);
                get(
                    url,
                    (response: IApiResponse) => {
                        const { data } = response;
                        setInputs(data);
                    },
                    async (error) => { },
                    () => {

                        dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                    },
                );
            }
        }
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.inputsService, `/input/purchases`);

        const data: IInputDistribution = {
            hubId: currentUser?.hubId ?? null,
            date: values.date.toISOString(),
            inputName: values.inputName.label,
            inputId: values.inputName.id,
            collectionCentreId: values.collectionCentreName.id,
            collectionCentreName: values.collectionCentreName.label,
            unitName: values.unitName?.name || null,
            unitId: values.unitId || null,
            unitCost: Number(values.unitCost),
            totalCost: Number(values.unitCost) * Number(values.quantity),
            quantity: Number(values.quantity),
        };

        post(
            url,
            data,
            (response) => {
                CloseModal("add-stock", () => {
                    setSubmitting(false);
                    resetForm();
                });
                dispatch({
                    type: INPUTS_DISTRIBUTION_CONSTANTS.POST_DATA,
                    payload: data,
                });
                setModalKey(Date.now());
                toast.success(toastMessages.default.success, overrideToastDefaults);
            },
            (error) => {
                setSubmitting(false);
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => {
            },
        );
    };

    return (
        <Formik
            initialValues={{
                ...initialValues,
                inputName: { id: null, label: null, units: [] },
                collectionCentreName: null,
                unitName: null,
                unitId: null,
                unitCost: "",
                quantity: "",
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={InputStockSchema}
        >
            {({ handleSubmit, isSubmitting, setFieldValue, values, touched, errors }) => {
                return (
                    <ModalWrapper
                        id="add-stock"
                        title={`${intl.formatMessage({ id: "FORM.TITLE.RECORDINPUTSTOCK", defaultMessage: "Record Input Stock" })}`}
                        size={"lg"}
                        submitting={isSubmitting}
                        key={modalKey}
                        handleSubmit={handleSubmit}
                    >
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XDatePicker}
                                    label={`${intl.formatMessage({ id: "FORM.DATE", defaultMessage: "Date" })}`}
                                    name="date"
                                    maxDate={dayjs(new Date())}
                                    value={values.date}
                                />
                                <ErrorMessage
                                    name="date"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={hubInputs || []}
                                    getOptionLabel={(option: any) => (option ? option.label : "")}
                                    values={values.inputName?.label || ""}
                                    label={`${intl.formatMessage({ id: "FORM.INPUTNAME", defaultMessage: "Input Name" })}`}
                                    name="inputName"
                                    onChange={(event: any, selectedInput: any) => {
                                        if (selectedInput && selectedInput.id) {
                                            setFieldValue("inputName", selectedInput);
                                            handleInputChange(selectedInput.id, setFieldValue);
                                        } else {
                                            setFieldValue("inputName", { id: "", label: "", units: [] });
                                            setFieldValue("unitName", null);
                                            setFieldValue("unitId", null);
                                            setUnits([]);
                                        }
                                    }}
                                />
                                <ErrorMessage
                                    name="inputName"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.QUANTITY", defaultMessage: "Quantity" })}`}
                                    name="quantity"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field name="unitName">
                                    {({ field, form }: any) => (
                                        <Autocomplete
                                            getOptionLabel={(option: IIdNamePair) => option.name || ""}
                                            options={values.inputName?.units || []}
                                            value={field.value || null}
                                            onChange={(event, value) => {
                                                form.setFieldValue('unitName', value || null);
                                                form.setFieldValue('unitId', value ? value.id : null);
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={
                                                        !values.inputName?.id
                                                            ? "Inputs Unit"
                                                            : (values.inputName?.units?.length === 0
                                                                ? "Input has no units"
                                                                : "Inputs Unit")
                                                    }
                                                    variant="standard"
                                                    error={touched.inputName && Boolean(errors.inputName)}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.UNITPRICE", defaultMessage: "Unit Price" })}`}
                                    name="unitCost"
                                    inputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">UGX</InputAdornment>
                                        ),
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const unitCost = Number(e.target.value);
                                        setFieldValue("unitCost", unitCost);
                                        setFieldValue(
                                            "totalCost",
                                            Number(values.quantity) * unitCost,
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.TOTALAMOUNTPAID", defaultMessage: "Total Amount Paid" })}`}
                                    name="totalCost"
                                    inputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">UGX</InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-lg-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={collectionCentres || []}
                                    value={values.collectionCentreName}
                                    getOptionLabel={(option: any) => (option ? option.label : "")}
                                    label={`${intl.formatMessage({ id: "FORM.COLLECTIONCENTRE", defaultMessage: "Collection Centre" })}`}
                                    name="collectionCentreName"
                                />
                            </div>
                        </div>
                    </ModalWrapper>
                );
            }}
        </Formik>
    );
};

export default AddStockForm;
