import * as Yup from "yup";
import {IntlShape} from "react-intl";

export const MoveBatchSchema = (intl: IntlShape) => Yup.object({
    typeOfMovement: Yup.string().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
    destinationCollectionCentre: Yup.object().nullable().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
})

export const CombineBatchesSchema = (intl: IntlShape) => Yup.object({
    batchNumber: Yup.string().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`)
})