import React, { useState } from 'react'
import { IPaginationMetadata } from "../../../interfaces/IApiResponse";
import { useDispatch } from "react-redux";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import { del } from "../../../../utils/ajax";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import { INVENTORY_PURCHASES_CONSTANTS } from "../../../../data/redux/inventory/purchasesReducer";
import { IPurchase } from "../interfaces/IPurchase";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { Formik } from "formik";

type Props = {
    id: string | undefined | null
    pagination: IPaginationMetadata
    close: () => void;
}

const DeletePurchaseForm = ({ id, pagination, close }: Props) => {

    const dispatch = useDispatch()

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues: IPurchase = {
        agentId: "",
        agentName: "",
        batchId: null,
        date: "",
        farmCode: "",
        farmCompliance: { farmId: "", farmPolygonDrawing: [], status: "" },
        farmerId: "",
        farmerName: "",
        hubId: "",
        id: "",
        productId: "",
        productName: "",
        quantity: 0,
        receiptId: "",
        season: 0,
        totalPrice: 0,
        unitId: "",
        unitName: "",
        unitPrice: 0,
        varietyId: "",
        varietyName: ""
    }

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.inventoryService, `/purchases/${id}`)

        del(url, (response) => {

            dispatch({
                type: INVENTORY_PURCHASES_CONSTANTS.DELETE_PURCHASE,
                payload: {
                    id, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems - 1,
                    }
                }
            })

            CloseModal("delete-purchase", () => {
                setSubmitting(false);
                resetForm();
            });

            close();

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id="delete-purchase"
                    title="Delete Purchase"
                    size="md"
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    key={modalKey}
                    handleSubmit={handleSubmit}>
                    <p>Are you sure you want to delete this purchase? This action cannot be undone.</p>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default DeletePurchaseForm