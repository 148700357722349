import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { Field, Formik, FieldArray, Form } from "formik";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { post } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { useAuth } from "../../../auth";
import { useDispatch } from "react-redux";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { IInputCategory } from "../../interfaces/IHub";
import { useEffect, useState, useRef } from "react";
import { SystemProductSchema } from "../../products/interfaces/ISystemProduct";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import {IPaginationMetadata} from "../../../../interfaces/IApiResponse";

type Props = {
    pagination: IPaginationMetadata
}

const CreateSystemProduct = ({pagination}: Props) => {
    const auth = useAuth();
    const dispatch = useDispatch();

    const [modalKey, setModalKey] = useState(Date.now());
    const newVarietyRef = useRef<HTMLInputElement | null>(null);

    const handleAddVariety = (push: (arg0: string) => void) => {
        push('');
        setTimeout(() => {
            newVarietyRef.current?.focus();
        }, 0);
    };

    const initialValues = {
        name: null,
        category: null,
        otherProductCategory: null,
        scientificName: null,
        varieties: [''],
    }

    const productCategories = auth.categories?.productCategories;
    const categories = productCategories?.map((category: IInputCategory) => ({
        label: category.name,
        id: category.number
    }));

    useEffect(() => {
        // handleFetchData()
    }, []);

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.onboardingService, '/products');
        const data = {
            name: values.name,
            scientificName: values.scientificName,
            brand: values.brand ?? null,
            category: values.category?.id ?? null,
            varieties: values.varieties,
        }

        post(url, data, (response) => {
            CloseModal("new-system-product", () => {
                setSubmitting(false);
                resetForm();
            });

            dispatch({
                type: SETTINGS_CONSTANTS.POST_PRODUCTS,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            });

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={SystemProductSchema}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                    <ModalWrapper
                        id="new-system-product"
                        title="Create a New Product"
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}
                    >
                        <Form>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Field
                                        type={"string"}
                                        disabled={isSubmitting}
                                        as={XTextField}
                                        label="Name"
                                        name="name"
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <Field
                                        type={"string"}
                                        disabled={isSubmitting}
                                        as={XTextField}
                                        label="Scientific Name"
                                        name="scientificName"
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <Field
                                        type={"string"}
                                        disabled={isSubmitting}
                                        as={XTextField}
                                        label="Brand"
                                        name="brand"
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <Field
                                        disabled={isSubmitting}
                                        component={XAutoComplete}
                                        label="Category"
                                        options={categories}
                                        name="category"
                                        onChange={(event: any, value: { label: string, id: number }) => setFieldValue('category', value)}
                                        value={values.category}
                                    />
                                </div>

                                {values.category && (values.category as any).label === 'Others' && (
                                    <div className="col-lg-12">
                                        <Field
                                            type={"string"}
                                            disabled={isSubmitting}
                                            as={XTextField}
                                            label="Other Product Category"
                                            name="otherProductCategory"
                                        />
                                    </div>
                                )}

                                <FieldArray name="varieties">
                                    {({ push, remove }) => (
                                        <>
                                            {values.varieties.map((variety: string, index: number) => (
                                                <div key={index} className="col-lg-6">
                                                    <div className="row">
                                                        <div className="col-lg-11">
                                                            <Field
                                                                type="string"
                                                                disabled={isSubmitting}
                                                                as={XTextField}
                                                                label={`Variety ${index + 1}`}
                                                                name={`varieties[${index}]`}
                                                                inputRef={index === values.varieties.length - 1 ? newVarietyRef : null}
                                                            />
                                                        </div>

                                                        {index > 0 && (
                                                            <div
                                                                className="col-lg-1 align-content-center"
                                                                style={{ cursor: "pointer", color: "grey", marginLeft: -20 }}
                                                                onClick={() => remove(index)}
                                                            >
                                                                <FontAwesomeIcon icon={faCircleMinus} color="#FF0000" size="lg" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            {values.varieties[values.varieties.length - 1]?.trim() !== "" && (
                                                <div
                                                    style={{ cursor: "pointer", color: "grey" }}
                                                    onClick={() => handleAddVariety(push)}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} color="#DF901F" size="lg" />
                                                    <span className="mx-2 align-items-center">Add another variety</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </Form>
                    </ModalWrapper>
                )}
            </Formik>
        </>
    );
}

export default CreateSystemProduct;
