import {Formik} from "formik";
import toast from "react-hot-toast";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import {del} from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { IProcess } from "../../interfaces/IHub";
import { useIntl } from "react-intl";
import {IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";

interface Props {
  item: IProcess;
  pagination: IPaginationMetadata
  close: () => void;
}

const DeleteProcessForm = ({item, pagination, close}: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const [modalKey, setModalKey] = useState(Date.now());

  const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
    const url = buildUrl(
      remoteRoutes.processingService,
      `/processes/${item.id}`,
    );

    const id = item.id

    del(
      url,
      (response) => {

        dispatch({
          type: SETTINGS_CONSTANTS.DELETE_PROCESS,
          payload: {
            id, pagination: {
              ...pagination,
              totalItems: pagination?.totalItems - 1,
            }
          }
        })

        CloseModal("delete-process", () => {
          setSubmitting(false);
          resetForm();
        });

        close();
        toast.success(toastMessages.default.success, overrideToastDefaults);
        setModalKey(Date.now());
      },
      (error) => {
        console.log(error);
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  return (
    <Formik
      initialValues={item}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      key={modalKey}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id="delete-process"
            title={`${intl.formatMessage({ id: "FORM.TITLE.DELETEPROCESS", defaultMessage: "Delete Process" })}`}
            size="md"
            isDeleteAction={true}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>
              Are you sure you want to delete this process? This action cannot
              be undone.
            </p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteProcessForm;
