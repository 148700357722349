import {
  IButtonProps,
  PageHeader,
} from "../../../components/shared/PageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import XTable, { ITableColumn } from "../../../components/shared/XTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import React, { useCallback, useEffect, useState } from "react";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import {
  HUBS_CONSTANTS,
  IHubState,
} from "../../../../data/redux/settings/hubsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { IHub } from "../../settings/interfaces/IHub";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faFilter,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import CreateHubForm from "./modals/CreateHubForm";
import { authService } from "../../../../data/oidc/AuthService";
import { IBreadcrumb } from "../../../components/shared/Breadcrumbs";
import debounce from "lodash.debounce";
import { FormattedMessage, useIntl } from "react-intl";
import {
  IsAgent,
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../../hooks/roleChecker";

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.NAME",
    label: "Name",
    id: "name",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.TELEPHONE",
    label: "Phone Number",
    id: "phoneNumber",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.DISTRICT",
    label: "District",
    id: "district",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.SUBCOUNTY",
    label: "SubCounty",
    id: "subCounty",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.VILLAGE",
    label: "Village",
    id: "village",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];

type Props = {
  hubId?: string;
  title?: string;
};

const Hubs = ({ hubId, title }: Props) => {
  const dispatch = useDispatch();
  const hub: IHub | any = authService.getHub();
  const navigate = useNavigate();

  const intl = useIntl();

  const { currentUser } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [selected, setSelected] = useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [childHubs, setChildHubs] = useState<IHub[]>([]);

  const { data, loading, pagination }: IHubState = useSelector(
    (state: IState) => state.hubs,
  );
  const mau: string | undefined =
    hub.minimumAggregationUnit !== ""
      ? hub.minimumAggregationUnit
      : hub.minimumAggregationUnit;

  document.title = `${mau}`;

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [actionButtons, setActionButtons] = useState<IButtonProps[]>([]);

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: HUBS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  const roles = authService.getRoles();
  const isHubAdmin = IsHubAdmin(roles);
  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isMerchant = IsMerchant(roles);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination?.pageSize, searchTerm);

    setBreadcrumbs([{ label: mau?.toUpperCase() ?? "Hubs", url: undefined }]);

    setActionButtons([
      {
        label: `${intl.formatMessage({ id: "BUTTONS.ADDNEW", defaultMessage: "Add new" })}`,
        cssClass: "btn btn-primary btn-sm",
        dataTarget: "#new-hub",
        dataToggle: "modal",
      },
    ]);
  }, [page, searchTerm, hubId]);

  useEffect(() => {
    setChildHubs(data);
  }, [data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.onboardingService) {
      const parentHubId = hubId ?? currentUser?.hubId;
      let params: any = { parentHubId, page, pageSize };

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(remoteRoutes.onboardingService, "/hubs", params);
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          dispatch({
            type: HUBS_CONSTANTS.FETCH_DATA,
            payload: { data, pagination: paginationMetadata },
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: HUBS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );

      // //Fetech MAU
      // const NewId = urlParams.hubId ?? currentUser?.hubId;
      // const hubUrl = buildUrl(remoteRoutes.onboardingService, `/hubs/${NewId}`)
      // get(hubUrl,
      //     (response: IHub) => {
      //         setHubDetails(response)
      //
      //         dispatch({
      //             type: HUBS_CONSTANTS.GET_DETAILS,
      //             payload: response
      //         })
      //     },
      //     () => {
      //     },
      //     () => dispatch({type: HUBS_CONSTANTS.STOP_FETCH}))
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: IHub) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: IHub) => {
    navigate(`/hubs/${item?.id}`);
  };

  return (
    <>
      {title && <PageHeader title={title} />}
      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-25">
            <input
              type="text"
              onChange={handleSearchChange}
              // onKeyUp={(event) => handleSearch(event)}
              className="form-control"
              placeholder={intl.formatMessage({
                id: "TABLE.SEARCH",
                defaultMessage: "Type to search...",
              })}
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="btn btn-sm btn-outline-secondary me-2"*/}
            {/*>*/}
            {/*  <FontAwesomeIcon icon={faFilter} className="text-orange" />*/}
            {/*  <span className="ms-2">*/}
            {/*    <FormattedMessage*/}
            {/*      id={"BUTTONS.FILTERS"}*/}
            {/*      defaultMessage={"Filters"}*/}
            {/*    />*/}
            {/*  </span>*/}
            {/*</button>*/}
            {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">*/}
            {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
            {/*</button>*/}

            {showActionButtons &&
              actionButtons.map((button, index) => {
                return (
                  <>
                    {(isHubAdmin || isGlobalAdmin || isMerchant) && (
                      <button
                        data-bs-toggle={button.dataToggle}
                        data-bs-target={button.dataTarget}
                        className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                        key={index}
                        onClick={button.onClick}
                      >
                        {button.processing
                          ? `${intl.formatMessage({
                              id: "LOADERS.PLEASEWAIT",
                              defaultMessage: "Please wait...",
                            })}`
                          : button.label}
                      </button>
                    )}
                  </>
                );
              })}
          </div>
        </div>
        {/*<XTable selected={[]} data={data} columns={columns} loading={loading}/>*/}

        <XTable
          checked={false}
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={data}
          columns={columns}
          loading={loading}
        />

        <XPagination
          dataLength={data?.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <CreateHubForm hubId={hubId} pagination={pagination} />
    </>
  );
};

export default Hubs;
