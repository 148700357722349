import * as Yup from "yup";
import {IntlShape} from "react-intl";

export const ProcessingSchema = (intl: IntlShape) => Yup.object({
    outputVarietyName: Yup.object().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
    outputUnit: Yup.object().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
    outputQuantity: Yup.string().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
})
