import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { IOption } from "../../../../components/shared/forms/XAutoComplete";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { IHub, ICollectionCentre } from "../../interfaces/IHub";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { get, post } from "../../../../../utils/ajax";
import { IApiResponse, IPaginationMetadata } from "../../../../interfaces/IApiResponse";
import { useDispatch } from "react-redux";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import toast from "react-hot-toast";
import { toastMessages, overrideToastDefaults } from "../../../../../data/toastDefaults";
import { useAuth } from "../../../auth";
import { Autocomplete, TextField } from "@mui/material";
import { useParams } from "react-router-dom";

type Props = {
    hubId?: string
    items: ICollectionCentre[]
    pagination: IPaginationMetadata
}

const AddCollectionCenters = ({ items, hubId, pagination }: Props) => {

    const [modalKey, setModalKey] = useState(Date.now());
    const [locations, setLocations] = useState<IOption[]>([])
    const urlParams = useParams()

    const { currentUser } = useAuth();
    const dispatch = useDispatch();

    const initialValues = {
        hubId: hubId ?? urlParams?.hubId,
        collectionCentres: []
    }

    const initialCollectionCentres = items.map(p => ({
        id: p.id ?? '',
        name: p.name ?? '',
        district: p.district ?? '',
        village: p.village ?? '',
        latitude: p.latitude ?? '',
        longitude: p.longitude ?? '',
    }));

    const collectionCentreOptions = locations?.map(c => ({
        id: c.id,
        name: c.label
    }));

    useEffect(() => {
        handleFetchData();
    }, [dispatch]);

    const setLoading = (state: boolean) => {
        dispatch({
            type: SETTINGS_CONSTANTS.LOADING_DATA,
            payload: state
        });
    };

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {

            const hubId = currentUser?.hubId;

            let params: any = { hubId };

            const url = buildUrl(remoteRoutes.onboardingService, '/collection/centres', params);

            get(url,
                (response: IApiResponse) => {
                    const { data } = response;
                    const collectionCenterList = data?.map(c => ({
                        id: c.id ?? '',
                        label: c.name,
                    })).sort((a, b) => a.label.localeCompare(b.label));

                    setLocations(collectionCenterList);
                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                    setLoading(false);
                });
        }
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.onboardingService, '/collection/centres/attach');
        const data = {
            hubId: hubId ?? urlParams.hubId,
            collectionCentres: values.collectionCentres,
        };

        post(
            url,
            data,
            (response) => {
                CloseModal("add-collection-centre", () => {
                    setSubmitting(false);
                    resetForm();
                });

                dispatch({
                    type: SETTINGS_CONSTANTS.ATTACH_COLLECTION_CENTRES,
                    payload: {
                        data: {collectionCentres: values.collectionCentres},
                        pagination: {
                            ...pagination,
                            totalItems: values.collectionCentres.length,
                        },
                    },
                });

                toast.success(toastMessages.default.success, overrideToastDefaults);
                setModalKey(Date.now());
            },
            (error) => {
                setSubmitting(false);
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            }
        );
    };

    return (
        <>
            <Formik
                initialValues={{
                    ...initialValues,
                    collectionCentres: initialCollectionCentres
                }}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {({ handleSubmit, isSubmitting, values, touched, errors, setFieldValue }) => {
                    return (
                        <ModalWrapper
                            id="add-collection-centre"
                            title="Add collection center(s)"
                            position="top"
                            size="lg"
                            key={modalKey}
                            submitting={isSubmitting}
                            handleSubmit={handleSubmit}
                        >
                            <div className="row">
                                <div className="col-lg-12">
                                    <Field name="collectionCentres">
                                        {() => (
                                            <Autocomplete
                                                multiple
                                                getOptionLabel={(option) => option.name}
                                                options={collectionCentreOptions}
                                                onChange={(event, value) => {
                                                    setFieldValue('collectionCentres', value);
                                                }}
                                                value={values.collectionCentres} // Ensuring value has correct structure

                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>
                                                        {option.name}
                                                    </li>
                                                )}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Collection Centers"
                                                        variant="standard"
                                                        error={touched.collectionCentres && Boolean(errors.collectionCentres)}
                                                    />
                                                )} />
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </ModalWrapper>
                    );
                }}
            </Formik>
        </>
    );
}

export default AddCollectionCenters;
