import { IPaginationMetadata } from "../../../app/interfaces/IApiResponse";
import { IBuyer } from "../../../app/modules/inventory/interfaces/IBuyer";

export const BUYERS_CONSTANTS = {
    DELETE_BUYER: "DELETE_BUYER",
    FETCH_BUYERS: "FETCH_BUYERS",
    FILTER_DATA: "FILTER_DATA",
    GET_BATCH: "GET_BATCH",
    LOADING_BUYERS: "LOADING_BUYERS",
    PAGINATION: "PAGINATION",
    POST_BUYER: "POST_BATCH",
    RESET_FILTER: "RESET_FILTER",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    STOP_FETCH: "STOP_FETCH",
    UPDATE_BUYER: "UPDATE_BUYER",
}

export interface IBuyerState {
    loading: boolean
    buyers: {
        data: IBuyer[],
        pagination: IPaginationMetadata
    }
    filter: {
        show: boolean,
        data: IBuyer[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IBuyerState = {
    loading: false,
    buyers: {
        data: [],
        pagination: {
            currentPage: 1,
            pageSize: 10,
            hasPreviousPage: false,
            hasNextPage: false,
            totalItems: 0,
            totalPages: 0
        }
    },
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function BUYERSReducer(state = initialState, action: any) {
    switch (action.type) {

        case BUYERS_CONSTANTS.FILTER_DATA:
            return { ...state, ...action.payload, show: !state.filter.show }

        case BUYERS_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return { ...initialState, reset: true }

        case BUYERS_CONSTANTS.SET_SORT_PARAMS:
            return { ...state, ...action.payload }

        case BUYERS_CONSTANTS.PAGINATION:
            return { ...state, ...action.payload }

        case BUYERS_CONSTANTS.FETCH_BUYERS: {
            return { ...state, loading: false, buyers: action.payload, reset: false }
        }

        case BUYERS_CONSTANTS.POST_BUYER: {
            const buyer: IBuyer = action.payload.data
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state, buyers: {
                    data: [buyer, ...state.buyers.data],
                    pagination
                }
            }
        }

        case BUYERS_CONSTANTS.UPDATE_BUYER: {
            return {
                ...state,
                buyers: {
                    ...state.buyers,
                    data: state.buyers.data.map(buyer =>
                        buyer.id === action.payload.id ? action.payload : buyer
                    )
                }
            }
        }

        case BUYERS_CONSTANTS.DELETE_BUYER: {
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state,
                buyers: {
                    ...state.buyers,
                    data: state.buyers.data.filter(buyer => buyer.id !== action.payload.id),
                    pagination
                }
            };
        }

        case BUYERS_CONSTANTS.LOADING_BUYERS: {
            return { ...state, loading: action.payload }
        }

        case BUYERS_CONSTANTS.GET_BATCH: {
            return { ...state, requestDetails: action.payload }
        }

        case BUYERS_CONSTANTS.STOP_FETCH: {
            return { ...state, loading: false }
        }

        default: {
            return state
        }
    }
}
