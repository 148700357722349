import React from "react";
import { Route, Routes } from "react-router-dom";
import ProcessRuns from "./ProcessRuns";
import Dashboard from "./Dashboard";

const ProcessingPage = () => {
    return (
        <Routes>
            <Route index element={<ProcessRuns />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='runs' element={<ProcessRuns />} />
        </Routes>
    )
}

export default ProcessingPage