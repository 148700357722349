import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { get } from "../../../../utils/ajax";
import { useAuth } from "../../auth";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { useIntl } from "react-intl";

interface Product {
  productId: string;
  product: string | null;
  volume: number;
  unit: string;
  variety: string;
  varietyId: string;
}

interface CollectionCentre {
  collectionCentreId: string;
  collectionCentreName: string;
  productVolumes: Product[];
}

interface DatasetItem {
  [key: string]: any;
  collectioncentre: string;
  fullName: string;
}

interface Props {
  productId: string | null | undefined;
  collectionCentreId: any | null | undefined;
  varietyId: string | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}

const valueFormatter = (value: number | null) => {
  if (value === null) return "";

  if (value >= 1_000 || value <= -1_000) {
    return `${Number((value / 1_000).toFixed(1)).toLocaleString()} mt`;
  } else {
    return `${Number(value).toLocaleString()} kg`;
  }
};

const shortLabelFormatter = (label: string) => {
  if (label.length > 10) {
    return `${label.substring(0, 7)}...`;
  }
  return label;
};

const colors = [
  "#AA0050",
  "#5F1ED9",
  "#FFCE56",
  "#4BC0C0",
  "#9966FF",
  "#FF9F40",
  "#4D5360",
];

const InventorySalesProgress: React.FC<Props> = ({
  productId,
  collectionCentreId,
  varietyId,
  startDate,
  endDate,
}) => {
  const intl = useIntl();
  const { currentUser } = useAuth();

  const [dataset, setDataset] = useState<DatasetItem[]>([]);
  const [series, setSeries] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const hubId = currentUser?.hubId;
      let params: any = { hubId };

      if (productId) {
        params.productId = productId;
      }

      if (collectionCentreId) {
        params.collectionCentreId = collectionCentreId;
      }

      if (varietyId) {
        params.varietyId = varietyId;
      }

      if (startDate) {
        params.startDate = startDate;
      }

      if (endDate) {
        params.endDate = endDate;
      }

      const url = buildUrl(
        remoteRoutes.inventoryService,
        "/dashboard/stock/centres",
        params,
      );

      get(
        url,
        (response: CollectionCentre[]) => {
          const transformedData: DatasetItem[] = response.map((centre) => {
            const data: DatasetItem = {
              collectioncentre: shortLabelFormatter(
                centre.collectionCentreName,
              ),
              fullName: centre.collectionCentreName,
            };
            centre.productVolumes.forEach((productVolume) => {
              const productName =
                productVolume.product ||
                `${intl.formatMessage({ id: "INVENTORY.SALES.UNKNOWNPRODUCT", defaultMessage: "Unknown Product" })}`;
              const productVariety =
                productVolume.variety ||
                `${intl.formatMessage({ id: "INVENTORY.SALES.UNKNOWNVARIETY", defaultMessage: "Unknown Variety" })}`;
              const combinedLabel = `${productName} - ${productVariety}`;
              data[combinedLabel] = productVolume.volume;
            });

            return data;
          });

          const productLabels = new Set<string>();

          response.forEach((centre) => {
            centre.productVolumes.forEach((productVolume) => {
              const productName =
                productVolume.product ||
                `${intl.formatMessage({ id: "INVENTORY.SALES.UNKNOWNPRODUCT", defaultMessage: "Unknown Product" })}`;
              const productVariety =
                productVolume.variety ||
                `${intl.formatMessage({ id: "INVENTORY.SALES.UNKNOWNVARIETY", defaultMessage: "Unknown Variety" })}`;
              const combinedLabel = `${productName} - ${productVariety}`;
              productLabels.add(combinedLabel);
            });
          });

          const dynamicSeries = Array.from(productLabels).map(
            (label: string, index: number) => ({
              dataKey: label,
              label: label,
              color: colors[index % colors.length],
              valueFormatter: (value: number) => valueFormatter(value),
              highlightScope: {
                highlight: "item",
              },
            }),
          );

          setDataset(transformedData);
          setSeries(dynamicSeries);
        },
        (error) => {
          console.error("API fetch error:", error);
        },
      );
    };

    fetchData();
  }, [productId, collectionCentreId, varietyId, startDate, endDate]);

  return (
    <Box>
      <BarChart
        dataset={dataset}
        series={series}
        layout="horizontal"
        xAxis={[
          {
            disableTicks: true,
            label: "kg",
            valueFormatter: (value) => valueFormatter(value),
          },
        ]}
        yAxis={[
          {
            disableTicks: true,
            scaleType: "band",
            dataKey: "collectioncentre",
            valueFormatter: (label: string) => shortLabelFormatter(label),
          },
        ]}
        height={350}
        margin={{ right: 30, left: 75 }}
        borderRadius={8}
        grid={{ vertical: true }}
        sx={{
          "& .MuiChartsLegend-root": { display: "none" },
          "& .MuiChartsGrid-root line": {
            stroke: "#EAEAEA",
            strokeDasharray: "4 4",
          },
          "& .MuiChartsAxis-root .MuiChartsAxis-line": { display: "none" },
          "& .MuiChartsAxis-root .MuiChartsAxis-tickLabel": {
            fill: "#A0A0A0",
            fontSize: 14,
            fontWeight: 400,
          },
        }}
      />
    </Box>
  );
};

export default InventorySalesProgress;
