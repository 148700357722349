import { IPaginationMetadata } from "../../../app/interfaces/IApiResponse";
import { IInputMovement } from "../../../app/modules/input-distribution/interfaces/IInputMovement";

export const INPUT_MOVEMENT_CONSTANTS = {
    DELETE_MOVEMENT: "DELETE_MOVEMENT",
    FETCH_DATA: "FETCH_DATA",
    FILTER_DATA: "FILTER_DATA",
    GET_DETAILS: "GET_DETAILS",
    LOADING_DATA: "LOADING_DATA",
    PAGINATION: "PAGINATION",
    POST_DATA: "POST_DATA",
    RESET_FILTER: "RESET_FILTER",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    STOP_FETCH: "STOP_FETCH",
    UPDATE_DATA: "UPDATE_DATA",
}

export interface IInputMovementState {
    loading: boolean
    data: IInputMovement[]
    pagination: IPaginationMetadata
    filter: {
        show: boolean,
        data: IInputMovement[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IInputMovementState = {
    loading: false,
    data: [],
    pagination: {
        currentPage: 1,
        pageSize: 25,
        hasPreviousPage: false,
        hasNextPage: false,
        totalItems: 0,
        totalPages: 0
    },
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}

export default function inputsMovementsReducer(state = initialState, action: any) {
    switch (action.type) {

        case INPUT_MOVEMENT_CONSTANTS.FILTER_DATA: {
            return { ...state, ...action.payload, show: !state.filter.show }
        }
        case INPUT_MOVEMENT_CONSTANTS.RESET_FILTER: {
            initialState.filter.show = true
            return { ...initialState, reset: true }
        }

        case INPUT_MOVEMENT_CONSTANTS.SET_SORT_PARAMS: {
            return { ...state, ...action.payload }
        }

        case INPUT_MOVEMENT_CONSTANTS.PAGINATION: {
            return { ...state, ...action.payload }
        }

        case INPUT_MOVEMENT_CONSTANTS.FETCH_DATA: {
            return { ...state, loading: false, data: action.payload, reset: false }
        }

        case INPUT_MOVEMENT_CONSTANTS.POST_DATA: {
            return { ...state, data: [action.payload, ...state.data] }
        }

        case INPUT_MOVEMENT_CONSTANTS.UPDATE_DATA: {
            return {
                ...state,
                data: state.data.map(movement => movement.id === action.payload.id ? action.payload : movement)
            }
        }

        case INPUT_MOVEMENT_CONSTANTS.DELETE_MOVEMENT: {
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state,
                data: state.data.filter(movement => movement.id !== action.payload.id),
                pagination
            }
        }

        case INPUT_MOVEMENT_CONSTANTS.LOADING_DATA: {
            return { ...state, loading: action.payload }
        }

        case INPUT_MOVEMENT_CONSTANTS.GET_DETAILS: {
            return { ...state, requestDetails: action.payload }
        }

        case INPUT_MOVEMENT_CONSTANTS.STOP_FETCH: {
            return { ...state, loading: false }
        }

        default: {
            return state
        }
    }
}