import { Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { IRole, IUnit } from "../../interfaces/IHub";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { del, put } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IPaginationMetadata } from "../../../../interfaces/IApiResponse";

type Props = {
  selectedRoleDelete: any;
  pagination: IPaginationMetadata;
};

const DeleteRoleForm = ({ selectedRoleDelete, pagination }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    const url = buildUrl(
      remoteRoutes.onboardingService,
      `/roles/${selectedRoleDelete}`,
    );

    const id = selectedRoleDelete;

    del(
      url,
      (response) => {
        dispatch({
          type: SETTINGS_CONSTANTS.DELETE_ROLE,
          payload: {
            id,
            pagination: {
              ...pagination,
              totalItems: pagination?.totalItems - 1,
            },
          },
        });

        CloseModal("delete-role");
        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        console.log(error);

        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  const initialValues: IRole = {
    id: "",
    name: "",
    description: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id={"delete-role"}
            title={`${intl.formatMessage({ id: "FORM.TITLE.DELETEROLE", defaultMessage: "Delete Role" })}`}
            size="md"
            isDeleteAction={true}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>
              <FormattedMessage
                id="DELETE.ROLE"
                defaultMessage="Are you sure you want to delete this role? This action cannot be undone."
              />
            </p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteRoleForm;
