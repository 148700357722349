import React, { useState } from "react";
import { IProcessRun } from "../interfaces/IProcessRun";
import { IPaginationMetadata } from "../../../interfaces/IApiResponse";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { Formik } from "formik";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { del } from "../../../../utils/ajax";
import { PROCESS_RUN_CONSTANTS } from "../../../../data/redux/processing/processRunsReducer";

interface Props {
    item: IProcessRun;
    close: () => void;
}

const DeleteProcessRun = ({ item, close }: Props) => {

    const intl = useIntl();

    const dispatch = useDispatch();

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = ({ resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.processingService, `/process-runs/${item.id}`)

        const id = item.id
        del(url, (response) => {

            dispatch({
                type: PROCESS_RUN_CONSTANTS.DELETE_PROCESS_RUN,
                payload: {
                    id
                }
            })

            CloseModal("delete-process-run");

            close();
            toast.success(toastMessages.deleteSuccessful.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, (error) => {
            console.log(error)
            setSubmitting(false);
            toast.error(toastMessages.deleteSuccessful.fail, overrideToastDefaults)
        }, () => {

        })

    }

    return (
        <Formik
            initialValues={item}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting }) => {
                return (
                    <ModalWrapper
                        id="delete-process-run"
                        title={`${intl.formatMessage({ id: "FORM.TITLE.DELETEPROCESSRUN", defaultMessage: "Delete Process Run" })}`}
                        size="md"
                        isDeleteAction={true}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}
                        key={modalKey}
                    >
                        <p>
                            Are you sure you want to delete this process run? This
                            action cannot be undone.
                        </p>
                    </ModalWrapper>
                );
            }}
        </Formik>
    )
}

export default DeleteProcessRun