import { XSidebarWrapper } from "../../../components/shared/XSidebarWrapper";
import React, { useEffect, useState } from "react";
import { IHeaderButtonProps } from "../../../components/shared/SettingsPageHeader";
import EditCostCentreForm from "./modals/EditCostCentreForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteCostCentreForm from "./modals/DeleteCostCentreForm";
import { useSelector } from "react-redux";
import { IState } from "../../../../data/types";
import { FormattedMessage, useIntl } from "react-intl";
import { ISettingsState } from "../../../../data/redux/settings/settingsReducer";
import { authService } from "../../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../../hooks/roleChecker";

type CostCentreDetailsProps = {
  close: () => void;
  show: boolean;
  costCentreId: any;
};

const CostCentreDetails = ({
  close,
  show,
  costCentreId,
}: CostCentreDetailsProps) => {
  const intl = useIntl();
  const costCentre = useSelector(
    (state: IState) =>
      state.settings.costCenters?.data?.find((cc) => cc.id === costCentreId) ||
      null,
  );

  const { costCenters }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );
  const { pagination } = costCenters;

  const [costCentreDetails, setCostCentreDetails] = useState(costCentre);

  useEffect(() => {
    setCostCentreDetails(costCentre);
  }, [costCentre]);

  const actionButtons: IHeaderButtonProps[] = [];

  const [loading, setLoading] = React.useState(true);
  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoading(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);

  if (isGlobalAdmin || isHubManager || isHubAdmin) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
      cssClass: "btn btn-primary btn-sm me-1",
      dataTarget: "#edit-cost-centre",
      dataToggle: "modal",
    });
  }

  if (isGlobalAdmin) {
    actionButtons.push({
      cssClass: "btn btn-danger btn-sm",
      dataTarget: "#delete-cost-centre",
      dataToggle: "modal",
      deleteIcon: true,
    });
  }

  return (
    <>
      <XSidebarWrapper
        title={intl.formatMessage({
          id: "DETAILS.TITLE.COSTCENTREDETAILS",
          defaultMessage: "Cost Centre Details",
        })}
        width={35}
        close={close}
        show={show}
      >
        <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
          <div className="py-3 px-4 rounded-1 bg-primary">
            <h2>{costCentreDetails?.name.substring(0, 2).toUpperCase()}</h2>
          </div>
          <div className="py-3 w-100">
            <h5>{costCentreDetails?.name}</h5>
            <div className="d-flex justify-content-between w-100">
              <div className="fs-6 small"></div>
              <div className="action-buttons">
                {actionButtons.map((button, index) => {
                  return (
                    <button
                      data-bs-toggle={button.dataToggle}
                      data-bs-target={button.dataTarget}
                      className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                      key={index}
                      onClick={button.onClick}
                    >
                      {button.deleteIcon && <FontAwesomeIcon icon={faTrash} />}
                      {button.processing
                        ? `${intl.formatMessage({
                            id: "LOADERS.PLEASEWAIT",
                            defaultMessage: "Please wait...",
                          })}`
                        : button.label}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 bg-gray rounded-3 mb-3">
          <div className="row">
            <div className="col-lg-12 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage id="DETAILS.UNITS" defaultMessage="Units" />
              </strong>
              <br />
              <div className="d-flex gap-1">
                {costCentreDetails?.units?.length
                  ? costCentreDetails.units.map((unit, index) => (
                      <span className="badge text-bg-danger" key={index}>
                        {unit.name}
                      </span>
                    ))
                  : "-"}
              </div>
            </div>
            <div className="col-lg-12 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.DESCRIPTION"
                  defaultMessage="Description"
                />
              </strong>
              <br />
              <span>{costCentreDetails?.description ?? "-"}</span>
            </div>
          </div>
        </div>
      </XSidebarWrapper>

      {costCentreDetails && (
        <>
          <EditCostCentreForm initialValues={costCentreDetails} />
          <DeleteCostCentreForm
            item={costCentreDetails}
            close={close}
            pagination={pagination}
          />
        </>
      )}
    </>
  );
};

export default CostCentreDetails;
