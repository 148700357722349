import React, { useEffect, useState } from "react";
import { IUser, EditUserSchema } from "../interfaces/IUser";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import toast from "react-hot-toast";
import { ErrorMessage, Field, Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { useIntl } from "react-intl";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { InputAdornment } from "@mui/material";
import { remoteRoutes } from "../../../../../data/constants";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { IApiResponse } from "../../../../interfaces/IApiResponse";
import { get, put } from "../../../../../utils/ajax";
import { useDispatch } from "react-redux";

interface Props {
    initialValues: IUser
}

const EditUserForm = ({ initialValues }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [modalKey, setModalKey] = useState(Date.now());
    const [userRoles, setUserRoles] = useState<any[]>([])

    const roles = userRoles?.map((role: any) => ({
        label: role.name,
        id: role.id,
    }));

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            let params: any = {
                pagesize: 10,
            };

            const url = buildUrl(remoteRoutes.onboardingService, "/roles", params);
            get(
                url,
                (response: IApiResponse) => {
                    const { data } = response;
                    setUserRoles(data);
                },
                async (error) => {
                    // toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                },
            );
        }
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.authServer, '/api/v1/users');
        const data = {
            userId: values.id,
            phoneNumber: `256${values.phoneNumber}`,
            email: values.email,
            claims: [
                { key: "role", value: values.role.label },
                { key: "given_name", value: values.firstName },
                { key: "family_name", value: values.lastName }
            ]
        }

        put(url, data, (response) => {

            CloseModal("edit-user", () => {
                setSubmitting(false);
                resetForm();
            });

            dispatch({
                type: SETTINGS_CONSTANTS.UPDATE_USER,
                payload: {
                    userId: values.id,
                    email: values.email,
                    phoneNumber: `256${values.phoneNumber}`,
                    claims: [
                        { key: "role", value: values.role.label },
                        { key: "given_name", value: values.firstName },
                        { key: "family_name", value: values.lastName },
                    ],
                },
            });

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const value = event.target.value;
        if (value.length <= 9) {
            setFieldValue("phoneNumber", value);
        }
    };

    const validationSchema = EditUserSchema(intl)

    useEffect(() => {
        handleFetchData();
    }, []);

    return (
        <Formik
            initialValues={{
                ...initialValues,
                phoneNumber: initialValues.phoneNumber.startsWith("256")
                    ? initialValues.phoneNumber.slice(3)
                    : initialValues.phoneNumber,
                role: initialValues.role ? {
                    label: initialValues.role,
                    id: null
                } : null,
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
                return <ModalWrapper
                    id={"edit-user"}
                    title={"Edit User"}
                    key={modalKey}
                    size="md"
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({
                                    id: "FORM.FIRSTNAME",
                                    defaultMessage: "First Name"
                                })}`}
                                name="firstName"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.LASTNAME", defaultMessage: "Last Name" })}`}
                                name="lastName"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={roles}
                                label={`${intl.formatMessage({ id: "FORM.ROLE", defaultMessage: "Role" })}`}
                                name="role"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({
                                    id: "FORM.PHONENUMBER",
                                    defaultMessage: "Phone umber"
                                })}`}
                                name="phoneNumber"
                                inputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">+256</InputAdornment>
                                    ),
                                    maxLength: 9,
                                    onKeyPress: handleKeyPress,
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChange(event, setFieldValue),
                                }}
                            />
                            <ErrorMessage name="PhoneNumber" component="div" />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.EMAIL", defaultMessage: "Email" })}`}
                                name="email"
                            />
                            <ErrorMessage name="email" component="div" />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default EditUserForm